import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { GetCountry } from "../../../Services/Api/master";
import { GetMerchantById, PutMerchant } from "../../../Services/Api/merchant";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import YupPassword from "yup-password";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Helmet } from "react-helmet-async";
import { useDebounce } from "use-debounce";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { url } from "../../../Utils/constants";
import { onImageError } from "../../../Utils/CommanFunctions";

YupPassword(Yup);

const EditMerchant = () => {
  const [countryOption, setCountryOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [showImg, setShowImg] = useState(true);
  const stateData = useLocation();
  const history = useNavigate();
  const { id } = stateData?.state || {};
  const [merchantDetails, setMerchantDetails] = useState({});
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [debounceKey] = useDebounce(1000);

  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  const getMerchantData = async () => {
    await GetMerchantById(id).then((res) => {
      const resMerchantData = res?.data?.data.merchant || {};
      setMerchantDetails(resMerchantData);
    });
  };
  const getCountryData = async () => {
    await GetCountry().then((res) => {
      const resCountryData = res?.data?.data || [];
      const countryFilterData = resCountryData.map((country) => {
        return {
          label: country?.name,
          value: country?.name,
        };
      });
      const currencyFilterData = resCountryData.map((country) => {
        return {
          label: `${country?.currency} - ${country?.name}`,
          value: country?.currency,
        };
      });
      setCurrencyOption(currencyFilterData);
      setCountryOption(countryFilterData);
    });
  };

  useEffect(() => {
    getMerchantData();
    getCountryData();
  }, []);

  const basicInfoSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    name: Yup.string().required("Name is required"),
    compnayName: Yup.string().required("Compnay name is required"),
    website: Yup.string().matches(url, 'Invalid URL format').required('Website URL is required'),
    authname: Yup.string().required("Authorized name is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.object().required("Please select a country"),
    currency: Yup.object().required("Please select a currency"),
    address: Yup.string().required("Address is required"),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, "Please enter correct mobile number")
      .required("Mobile number is required"),
    bphoneNumber: Yup.string()
      .matches(phoneRegExp, "Please enter correct mobile number")
      .required("Mobile number is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Please enter correct mobile number")
      .required("Telephone number is required"),
    zipcode: Yup.string()
      .required("PO box number / Pin code is required")
      .required("Pin code / PO box number is required"),
    vatNumber: Yup.string().required("VAT number is required"),
    profile_picture: Yup.mixed(),
  });

  const basicInitialValues = {
    email: merchantDetails?.email || "",
    name: merchantDetails?.name || "",
    bphoneNumber: merchantDetails?.billing_address?.phone || "",
    phone: merchantDetails?.telephone_number || "",
    currency:
      currencyOption.find(
        (ct) => ct.value === merchantDetails?.primary_currency
      ) || "",
    compnayName: merchantDetails?.company_name || "",
    website: merchantDetails?.website || "",
    authname: merchantDetails?.authorized_person_name || "",
    mobileNumber: merchantDetails?.mobile || "",
    city: merchantDetails?.billing_address?.city,
    state: merchantDetails?.billing_address?.state,
    country:
      countryOption.find(
        (ct) => ct.value === merchantDetails?.billing_address?.country
      ) || "",
    zipcode: merchantDetails?.billing_address?.zip_code,
    address: merchantDetails?.billing_address?.address,
    vatNumber: merchantDetails?.vat_number || "",
    profile_picture: "",
    allow_cash_credit: merchantDetails?.allow_cash_credit || "1",
  };

  return (
    <>
      <Helmet>
        <title>CPay | Admin Edit Merchant</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Merchant</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Admin/merchants">Merchant</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Merchant</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/merchants" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    initialValues={basicInitialValues}
                    validationSchema={basicInfoSchema}
                    enableReinitialize
                    onSubmit={async (values, { setSubmitting }) => {
                      const data = {
                        name: values.name,
                        authorized_person_name: values?.authname || " ",
                        company_name: values?.compnayName || "",
                        website: values?.website || "",
                        telephone_number: values?.phone || " ",
                        mobile: values?.mobileNumber || " ",
                        profile_picture: values?.profile_picture,
                        primary_currency: values?.currency.value || " ",
                        billing_address: {
                          name: values?.name || " ",
                          address: values?.address || " ",
                          phone: values?.bphoneNumber || " ",
                          country: values?.country.value || " ",
                          state: values?.state || " ",
                          city: values?.city || " ",
                          zip_code: values?.zipcode.toString() || " ",
                        },
                        allow_cash_credit: values?.allow_cash_credit || "1",
                      };
                      await PutMerchant({ id, data })
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Merchnat Updated."
                          );
                          setSubmitting(false);
                          history("/admin/merchants");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        values,
                        handleChange,
                        setFieldValue,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  value={values.name}
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>

                                <Field
                                  type="email"
                                  placeholder="Enter email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  value={values.email}
                                  disabled
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Telephone Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  placeholder="Enter telephone number"
                                  className={`form-control ${
                                    errors.phone && touched.phone
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="phone"
                                  value={values.phone}
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Website</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Website Url"
                                  onChange={handleChange}
                                  className={`form-control ${errors.website && touched.website
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="website"
                                  value={values.website}
                                />
                                <ErrorMessage
                                  name="website"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                              <label>
                                Currency <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={currencyOption}
                                onChange={(newVal) => {
                                  setFieldValue("currency", newVal);
                                }}
                                name="currency"
                                value={values.currency}
                                placeholder="Select currency"
                              />
                              <ErrorMessage
                                name="currency"
                                component="span"
                                className="error"
                              />
                              </div>
                             
                            </div>
                            
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Authorized Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  placeholder="Enter authorised name"
                                  className={`form-control ${
                                    errors.authname && touched.authname
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="authname"
                                  value={values.authname}
                                />
                                <ErrorMessage
                                  name="authname"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group remove-inner-input">
                                <label>
                                  Mobile Number (Country Code){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.mobileNumber}
                                  onChange={(newVal) => {
                                    setFieldValue("mobileNumber", newVal || "");
                                  }}
                                  className={`form-control ${
                                    errors.mobileNumber && touched.mobileNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="mobileNumber"
                                />
                                <ErrorMessage
                                  name="mobileNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Company Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.compnayName && touched.compnayName
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="compnayName"
                                  value={values.compnayName}
                                  placeholder="Enter company name"
                                />
                                <ErrorMessage
                                  name="compnayName"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  VAT Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  placeholder="Enter VAT number"
                                  className={`form-control ${
                                    errors.vatNumber && touched.vatNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="vatNumber"
                                  value={values.vatNumber}
                                />
                                <ErrorMessage
                                  name="vatNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group dropzone-image-area">
                                <label className="image-label">
                                  Profile Picture
                                </label>
                                <DragAndDropFile
                                  randerKey={debounceKey}
                                  maxFileSize={2000000} //2mb
                                  label="Drag and drop a image or click"
                                  fileTypes={[
                                    "image/*",
                                    ".pdf",
                                    ".docx",
                                    ".doc",
                                    ".pptx",
                                  ]}
                                  className={`form-control dropzoneBox ${
                                    errors.profile_picture &&
                                    touched.profile_picture
                                      ? "input-error"
                                      : null
                                  }`}
                                  handleChange={async (file) => {
                                    setFieldValue("profile_picture", file[0]);
                                    let imageData = file[0];
                                    const imagePostData = {
                                      user_id: id,
                                      file_name: imageData.name,
                                      file_module: "PROFILE_PICTURE",
                                      mime_type: imageData.type,
                                      document_sides: "NONE",
                                    };
                                    await fileUploadToBucket({
                                      data: imagePostData,
                                      file: imageData,
                                    })
                                      .then(async (res) => {
                                        setFieldValue(
                                          "profile_picture",
                                          res?.data?.data?.s3_key
                                        );
                                        setShowImg(false);
                                      })
                                      .catch((err) => {
                                        setShowImg(true);
                                        if (
                                          typeof err.response.data.message !==
                                          "undefined"
                                        ) {
                                          ErrorToast(
                                            err.response.data.message ||
                                              "Server Error!!"
                                          );
                                        } else {
                                          ErrorToast(
                                            err?.message || "Server Error!!"
                                          );
                                        }
                                      });
                                  }}
                                  acceptedFiles={fileTypes}
                                  // value={values.profile_picture}
                                />
                                <ErrorMessage
                                  name="profile_picture"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                {/* {showImg && merchantDetails.profile_picture && (
                                  <> */}
                                    <img
                                      src={merchantDetails?.profile_picture || ""}
                                      onError={onImageError}
                                      alt="profile"
                                      style={{
                                        width: "224px",
                                        height: "auto",
                                        marginTop: "33px",
                                      }}
                                    />
                                  {/* </>
                                )} */}
                              </div>
                            </div>
                          </div>
                          <h4 className="card-title mt-4">Billing Address</h4>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  City <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.city && touched.city
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="city"
                                  value={values.city}
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="city"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Country <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={countryOption}
                                  onChange={(newVal) => {
                                    setFieldValue("country", newVal);
                                  }}
                                  name="country"
                                  value={values.country}
                                  placeholder="Select country"
                                />
                                <ErrorMessage
                                  name="country"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Address <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  rows={3}
                                  cols={5}
                                  type="text"
                                  placeholder="Enter address"
                                  className={`form-control ${
                                    errors.address && touched.address
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="address"
                                  onChange={handleChange}
                                  value={values.address}
                                />
                                <ErrorMessage
                                  name="address"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  State <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  placeholder="Enter state"
                                  className={`form-control ${
                                    errors.state && touched.state
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="state"
                                  onChange={handleChange}
                                  value={values.state}
                                />
                                <ErrorMessage
                                  name="state"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  PO box number / Pin code{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  placeholder="Enter PO box number / pin code"
                                  className={`form-control ${
                                    errors.zipcode && touched.zipcode
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="zipcode"
                                  onChange={handleChange}
                                  value={values.zipcode}
                                />
                                <ErrorMessage
                                  name="zipcode"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="form-group remove-inner-input">
                                <label>
                                  Mobile Number (Country Code){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.bphoneNumber}
                                  onChange={(newVal) => {
                                    setFieldValue("bphoneNumber", newVal || "");
                                  }}
                                  className={`form-control ${
                                    errors.bphoneNumber && touched.bphoneNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="bphoneNumber"
                                />
                                <ErrorMessage
                                  name="bphoneNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                            <label className="image-label">
                              Allow Customer To Pay Amount Via Cash/Credit OR Cash Only
                            </label>
                              <div className="input-radio">
                                <label htmlFor="allow_cash_credit_both">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="allow_cash_credit"
                                  id="allow_cash_credit_both"
                                  value="1"
                                />{" "}
                                Allow Both
                                </label>
                                &nbsp;&nbsp;
                                <label htmlFor="allow_cash_credit_cash">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="allow_cash_credit"
                                  id="allow_cash_credit_cash"
                                  value="2"
                                />{" "}
                                Cash Only
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Merchant
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div class="content container-fluid absolute px-2">
              <footer className="text-center text-lg-start">
                <div className="text-center pb-4">
                  Powered by Alsaffron System.
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMerchant;
