import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "../../../Utils";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import { ServiceChargeListService } from "../../../Services/Api/customer";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import format from "date-fns/format";

const ServiceCharge = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceChargeList, setServiceChargeList] = useState([]);
  const stateData = useLocation();
  const history = useNavigate();
  const { customerid, currency, companyName } = stateData?.state || {};

  const getServiceChargeList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await ServiceChargeListService({
      limit: limitO,
      page: pageO,
      customer_id: customerid,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setServiceChargeList(res?.data?.data?.service_charges);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setServiceChargeList([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setServiceChargeList([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    getServiceChargeList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Service Charge</title>
      </Helmet>
      <div>
        <div className="main-wrapper">
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Service Charge for {companyName}</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/admin/customer">Customer</Link>
                      </li>
                      <li className="breadcrumb-item active">Service Charge</li>
                    </ul>
                  </div>
                  <div className="col-auto">
                    {/* <Link to="/admin/add-service-charge" className="btn btn-primary me-1">
                  <i className="fas fa-plus" /> Add Service Charge
                </Link> */}
                    <span
                      className="btn btn-primary me-"
                      onClick={() =>
                        history("/admin/add-service-charge", {
                          state: { customerid: customerid, currency: currency },
                        })
                      }
                    >
                      Add Service Charge
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-center table-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>#</th>
                              <th>Merchant Name</th>
                              <th>Service Charge</th>
                              <th>Start date</th>
                              <th>Created By</th>
                              <th>Updated By</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading && (
                              <>
                                {serviceChargeList.map(
                                  (servicecharge, index) => (
                                    <tr key={index}>
                                      <td>{limit * page + (index + 1)}</td>
                                      <td>
                                        {servicecharge?.merchant?.name || "-"}
                                      </td>
                                      {servicecharge?.type == "percentage" ? (
                                        <td>
                                          {servicecharge?.service_charge}%
                                        </td>
                                      ) : (
                                        <td>
                                          <span className="currency-type-text">
                                            {currency}
                                          </span>{" "}
                                          {servicecharge?.service_charge}{" "}
                                        </td>
                                      )}

                                      <td>
                                        {format(
                                          new Date(servicecharge.start_date),
                                          "dd MMMM, yyyy"
                                        )}
                                      </td>
                                      <td>
                                        {servicecharge?.createdBy?.name || "-"}
                                      </td>
                                      <td>
                                        {servicecharge?.updatedBy?.name || "-"}
                                      </td>
                                      <td>
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            history(
                                              "/admin/edit-service-charge",
                                              {
                                                state: {
                                                  editid: servicecharge?.id,
                                                  currency,
                                                  customerid,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  )
                                )}
                                {serviceChargeList.length === 0 && (
                                  <tr>
                                    <td colSpan={7}>No Records Found.</td>
                                  </tr>
                                )}
                              </>
                            )}
                            <Pagination
                              totalItem={totalRecords}
                              itemsPerPage={limit}
                              page={page}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleLimitChange}
                              colSpan={7}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid absolute">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCharge;
