import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SuccessToast, ErrorToast } from "../../Utils/SweetAlert";
import { useNavigate } from 'react-router-dom'
import { ResetPasswordService } from "../../Services/Api/auth";
import { Helmet } from 'react-helmet-async';

const ResetPassword = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const signInSchema = Yup.object().shape({
    password: Yup
      .string()
      .required('Password is required')
      .test('uppderCase', 'Must contain at least one uppercase character', (value) => /[A-Z]/.test(value))
      .test('lowerCase', 'Must contain at least one lowercase character', (value) => /[a-z]/.test(value))
      .test('oneNumber', 'Must contain at least one number', (value) => /[0-9]/.test(value))
      .test('oneSpecial', 'Must contain at least one special character', (value) => /[!@#%&]/.test(value)),
    confirm_password: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });
  const initialValues = {
    newPass: "",
    conformNewPass: "",
  };
  return (
    <>
      <Helmet>
        <title>CPay | Admin Reset Password</title>
      </Helmet>
      <div>
        <div className="main-wrapper login-body">
          <div className="login-wrapper">
            <div className="container">
              <img
                className="img-fluid logo-dark mb-2"
                src="/inner-assets/img/logo.png"
                alt="Logo"
              />
              <div className="loginbox">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <h1>Reset Password</h1>
                    <p className="account-subtitle">Enter New Password</p>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      validateOnChange
                      onSubmit={async (values, { setSubmitting }) => {
                        const resetPasswordObj = {
                          forgotten_password_code: token,
                          new_password: values.password,
                          confirm_new_password: values.confirm_password,
                        }
                        await ResetPasswordService(resetPasswordObj).then((res) => {
                          SuccessToast(res?.data?.message || 'Password has been reset successfully.');
                          setSubmitting(false);
                          navigate('/');
                        }).catch((err) => {
                          if (typeof err.response.data.message !== 'undefined') {
                            ErrorToast(err.response.data.message || 'Server Error!!');
                          } else {
                            ErrorToast(err?.message || 'Server Error!!');
                          }
                        })
                      }}
                    >
                      {({
                        errors,
                        touched,
                        isSubmitting
                      }) => {
                        return (
                          <Form>
                            <div classname="form-group m-bottom">
                              <label className="form-control-label">Password<span className="text-danger">*</span></label>
                              <div className="pass-group">
                                <Field
                                  type={isShowPassword ? 'text' : 'password'}
                                  className={
                                    `form-control ${errors.password && touched.password ? "input-error" : null}`
                                  }
                                  name="password"
                                  placeholder="Enter password"
                                />
                                <span onClick={() => setIsShowPassword(!isShowPassword)} className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                              </div>
                              <ErrorMessage name="password" component="span" className="error" />
                            </div>
                            <div classname="form-group">
                              <label className="form-control-label">Confirm Password<span className="text-danger">*</span></label>
                              <div className="pass-group">
                                <Field
                                  type={isShowConfirmPassword ? 'text' : 'password'}
                                  id="new_password"
                                  className={
                                    `form-control ${errors.confirm_password && touched.confirm_password ? "input-error" : null}`
                                  }
                                  name="confirm_password"
                                  placeholder="Enter confirm password"
                                />
                                <span onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)} className={`fas ${isShowConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                              </div>
                              <ErrorMessage name="confirm_password" component="span" className="error" />
                            </div>
                            <div className="form-group mt-4">
                              <button
                                className="btn btn-lg btn-block btn-primary w-100 rset"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Reset Password&nbsp;&nbsp;
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                    <div className="text-center dont-have">
                      Remember your password?{" "}
                      <NavLink to="/">Login</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
