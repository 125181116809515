import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Pagination } from "../../../Utils";
import { Confirmation, SuccessToast, ErrorToast, RejectTextArea } from "../../../Utils/SweetAlert";
import format from "date-fns/format";
import { Helmet } from 'react-helmet-async';
import { GetMerchantRegistrationRequest, ApproveMerchantRequest, RejectMerchantRequest } from '../../../Services/Api/merchant';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const MerchantRegistrationRequest = () => {

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [merchantList, setMerchantList] = useState([]);

  const approveMerchant = (merchentRequestID, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          registration_request_id: merchentRequestID,
        };
        setIsLoading(true);
        await ApproveMerchantRequest(postObj)
          .then((res) => {
            SuccessToast(res?.data.message || "Merchant has been approved successfully");
            getMerchantRegistrationRequest(limit, page);
          })
          .catch((err) => {
            setIsLoading(false);
            if (err?.response?.data?.message) {
              ErrorToast(err?.response?.data?.message)
            }
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  const rejectMerchant = (merchentRequestID, text) => {
    RejectTextArea({ denyButtonText: "Cancel" }).then(async (result) => {


      if (result.isConfirmed) {
        const rejectData = {
          reject_reason: result.value,
          registration_request_id: merchentRequestID,
        };

        setIsLoading(true);
        console.log(rejectData, "rejectData");
        await RejectMerchantRequest(rejectData)
          .then(async (res) => {
            SuccessToast(res?.data?.message || "Merchant requests rejected.");
            getMerchantRegistrationRequest(limit, page);
          })
          .catch((err) => {
            setIsLoading(false);
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const getMerchantRegistrationRequest = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetMerchantRegistrationRequest({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setMerchantList(res?.data?.data?.merchants);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setMerchantList([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setMerchantList([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getMerchantRegistrationRequest(limit, page);
  }, [limit, page]);
  return (
    <>
      <Helmet>
        <title>CPay | Admin Merchant Registration Request</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Merchant Registration Requests</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Merchant Registration Requests</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th className="text-center">Status</th>
                          <th>Company Name</th>
                          <th>Website</th>
                          <th>Contact Person Name</th>
                          <th>Department</th>
                          <th>Designation</th>
                          <th>Telephone Number</th>
                          <th>Email</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {merchantList.map((merchant, index) => (
                              <tr key={index}>
                                <td>
                                  {(limit * page) + (index + 1)}
                                </td>
                                <td>
                                  {merchant.status === 2 && (
                                    <>
                                      {/* <span className="badge badge-pill bg-success-light">
                                      Approved
                                    </span> */}
                                      <span className="badge badge-pill bg-danger-light" style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                        Rejected
                                      </span>
                                      {merchant?.reject_reason &&
                                        <>
                                          <Tooltip
                                            title={merchant?.reject_reason}
                                          >
                                            <IconButton style={{ color: "#ed4646" }}>
                                              <InfoIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      }
                                    </>
                                  )}
                                  {!merchant?.status && (
                                    <>
                                      <span
                                        className="btn btn-sm btn-block btn-outline-success"
                                        // style={{ paddingLeft: "23px", paddingRight: "23px" }}
                                        onClick={() => {
                                          approveMerchant(
                                            merchant?.id,
                                            "Want to approve merchant?"
                                          );
                                        }}
                                      >
                                        Approve{" "}
                                      </span>
                                      <span
                                        className="btn btn-sm btn-block btn-outline-danger"
                                        style={{ marginLeft: "5px" }}
                                        // style={{ paddingLeft: "23px", paddingRight: "23px" }}
                                        onClick={() => {
                                          rejectMerchant(
                                            merchant?.id,
                                            "Want to reject merchant?"
                                          );
                                        }}
                                      >
                                        Reject{" "}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {merchant?.company_name}
                                </td>
                                <td>
                                  <a href={merchant?.website} target='_blank' >{merchant?.website}</a>
                                </td>
                                <td>
                                  {merchant?.contact_person_name}
                                </td>
                                <td>
                                  {merchant?.department}
                                </td>
                                <td>
                                  {merchant?.designation}
                                </td>
                                <td>
                                  {merchant?.telephone_number}
                                </td>
                                <td>
                                  {merchant?.email}
                                </td>
                                <td>
                                  {format(
                                    new Date(merchant.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                              </tr>
                            ))}
                            {merchantList.length === 0 && (
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan={9}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={9}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="content container-fluid absolute px-2">
                <footer className="text-center text-lg-start">
                  <div className="text-center pb-4">
                    Powered by Alsaffron System.
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MerchantRegistrationRequest