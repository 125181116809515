import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { GetCountry } from "../../../Services/Api/master";
import { GetCustomerById, PutCustomer } from "../../../Services/Api/customer";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Helmet } from "react-helmet-async";
import { useDebounce } from "use-debounce";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { onImageError } from "../../../Utils/CommanFunctions";

const EditCustomer = () => {
  const stateData = useLocation();
  const history = useNavigate();
  const { id } = stateData?.state || {};
  const [customerDetails, setCustomerDetails] = useState({});
  const [countryOption, setCountryOption] = useState([]);
  const [showImg, setShowImg] = useState(true);
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [debounceKey] = useDebounce(1000);

  const paneltyPeriod = [
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Fort Night", value: "FORT_NIGHT" },
  ];

  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  const getCountryData = async () => {
    await GetCountry().then((res) => {
      const resCountryData = res?.data?.data || [];
      const countryFilterData = resCountryData.map((country) => {
        return {
          label: country?.name,
          value: country?.name,
        };
      });
      setCountryOption(countryFilterData);
    });
  };

  const getCustomerData = async () => {
    await GetCustomerById(id).then((res) => {
      const resCustomerData = res?.data?.data.customer || {};
      setCustomerDetails(resCustomerData);
    });
  };

  useEffect(() => {
    getCountryData();
    getCustomerData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Edit Customer</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Customers</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/customer">Customers</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Customer</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/customer" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    initialValues={{
                      email: customerDetails?.email || "",
                      name: customerDetails?.name || "",
                      mobileNumber: customerDetails?.mobile || "",
                      contactname:
                        customerDetails?.authorized_person_name || "",
                      compnay: customerDetails?.company_name || "",
                      telephone_number: customerDetails?.telephone_number || "",
                      bphoneNumber:
                        customerDetails?.billing_address?.phone || "",
                      city: customerDetails?.billing_address?.city,
                      state: customerDetails?.billing_address?.state,
                      country:
                        countryOption.find(
                          (ct) =>
                            ct.value ===
                            customerDetails?.billing_address?.country
                        ) || "",
                      zipcode: customerDetails?.billing_address?.zip_code,
                      address: customerDetails?.billing_address?.address,
                      cr_due_days: customerDetails?.cr_due_days,
                      cr_penalty_start_days:
                        customerDetails?.cr_penalty_start_days,
                      cr_penalty_amount: customerDetails?.cr_penalty_amount,
                      cr_penalty_period: customerDetails?.cr_penalty_period,
                      vatNumber: customerDetails?.vat_number || "",
                      profile_picture: "",
                    }}
                    validateOnChange
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email().required("Email is required"),
                      name: Yup.string().required("Name is required"),
                      compnay: Yup.string().required(
                        "Company name is required"
                      ),
                      contactname: Yup.string().required(
                        "Authorised person name is required"
                      ),
                      telephone_number: Yup.string()
                        .matches(
                          phoneRegExp,
                          "Please enter correct telephone number"
                        )
                        .required("Telephone number is required"),
                      mobileNumber: Yup.string()
                        .required("Mobile number is required")
                        .matches(
                          phoneRegExp,
                          "Please enter correct mobile number"
                        ),
                      country: Yup.object()
                        .nullable(true)
                        .required("Please select country"),
                      bphoneNumber: Yup.string().matches(
                        phoneRegExp,
                        "Please enter correct mobile number"
                      ),
                      city: Yup.string().required("City is required"),
                      state: Yup.string().required("State is required"),
                      address: Yup.string().required("Address is required"),
                      vatNumber: Yup.string().required(
                        "VAT Number is required"
                      ),
                      profile_picture: Yup.mixed(),
                      zipcode: Yup.string().required(
                        "PO box number / Pin code is required"
                      ),
                      cr_due_days: Yup.number()
                        .positive("Must be greater then 0")
                        .moreThan(-1, "Due days should not be less than zero")
                        .nullable(true)
                        .typeError("Please enter number only"),
                      // .required('Please enter due date value')
                      cr_penalty_start_days: Yup.number()
                        .moreThan(
                          -1,
                          "Penalty start days should not be less than zero"
                        )
                        .nullable(true)
                        .typeError("Please enter number only"),
                      // .required('Please enter penalty start days value')
                      cr_penalty_period: Yup.object().nullable(true),
                      cr_penalty_amount: Yup.number()
                        .positive("Must be greater then 0")
                        .nullable(true)
                        .typeError("Please enter number only"),
                      // .required('Penalty amount is required')
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      const data = {
                        name: values.name || " ",
                        authorized_person_name: values?.contactname || " ",
                        telephone_number: values?.telephone_number || " ",
                        mobile: values?.mobileNumber || " ",
                        profile_picture: values?.profile_picture,
                        company_name: values?.compnay || " ",
                        vat_number: values?.vatNumber || " ",
                        billing_address: {
                          name: values?.name || " ",
                          address: values?.address || " ",
                          phone: values?.bphoneNumber || " ",
                          country: values?.country.value || " ",
                          state: values?.state || " ",
                          city: values?.city || " ",
                          zip_code: values?.zipcode.toString() || " ",
                        },
                        cr_due_days: values?.cr_due_days || null,
                        cr_penalty_start_days: values?.cr_penalty_start_days,
                        cr_penalty_amount: values?.cr_penalty_amount || null,
                        cr_penalty_period: values?.cr_penalty_period || null,
                      };
                      await PutCustomer({ id, data })
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Customer Updated."
                          );
                          setSubmitting(false);
                          history("/admin/customer");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      isValid,
                      isSubmitting,
                      handleChange,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="name"
                                  placeholder="Enter name"
                                  name="name"
                                  value={values.name}
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error "
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="email"
                                  placeholder="Enter email"
                                  name="email"
                                  value={values.email}
                                  disabled
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Telephone Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.telephone_number &&
                                    touched.telephone_number
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="telephone_number"
                                  placeholder="Enter telephone number"
                                  name="telephone_number"
                                  value={values.telephone_number}
                                />
                                <ErrorMessage
                                  name="telephone_number"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="col-md-12">
                                <div className="form-group dropzone-image-area">
                                  <label className="image-label">
                                    Profile Picture:
                                  </label>
                                  <DragAndDropFile
                                    randerKey={debounceKey}
                                    maxFileSize={2000000} //2mb
                                    label="Drag and drop a image or click"
                                    fileTypes={[
                                      "image/*",
                                      ".pdf",
                                      ".docx",
                                      ".doc",
                                      ".pptx",
                                    ]}
                                    className={`form-control dropzoneBox ${
                                      errors.profile_picture &&
                                      touched.profile_picture
                                        ? "input-error"
                                        : null
                                    }`}
                                    handleChange={async (file) => {
                                      setFieldValue("profile_picture", file[0]);
                                      let imageData = file[0];
                                      const imagePostData = {
                                        user_id: id,
                                        file_name: imageData.name,
                                        file_module: "PROFILE_PICTURE",
                                        mime_type: imageData.type,
                                        document_sides: "NONE",
                                      };
                                      await fileUploadToBucket({
                                        data: imagePostData,
                                        file: imageData,
                                      })
                                        .then(async (res) => {
                                          setFieldValue(
                                            "profile_picture",
                                            res?.data?.data?.s3_key
                                          );
                                          setShowImg(false);
                                        })
                                        .catch((err) => {
                                          setShowImg(true);
                                          if (
                                            typeof err.response.data.message !==
                                            "undefined"
                                          ) {
                                            ErrorToast(
                                              err.response.data.message ||
                                                "Server Error!!"
                                            );
                                          } else {
                                            ErrorToast(
                                              err?.message || "Server Error!!"
                                            );
                                          }
                                        });
                                    }}
                                    acceptedFiles={fileTypes}
                                    // value={values.profile_picture}
                                  />
                                  <ErrorMessage
                                    name="profile_picture"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    {showImg &&
                                      customerDetails.profile_picture && (
                                        <>
                                          <img
                                            src={
                                              customerDetails?.profile_picture || ""
                                            }
                                            onError={onImageError}
                                            alt="profile"
                                            style={{
                                              width: "224px",
                                              height: "auto",
                                              marginTop: "33px",
                                            }}
                                          />
                                        </>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Authorised Person Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.contactname && touched.contactname
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="contactname"
                                  placeholder="Enter authorised person name"
                                  name="contactname"
                                  value={values.contactname}
                                />
                                <ErrorMessage
                                  name="contactname"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group remove-inner-input">
                                <label>
                                  Mobile Number (Country Code){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.mobileNumber}
                                  onChange={(newVal) => {
                                    setFieldValue("mobileNumber", newVal || "");
                                  }}
                                  className={`form-control ${
                                    errors.mobileNumber && touched.mobileNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="mobileNumber"
                                />
                                <ErrorMessage
                                  name="mobileNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Compnay Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.compnay && touched.compnay
                                      ? "input-error"
                                      : null
                                  }`}
                                  placeholder="Enter compnay"
                                  name="compnay"
                                  value={values.compnay}
                                />
                                <ErrorMessage
                                  name="compnay"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  VAT Number{" "}
                                  <span className="text-danger">*</span>
                                </label>

                                <Field
                                  type="text"
                                  placeholder="Enter VAT number"
                                  className={`form-control ${
                                    errors.vatNumber && touched.vatNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="vatNumber"
                                  value={values.vatNumber}
                                />
                                <ErrorMessage
                                  name="vatNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <h4 className="card-title mt-4">Billing Address</h4>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  City <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.city && touched.city
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="city"
                                  placeholder="Enter city"
                                  value={values.city}
                                />
                                <ErrorMessage
                                  name="city"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Country <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={countryOption}
                                  onChange={(newVal) => {
                                    setFieldValue("country", newVal);
                                  }}
                                  name="country"
                                  placeholder="Enter Country"
                                  value={values.country}
                                />
                                <ErrorMessage
                                  name="country"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Address <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  rows={3}
                                  cols={5}
                                  type="text"
                                  placeholder="Enter address"
                                  className={`form-control ${
                                    errors.address && touched.address
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="address"
                                  onChange={handleChange}
                                  value={values.address}
                                />
                                <ErrorMessage
                                  name="address"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  State <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.state && touched.state
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="state"
                                  placeholder="Enter state"
                                  onChange={handleChange}
                                  value={values.state}
                                />
                                <ErrorMessage
                                  name="state"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  PO box number / Pin code{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.zipcode && touched.zipcode
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="zipcode"
                                  placeholder="Enter PO box number / Pin code"
                                  onChange={handleChange}
                                  value={values.zipcode}
                                />
                                <ErrorMessage
                                  name="zipcode"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group remove-inner-input">
                                <label>Mobile Number (Country Code) </label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.bphoneNumber}
                                  onChange={(newVal) => {
                                    setFieldValue("bphoneNumber", newVal || "");
                                  }}
                                  className={`form-control ${
                                    errors.bphoneNumber && touched.bphoneNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="bphoneNumber"
                                />
                                <ErrorMessage
                                  name="bphoneNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          {/* 12-07-2022 */}
                          <h4 className="card-title mt-4">
                            Credit Request Penalty Setting
                          </h4>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Due Days </label>
                                <Field
                                  type="text"
                                  placeholder="Enter due days"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_due_days && touched.cr_due_days
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_due_days"
                                  value={values.cr_due_days}
                                />
                                <ErrorMessage
                                  name="cr_due_days"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Penalty Start Days </label>
                                <Field
                                  type="text"
                                  placeholder="Enter penalty start days"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_penalty_start_days &&
                                    touched.cr_penalty_start_days
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_penalty_start_days"
                                  value={values.cr_penalty_start_days}
                                />
                                <ErrorMessage
                                  name="cr_penalty_start_days"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                            <div className="form-group col-md-3">
                              <label>Penalty Period</label>
                              <Select
                                value={paneltyPeriod.filter(
                                  (option) =>
                                    option.value === values.cr_penalty_period
                                )}
                                options={paneltyPeriod}
                                onChange={(e) => {
                                  setFieldValue("cr_penalty_period", e.value);
                                }}
                                name="cr_penalty_period"
                                placeholder="Select penalty period"
                              />
                              <ErrorMessage
                                name="cr_penalty_period"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Penalty Amount </label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_penalty_amount &&
                                    touched.cr_penalty_amount
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_penalty_amount"
                                  placeholder="Enter penalty amount"
                                  value={values.cr_penalty_amount}
                                />
                                <ErrorMessage
                                  name="cr_penalty_amount"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                          </div>
                          {/* 12-07-2022 */}
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Customer
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCustomer;
