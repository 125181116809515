import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { PostBankAdd } from "../../../Services/Api/bankingOption";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { getProfile } from "../../../Services/Api/auth";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import "react-phone-number-input/style.css";
import { Helmet } from "react-helmet-async";

const AddBank = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [logoImage, setLogoImage] = useState(false);

  let initData = {
    name: "",
    logo: "",
  };

  const getProfileData = async () => {
    getProfile()
      .then((res) => {
        setProfileData(res?.data?.data || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //     ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //     ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  const uploadLogo = async (e) => {
    const logo = e.target.files[0];
    const profilePostData = {
      user_id: profileData._id,
      file_name: logo.name,
      file_module: "BANK_LOGOS",
      mime_type: logo.type,
      document_sides: "NONE",
    };
    await fileUploadToBucket({ data: profilePostData, file: logo }).then(
      (res) => {
        setLogoImage(res?.data?.data?.s3_key || false);
      }
    );
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Add Bank</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Banks</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/banks">Banks</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Bank</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/banks" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initData}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Name is required"),
                      logo: Yup.string().required("Please select a logo"),
                    })}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postObj = {
                        name: values.name,
                        logo: logoImage,
                      };
                      await PostBankAdd(postObj)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Bank added Successfully."
                          );
                          setSubmitting(false);
                          navigate("/admin/banks");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-12"></div>
                            <div className="form-group col-md-6">
                              <label>
                                Name <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.name && touched.name
                                    ? "input-error"
                                    : null
                                }`}
                                name="name"
                                value={values.name}
                                placeholder="Enter name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Logo <span className="text-danger">*</span>
                              </label>
                              <input
                                type="file"
                                name="logo"
                                onChange={(e) => {
                                  uploadLogo(e);
                                  setFieldValue(
                                    "logo",
                                    e.currentTarget.files[0]
                                  );
                                }}
                                className={`form-control`}
                              />
                              <ErrorMessage
                                name="logo"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Bank&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBank;
