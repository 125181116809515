import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import format from "date-fns/format";
import {
  GetPayCreditRequestDetails,
  DeleteCustomerCreditPaymentRequestDetail,
  ApproveRejectCustomerCreditPaymentRequest,
} from "../../../Services/Api/master";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GppBadIcon from "@mui/icons-material/GppBad";
import DeleteIcon from "@mui/icons-material/Delete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";

const CustomerCreditRequestDetail = () => {
  const stateData = useLocation();
  const history = useNavigate();
  const { tid, customer_name } = stateData?.state || {};
  const [payCreditRequest, setPayCreditRequest] = useState({});

  const getPayCreditRequestData = async () => {
    await GetPayCreditRequestDetails(tid)
      .then((res) => {
        setPayCreditRequest(res?.data?.data);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  useEffect(() => {
    getPayCreditRequestData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Credit Request Details</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title"> {(customer_name) ? `Credit Request Details For `+customer_name : `Credit Request Details`} </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Credit Request Details
                  </li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/Admin/customer-credit-request"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="custom-wallet-details">
                    <Formik
                      initialValues={{
                        transaction_amount:
                          payCreditRequest?.credit_request?.payment_request
                            ?.transaction_amount || 0,
                        comments: payCreditRequest?.status_comments || "",
                      }}
                      validateOnChange
                      enableReinitialize
                      validationSchema={Yup.object().shape({
                        transaction_amount: Yup.number()
                          .positive("Please enter a positive value")
                          .required("Transaction amount is required"),
                        comments: Yup.string().required("Comment is required."),
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        var process_type = values.process_type || null;
                        if (process_type == 3) {
                          var postObj = {
                            payment_request_id:
                              payCreditRequest?.credit_request?.payment_request
                                ?.id,
                            comments: values.comments,
                          };
                          await DeleteCustomerCreditPaymentRequestDetail(
                            postObj
                          )
                            .then((res) => {
                              SuccessToast(
                                res?.data?.message || "Status Updated"
                              );
                              setSubmitting(false);
                              history("/Admin/customer-credit-request");
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                            });
                        } else if (process_type == 1 || process_type == 2) {
                          var postObj = {
                            payment_request_id:
                              payCreditRequest?.credit_request?.payment_request
                                ?.id,
                            amount: values.transaction_amount,
                            status: process_type,
                            comments: values.comments,
                          };
                          await ApproveRejectCustomerCreditPaymentRequest(
                            postObj
                          )
                            .then((res) => {
                              SuccessToast(
                                res?.data?.message || "Status Updated"
                              );
                              setSubmitting(false);
                              history("/Admin/customer-credit-request");
                            })
                            .catch((err) => {
                              // if (typeof err.response.data.message !== "undefined") {
                              //     ErrorToast(err.response.data.message || "Server Error!!");
                              // } else {
                              //     ErrorToast(err?.message || "Server Error!!");
                              // }
                            });
                        } else {
                          ErrorToast("Please select process type.");
                        }
                      }}
                    >
                      {({ errors, touched, values, isSubmitting }) => {
                        return (
                          <Form>
                            <div className="form-main-sec">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Reference Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.reference_number
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Date
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={format(
                                                new Date(
                                                  payCreditRequest
                                                    ?.credit_request
                                                    ?.payment_request
                                                    ?.date_of_payment || null
                                                ),
                                                "dd MMMM, yyyy,hh:mm"
                                              )}
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Currency
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.currency
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-3">
                                          <label className="form-label">
                                            Amount to be credited{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            className={`form-control ${
                                              errors.transaction_amount &&
                                              touched.transaction_amount
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="transaction_amount"
                                            placeholder="Enter credited amount"
                                            value={values.transaction_amount}
                                          />
                                          <span>
                                            Actual payment amount:{" "}
                                            <span className="currency-type-text">
                                              {
                                                payCreditRequest?.credit_request
                                                  ?.currency
                                              }
                                            </span>{" "}
                                            {
                                              payCreditRequest?.payment_info
                                                ?.amount
                                            }{" "}
                                          </span>
                                          <br />
                                          <ErrorMessage
                                            name="transaction_amount"
                                            component="span"
                                            className="error"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Your Reference
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.payment_request
                                                  ?.user_reference
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-12">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Destination / Bank
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.payment_request
                                                  ?.destination_bank?.title
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Currency
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.payment_request
                                                  ?.deposited_currency
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Amount Deposited
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.payment_request
                                                  ?.deposited_amount
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-12">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Debtor Reference
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.payment_request
                                                  ?.bank_reference
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-12">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.payment_request
                                                  ?.transaction_type
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Comments
                                            </label>
                                            <input
                                              as="textarea"
                                              className="form-control"
                                              placeholder={
                                                payCreditRequest?.credit_request
                                                  ?.payment_request?.comments
                                              }
                                              style={{ minHeight: "100px" }}
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="content-details-img">
                                    {payCreditRequest?.credit_request
                                      ?.payment_request?.receipt_file && (
                                      <img
                                        src={
                                          payCreditRequest?.credit_request
                                            ?.payment_request?.receipt_file
                                        }
                                        alt="right"
                                        style={{
                                          width: "500px",
                                          height: "auto",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="comments-sec">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="comments-card text-center">
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <div className="input-radio">
                                      <Field
                                        className="form-check-input mt-0"
                                        type="radio"
                                        name="process_type"
                                        value="3"
                                      />{" "}
                                      Delete
                                    </div>
                                    <p>Remove this record.</p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="comments-card text-center">
                                    <Tooltip
                                      title="Reject"
                                      className="btn btn-sm btn-white text-danger me-2"
                                    >
                                      <IconButton>
                                        <GppBadIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <div className="input-radio">
                                      <i className="fa-solid fa-delete-left"></i>
                                      <Field
                                        className="form-check-input mt-0"
                                        type="radio"
                                        name="process_type"
                                        value="2"
                                      />{" "}
                                      Send Back
                                    </div>
                                    <p>Send back to client to revise.</p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="comments-card text-center">
                                    <i className="fa-solid fa-trash-can-xmark" />
                                    <Tooltip
                                      title="Approve"
                                      className="btn btn-sm btn-white text-success me-2"
                                    >
                                      <IconButton>
                                        <AssignmentTurnedInIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <div className="input-radio">
                                      <Field
                                        className="form-check-input mt-0"
                                        type="radio"
                                        name="process_type"
                                        value="1"
                                      />{" "}
                                      Apply
                                    </div>
                                    <p>
                                      Accept and apply as a new transaction.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="text-area">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Comments Back
                                  </label>
                                  <Field
                                    component="textarea"
                                    name="comments"
                                    className="form-control"
                                    rows={3}
                                  />
                                  <ErrorMessage
                                    name="comments"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="submit-btn text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Submit
                                  {isSubmitting && (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  )}
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerCreditRequestDetail;
