import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { Editor } from "@tinymce/tinymce-react";
import SwitchToggle from "../../../Utils/SwithToggle";
import { EditFaqs, FetchFaqsById } from "../../../Services/Api/faq";

const EditFaq = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || "";
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [faqById, setFaqById] = useState([]);

  const signInSchema = Yup.object().shape({
    question: Yup.string().required("Question is required"),
    answer: Yup.string().required("Answer is required"),
    type: Yup.string().required("Please select type"),
  });

  const initialValues = {
    type: faqById.type || "",
    question: faqById.question || "",
    answer: faqById.answer || "",
    status: faqById.status || 0,
  };

  const typeOfUser = [
    { label: "Customer", value: "customer" },
    { label: "Merchant", value: "merchant" },
  ];

  const getFaqById = async () => {
    await FetchFaqsById(id)
      .then((res) => {
        setFaqById(res?.data?.data?.faq);
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  useEffect(() => {
    getFaqById();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Edit Faq</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Faq</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/faq-list">Faq</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Faq</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/faq-list" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Faq Info</h4>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        id: id,
                        type: values.type,
                        question: values.question,
                        answer: values.answer,
                        status: values.status,
                      };
                      await EditFaqs(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Faq updated successfully."
                          );
                          setSubmitting(false);
                          navigate("/admin/faq-list");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Question
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.question && touched.question
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="question"
                                  value={values.question}
                                  placeholder="Enter question"
                                />
                                <ErrorMessage
                                  name="question"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Answer <span className="text-danger">*</span>
                                </label>
                                <Editor
                                  apiKey="0cjhdp8cn3vtdkcr174ql1pan43comxnovk9u1cqs95aa80f"
                                  onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                  }
                                  // initialValue=""
                                  value={values.answer}
                                  textareaName="answer"
                                  onEditorChange={(newValue, editor) =>
                                    setFieldValue("answer", newValue)
                                  }
                                  init={{
                                    height: 200,
                                    menubar: false,
                                    // plugins: [
                                    //   'advlist autolink lists link image charmap print preview anchor',
                                    //   'searchreplace visualblocks code fullscreen',
                                    //   'insertdatetime media table paste code help wordcount emoticons'
                                    // ],
                                    // toolbar: 'undo redo | formatselect | ' +
                                    // 'bold italic backcolor | alignleft aligncenter ' +
                                    // 'alignright alignjustify | bullist numlist outdent indent | ' +
                                    // 'removeformat | help | emoticons',
                                    plugins:
                                      "emoticons code anchor link textcolor",
                                    toolbar:
                                      "emoticons | bold italic link forecolor | undo redo | code",
                                    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    setup: function (ed) {
                                      ed.on("keydown", function (e) {
                                        if (
                                          e.code === "Enter" ||
                                          e.code === "NumpadEnter"
                                        ) {
                                          e.preventDefault();
                                        }
                                      });
                                    },
                                  }}
                                />
                                <ErrorMessage
                                  name="answer"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Type <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={typeOfUser}
                                  onChange={(e) => {
                                    setFieldValue("type", e.value);
                                  }}
                                  value={typeOfUser.filter(
                                    (option) => option.value === values.type
                                  )}
                                  name="type"
                                  placeholder="Select type"
                                />
                                <ErrorMessage
                                  name="type"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <SwitchToggle
                              setFieldValue={setFieldValue}
                              value={values}
                            />
                          </div>

                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Faq
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFaq;
