import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import {
  GetBankingList,
  StatusBank,
} from "../../../Services/Api/bankingOption";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { DeleteBank } from "../../../Services/Api/merchant";

const Banks = () => {
  const [page] = useState(0);
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [bankingList, setBankingList] = useState([]);

  const getGetBankingOptionList = async () => {
    setIsLoading(true);
    await GetBankingList()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.accounts.length > 0) {
          setBankingList(res?.data?.data?.accounts);
        } else {
          setBankingList([]);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setBankingList([]);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const StatusChange = (accounts, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          bank_id: accounts._id,
          status: `${accounts.status ^ 1}`,
        };
        await StatusBank(postObj)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            getGetBankingOptionList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  const deleteBank = (deleteBankId) => {
    Confirmation("Want to delete bank?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteBank(deleteBankId)
          .then((res) => {
            SuccessToast(res?.data?.message || "Bank Deleted.");
            getGetBankingOptionList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  useEffect(() => {
    getGetBankingOptionList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Banks</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Banks</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Banks</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/add-banks" className="btn btn-primary me-1">
                  <i className="fas fa-plus" /> Add Banks
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Logo</th>
                          <th className="text-center">Status</th>
                          <th>Actions</th>
                          <th>Name</th>
                          <th>CreatedBy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {bankingList.map((accounts, index) => (
                              <tr key={index}>
                                <td>
                                  <img
                                    className="logoImg"
                                    src={accounts?.logo}
                                    alt="User"
                                  />
                                </td>
                                <td className="text-center">
                                  {accounts.status === 0 && (
                                    <button
                                      className="btn btn-sm btn-block btn-outline-danger"
                                      onClick={() => {
                                        StatusChange(
                                          accounts,
                                          "Want to active bank?"
                                        );
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                  {accounts.status === 1 && (
                                    <button
                                      className="btn btn-sm btn-block btn-outline-success"
                                      onClick={() => {
                                        StatusChange(
                                          accounts,
                                          "Want to deactivate bank?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                                <td>
                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2 tooltip-position"
                                    onClick={() => deleteBank(accounts._id)}
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                                <td>{accounts?.name}</td>
                                <td>{accounts?.createdBy?.name}</td>
                              </tr>
                            ))}
                            {bankingList.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={5}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={5}>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banks;
