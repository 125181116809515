import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
import {
  getDepositOptionService,
  depositOptionService,
} from "../../../Services/Api/booking";

const DepositOption = () => {
  const [depositeOption, setDepositeOption] = useState([]);

  const getDepositOption = async () => {
    getDepositOptionService()
      .then((res) => {
        setDepositeOption(res?.data?.data?.depositOptions || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  useEffect(() => {
    getDepositOption();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Deposit Option</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="page-title">Deposit Option</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Deposit Option</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Deposit Option</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      credit: depositeOption?.credit || 0,
                      cash: depositeOption?.cash || 0,
                      card: depositeOption?.card || 0,
                      convenience_fee: depositeOption?.convenience_fee || 0,
                    }}
                    validationSchema={Yup.object().shape({
                      credit: Yup.number(),
                      cash: Yup.number(),
                      card: Yup.number(),
                      convenience_fee: Yup.number().moreThan(
                        -1,
                        "Convenience fee should be 0 or greater than 0"
                      ),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      const data = {
                        id: depositeOption?._id,
                        credit: values.credit,
                        cash: values.cash,
                        card: values.card,
                        convenience_fee: values.convenience_fee || 0,
                      };
                      await depositOptionService(data)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Service Success."
                          );
                          setSubmitting(false);
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                        });
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      isSubmitting,
                      errors,
                      touched,
                      handleChange,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  className="row form-group toggle-switch"
                                  htmlFor="switchOn"
                                >
                                  <span className="col-4 col-sm-12 toggle-switch-content">
                                    <span
                                      className="d-block text-dark"
                                      style={{ fontSize: "larger" }}
                                    >
                                      Credit
                                    </span>
                                  </span>
                                  <span className="col-8 col-sm-9">
                                    <input
                                      type="checkbox"
                                      name="credit"
                                      value="1"
                                      checked={values.credit}
                                      className="toggle-switch-input"
                                      id="switchOn"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setFieldValue("credit", 1);
                                        } else {
                                          setFieldValue("credit", 0);
                                        }
                                      }}
                                    />
                                    <span className="toggle-switch-label">
                                      <span className="toggle-switch-indicator"></span>
                                    </span>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  className="row form-group toggle-switch"
                                  htmlFor="switchOn1"
                                >
                                  <span className="col-4 col-sm-12 toggle-switch-content">
                                    <span
                                      className="d-block text-dark"
                                      style={{ fontSize: "larger" }}
                                    >
                                      Cash
                                    </span>
                                  </span>
                                  <span className="col-8 col-sm-9">
                                    <input
                                      type="checkbox"
                                      name="cash"
                                      value="1"
                                      checked={values.cash}
                                      className="toggle-switch-input"
                                      id="switchOn1"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setFieldValue("cash", 1);
                                        } else {
                                          setFieldValue("cash", 0);
                                        }
                                      }}
                                    />
                                    <span className="toggle-switch-label">
                                      <span className="toggle-switch-indicator"></span>
                                    </span>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="form-group col-md-4">
                              <label
                                className="row form-group toggle-switch"
                                htmlFor="switchOn2"
                              >
                                <span className="col-4 col-sm-12 toggle-switch-content">
                                  <span
                                    className="d-block text-dark"
                                    style={{ fontSize: "larger" }}
                                  >
                                    Card
                                  </span>
                                </span>
                                <span className="col-8 col-sm-9">
                                  <input
                                    type="checkbox"
                                    name="card"
                                    value="1"
                                    checked={values.card}
                                    className="toggle-switch-input"
                                    id="switchOn2"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue("card", 1);
                                      } else {
                                        setFieldValue("card", 0);
                                      }
                                    }}
                                  />
                                  <span className="toggle-switch-label">
                                    <span className="toggle-switch-indicator"></span>
                                  </span>
                                </span>
                              </label>
                              <span className="my-3">Convenience Fee (%)</span>
                              <div className="form-group my-2">
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.convenience_fee &&
                                    touched.convenience_fee
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="convenience_fee"
                                  onChange={handleChange}
                                  value={values.convenience_fee}
                                />
                                <ErrorMessage
                                  name="convenience_fee"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Update&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositOption;
