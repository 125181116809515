import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "../../../Utils";
import {
  AddFundPopup,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
import {
  AddFundService,
  GetInvestorPaginate,
  ReturnFundService,
} from "../../../Services/Api/investor";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const Investor = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [investorList, setInvestorList] = useState([]);
  const navigate = useNavigate();

  const getInvestorList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetInvestorPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setInvestorList(res?.data?.data?.investors);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setInvestorList([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setInvestorList([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const AddFund = async (investorId) => {
    const label = "Add Fund";
    AddFundPopup({ label }).then(async (confirm) => {
      if (confirm.isConfirmed) {
        const postData = {
          investor_id: investorId?.id,
          amount: confirm.value.amount,
          notes: confirm.value.notes,
        };
        await AddFundService(postData)
          .then(async (res) => {
            SuccessToast(res?.data?.message || "Fund Added successfull.");
            getInvestorList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  const ReturnFund = async (investorId) => {
    const label = "Return Fund";
    AddFundPopup({ label }).then(async (confirm) => {
      if (confirm.isConfirmed) {
        const postData = {
          investor_id: investorId?.id,
          amount: confirm.value.amount,
          notes: confirm.value.notes,
        };
        await ReturnFundService(postData)
          .then(async (res) => {
            SuccessToast(res?.data?.message || "Fund Return successfull.");
            getInvestorList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  useEffect(() => {
    getInvestorList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Investors</title>
      </Helmet>
      <div>
        <div className="main-wrapper">
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Investors</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Investors</li>
                    </ul>
                  </div>
                  <div className="col-auto">
                    <Link
                      to="/admin/view-fund"
                      className="btn btn-primary me-1 mb-1"
                    >
                      View Fund
                    </Link>
                    <Link
                      to="/admin/add-investor"
                      className="btn btn-primary me-1 mb-1"
                    >
                      <i className="fas fa-plus" /> Add Investor
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-center table-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>#</th>
                              <th className="text-center">Action</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Contact Number</th>
                              <th>Company Name</th>
                              <th>Website</th>
                              <th>Amount Invested</th>
                              <th>Remaining Investment</th>
                              <th>Funds Returned</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading && (
                              <>
                                {investorList.map((investor, index) => (
                                  <tr key={index}>
                                    <td>{limit * page + (index + 1)}</td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          AddFund({ id: investor?.id })
                                        }
                                        className="btn btn-sm btn-block btn-outline-success mx-2"
                                      >
                                        Add Fund
                                      </button>
                                      <button
                                        onClick={() =>
                                          ReturnFund({ id: investor?.id })
                                        }
                                        className="btn btn-sm btn-block btn-outline-danger mx-2"
                                      >
                                        Return Fund
                                      </button>

                                      <Tooltip
                                        title="Statement"
                                        className="btn btn-sm btn-white text-warning me-2"
                                        onClick={() =>
                                          navigate(
                                            "/admin/investor-fund-statement",
                                            {
                                              state: { id: investor?.id, user_name: investor?.name },
                                            }
                                          )
                                        }
                                      >
                                        <IconButton>
                                          <div className="handshake-img">
                                            <img
                                              src="/inner-assets/img/statement.svg"
                                              alt="statement"
                                            />
                                          </div>
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                    <td>{investor?.name || "-"}</td>
                                    <td>{investor?.email || "-"}</td>
                                    <td>{investor?.contact_number || "-"}</td>
                                    <td>{investor?.company_name || "-"}</td>
                                    <td>{investor?.website || "-"}</td>
                                    <td>
                                      <span className="currency-type-text">
                                        AED
                                      </span>{" "}
                                      {investor?.funds_invested || 0}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        AED
                                      </span>{" "}
                                      {investor?.remaining_investment || 0}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        AED
                                      </span>{" "}
                                      {investor?.funds_returned || 0}
                                    </td>
                                  </tr>
                                ))}
                                {investorList.length === 0 && (
                                  <tr>
                                    <td colSpan={10}>No Records Found.</td>
                                  </tr>
                                )}
                              </>
                            )}
                            <Pagination
                              totalItem={totalRecords}
                              itemsPerPage={limit}
                              page={page}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleLimitChange}
                              colSpan={10}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investor;
