import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "../../../Utils";
// import {  ErrorToast } from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
import {
  GetFundStatementPaginate,
  GetFundWidgetService,
} from "../../../Services/Api/investor";
import format from "date-fns/format";

const ViewFund = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [fundStatementList, setFundStatementList] = useState([]);
  const [funddata, setFunddata] = useState([]);

  const getFundStatement = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetFundStatementPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setFundStatementList(res?.data?.data?.statement);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setFundStatementList([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setFundStatementList([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getFundWidget = async () => {
    await GetFundWidgetService()
      .then((res) => {
        setFunddata(res?.data?.data?.fund || []);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  useEffect(() => {
    getFundWidget();
  }, []);

  useEffect(() => {
    getFundStatement(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin View Fund</title>
      </Helmet>
      <div>
        <div className="main-wrapper">
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">View Fund</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">View Fund</li>
                    </ul>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/investor" className="btn btn-primary me-1">
                      <i className="fas fa-angle-double-left" /> Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="card inovices-card">
                    <div className="card-body">
                      <div className="inovices-widget-header">
                        <span className="inovices-widget-icon">
                          <img
                            src="../inner-assets/img/icons/invoices-icon1.svg"
                            alt="icon"
                          />
                        </span>
                        <div className="inovices-dash-count">
                          <div className="inovices-amount">
                            <span className="currency-type-text">AED</span>{" "}
                            {funddata?.total_investment}{" "}
                          </div>
                        </div>
                      </div>
                      <p className="inovices-all">Total Investment </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="card inovices-card">
                    <div className="card-body">
                      <div className="inovices-widget-header">
                        <span className="inovices-widget-icon">
                          <img
                            src="../inner-assets/img/icons/invoices-icon2.svg"
                            alt="icon"
                          />
                        </span>
                        <div className="inovices-dash-count">
                          <div className="inovices-amount">
                            <span className="currency-type-text">AED</span>{" "}
                            {funddata?.invested_amount}{" "}
                          </div>
                        </div>
                      </div>
                      <p className="inovices-all">Invested Amount </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="card inovices-card">
                    <div className="card-body">
                      <div className="inovices-widget-header">
                        <span className="inovices-widget-icon">
                          <img
                            src="../inner-assets/img/icons/invoices-icon3.svg"
                            alt="icon"
                          />
                        </span>
                        <div className="inovices-dash-count">
                          <div className="inovices-amount">
                            <span className="currency-type-text">AED</span>{" "}
                            {funddata?.remaining_investment}{" "}
                          </div>
                        </div>
                      </div>
                      <p className="inovices-all">Remaining Investment </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="card inovices-card">
                    <div className="card-body">
                      <div className="inovices-widget-header">
                        <span className="inovices-widget-icon">
                          <img
                            src="../inner-assets/img/icons/invoices-icon4.svg"
                            alt="icon"
                          />
                        </span>
                        <div className="inovices-dash-count">
                          <div className="inovices-amount">
                            <span className="currency-type-text">AED</span>{" "}
                            {funddata?.investment_returned}{" "}
                          </div>
                        </div>
                      </div>
                      <p className="inovices-all">Investment Returned </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-center table-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Debit</th>
                              <th>Credit</th>
                              <th>Opening Balance</th>
                              <th>Closing Balance</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading && (
                              <>
                                {fundStatementList.map((investor, index) => (
                                  <tr key={index}>
                                    <td>{limit * page + (index + 1)}</td>
                                    <td>
                                      {format(
                                        new Date(investor?.createdAt),
                                        "dd MMMM, yyyy, HH:mm a"
                                      )}
                                    </td>
                                    <td>
                                      {investor?.transaction_type == "DEBIT" ? (
                                        <>
                                          <span className="currency-type-text">
                                            AED
                                          </span>
                                          &nbsp;
                                          {investor?.amount}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {investor?.transaction_type ==
                                      "CREDIT" ? (
                                        <>
                                          <span className="currency-type-text">
                                            AED
                                          </span>
                                          &nbsp;
                                          {investor?.amount}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        AED{" "}
                                      </span>
                                      {investor?.opening_balance || 0}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        AED{" "}
                                      </span>
                                      {investor?.closing_balance || 0}
                                    </td>
                                    <td>{investor?.notes || "-"}</td>
                                  </tr>
                                ))}
                                {fundStatementList.length === 0 && (
                                  <tr>
                                    <td colSpan={7}>No Records Found.</td>
                                  </tr>
                                )}
                              </>
                            )}
                            <Pagination
                              totalItem={totalRecords}
                              itemsPerPage={limit}
                              page={page}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleLimitChange}
                              colSpan={7}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFund;
