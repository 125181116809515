import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import {
  EditServiceChargeService,
  GetServiceChargeEditDataService,
} from "../../../Services/Api/customer";
import { Helmet } from "react-helmet-async";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EditServiceCharge = () => {
  const stateData = useLocation();
  const history = useNavigate();
  const { editid, currency, customerid } = stateData?.state || {};
  const [date, setDate] = useState("");

  const serviceCharge = [
    { label: "Percentage", value: "percentage" },
    { label: "Amount", value: "amount" },
  ];
  const [editServiceChargeData, setEditServiceChargeData] = useState({});
  const getServiceChargeData = async () => {
    await GetServiceChargeEditDataService(editid)
      .then((res) => {
        setEditServiceChargeData(res?.data?.data?.service_charge);
        const date = moment(res?.data?.data?.service_charge?.start_date)
          .utc()
          .format("YYYY-MM-DD");
        setDate(new Date(date));
      })
      .catch((err) => {
        setEditServiceChargeData({});
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const initData = {
    service_charge: editServiceChargeData?.service_charge,
    type: editServiceChargeData?.type,
    start_date: date || "",
  };
  useEffect(() => {
    getServiceChargeData();
  }, []);
  return (
    <>
      <Helmet>
        <title>CPay | Admin Edit Service Charge</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="page-title">Service charge</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/customer">Customers</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/admin/service-charge"
                      state={{ editid, currency, customerid }}
                    >
                      Service Charge
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edit Service charge
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Edit Service charge</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initData}
                    validationSchema={Yup.object().shape({
                      type: Yup.string()
                        .nullable()
                        .required("Please choose service charge type"),
                      service_charge: Yup.number()
                        .positive("Must be greater then 0")
                        .nullable(true)
                        .moreThan(
                          -1,
                          "Service charge should not be less than zero"
                        )
                        .required("Service charge is required")
                        .typeError("Please enter number only"),
                      start_date: Yup.date().required("Please select date"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      const data = {
                        id: editServiceChargeData?.id,
                        type: values.type,
                        service_charge: values.service_charge,
                        start_date: moment(values.start_date).format(
                          "YYYY-MM-DD"
                        ),
                      };
                      EditServiceChargeService(data)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "Service charge edit successfully."
                          );
                          setSubmitting(false);
                          history("/admin/service-charge", {
                            state: { editid, currency, customerid },
                          });
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Service Charge Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  value={serviceCharge.filter(
                                    (option) => option.value === values.type
                                  )}
                                  options={serviceCharge}
                                  onChange={(e) => {
                                    setFieldValue("type", e.value);
                                  }}
                                  name="type"
                                  placeholder="Select service charge type"
                                />
                                <ErrorMessage
                                  name="type"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-dark">
                                  Start Date{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  autoComplete="off"
                                  className={`form-control ${
                                    errors.start_date && touched.start_date
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="start_date"
                                  onChange={(date) =>
                                    setFieldValue("start_date", date)
                                  }
                                  placeholderText="dd-mm-yyyy"
                                  // maxDate={new Date()}
                                  value={moment(values.start_date).format(
                                    "DD-MM-yyyy"
                                  )}
                                  dateFormat="DD-MM-yyyy"
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Service Charge{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  placeholder="Enter service charge"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.service_charge &&
                                    touched.service_charge
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="service_charge"
                                  value={values.service_charge}
                                />
                                <ErrorMessage
                                  name="service_charge"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Service Charge&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditServiceCharge;
