import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ForumIcon from '@mui/icons-material/Forum';
import RateReviewIcon from '@mui/icons-material/RateReview';
import HelpIcon from "@mui/icons-material/Help";
import { adminGetUserPermissionService } from "../../Services/Api/user";

const AdminSidebar = ({ setOpenSidebar }) => {
  const [hideShow, setHideShow] = useState("none");
  const [userManagementHideShow, setUserManagementHideShow] = useState("none");
  const [customerHideShow, setCustomerHideShow] = useState("none");
  const [customerWalletHideShow, setCustomerWalletHideShow] = useState("none");
  const [merchantApi, setMerchantApi] = useState("none");
  const [report, setReport] = useState("none");

  const [arrowMerchant, setArrowMerchant] = useState("menu-arrow");
  const [arrowCustomer, setArrowCustomer] = useState("menu-arrow");
  const [arrowCustomerWallet, setArrowCustomerWallet] = useState("menu-arrow");
  const [arrowMerchantApi, setArrowMerchantApi] = useState("menu-arrow");
  const [arrowUserManagement, setArrowUserManagement] = useState("menu-arrow");
  const [arrowReport, setArrowReport] = useState("menu-arrow");

  useEffect(() => {
    setArrowMerchant(
      hideShow == "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
    setArrowCustomer(
      customerHideShow == "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
    setArrowCustomerWallet(
      customerWalletHideShow == "block"
        ? "menu-arrow menu-arrow-down"
        : "menu-arrow"
    );
    setArrowMerchantApi(
      merchantApi == "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
    setArrowUserManagement(
      userManagementHideShow == "block"
        ? "menu-arrow menu-arrow-down"
        : "menu-arrow"
    );
    setArrowReport(
      report == "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
  });

  const menuclick = (value) => {
    setCustomerHideShow("none");
    setHideShow("none");
    setCustomerWalletHideShow("none");
    setMerchantApi("none");
    setUserManagementHideShow("none");
    setReport("none");

    if (value == "merchant") {
      setHideShow("block");
    }
    if (value == "customer") {
      setCustomerHideShow("block");
    }
    if (value == "card-wallet-request") {
      setCustomerWalletHideShow("block");
      setCustomerHideShow("block");
    }
    if (value == "merchant-api-keys") {
      setMerchantApi("block");
    }
    if (value == "merchant-api-documentation") {
      setMerchantApi("block");
    }
    if (value == "usermanagement") {
      setUserManagementHideShow("block");
    }
    if (value == "report") {
      setReport("block");
    }
  };

  const [permission, setPermission] = useState([]);
  const [role, setRole] = useState();

  const getPermission = async () => {
    await adminGetUserPermissionService()
      .then((res) => {
        const permissionArr = res?.data.data.permissions.role_meta;
        const resourceArr = permissionArr.map((value) => {
          return value.role_resource.resource_key;
        });
        setPermission(resourceArr);
        localStorage.setItem("adminPermission", JSON.stringify(resourceArr));
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("userData"));
    setRole(role?.role);
    if (role?.role === "sub_admin") {
      getPermission();
    }
  }, []);

  return (
    <div
      className="sidebar"
      id="sidebar"
      style={
        setOpenSidebar == true
          ? { marginLeft: "0px" }
          : { marginLeft: "-225px" }
      }
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main</span>
            </li>

            <li onClick={() => menuclick("dashboard")}>
              <NavLink to="/admin/dashboard">
                <i data-feather="home" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-home"
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
                <span>Dashboard</span>
              </NavLink>
            </li>

            {role == "sub_admin" ? (
              <>
                {(permission.indexOf("user_management") > -1 ||
                  permission.indexOf("role_management") > -1) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setUserManagementHideShow(
                            userManagementHideShow === "none" ? "block" : "none"
                          );
                        }}
                      >
                        <span className="role_icon">
                          <ManageAccountsIcon />
                        </span>
                        <span> User Management</span>{" "}
                        <span className={arrowUserManagement} />
                      </div>
                    </Link>
                    <ul style={{ display: userManagementHideShow }}>
                      {permission.indexOf("user_management") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("usermanagement")}
                            to="/admin/users"
                          >
                            Users
                          </NavLink>
                        </li>
                      )}
                      {permission.indexOf("role_management") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("usermanagement")}
                            to="/admin/role"
                          >
                            Role Management
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </>
            ) : (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setUserManagementHideShow(
                          userManagementHideShow === "none" ? "block" : "none"
                        );
                      }}
                    >
                      {/* <i data-feather="clipboard" /> */}
                      <span className="role_icon">
                        <ManageAccountsIcon />
                      </span>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg> */}
                      <span> User Management</span>{" "}
                      <span className={arrowUserManagement} />
                    </div>
                  </Link>
                  <ul style={{ display: userManagementHideShow }}>
                    <li>
                      <NavLink
                        onClick={() => menuclick("usermanagement")}
                        to="/admin/users"
                      >
                        Users
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("usermanagement")}
                        to="/admin/role"
                      >
                        Role Management
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {(permission.indexOf("manage_merchants") > -1 ||
                  permission.indexOf("merchant_banking_details") > -1 ||
                  permission.indexOf("merchant_wallet_request") > -1 ||
                  permission.indexOf("merchant_credit_request") > -1) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setHideShow(hideShow === "none" ? "block" : "none");
                        }}
                      >
                        <i data-feather="clipboard" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        <span> Merchant</span>{" "}
                        <span className={arrowMerchant} />
                      </div>
                    </Link>
                    <ul style={{ display: hideShow }}>
                      {permission.indexOf("manage_merchants") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("merchant")}
                            to="/admin/merchant-registration-request"
                          >
                            Registration Requests
                          </NavLink>
                        </li>
                      )}
                      {permission.indexOf("manage_merchants") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("merchant")}
                            to="/admin/merchants"
                          >
                            Merchants
                          </NavLink>
                        </li>
                      )}
                      {permission.indexOf("merchant_wallet_request") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("merchant")}
                            to="/admin/merchant-wallet-request"
                          >
                            Merchant Wallet Request
                          </NavLink>
                        </li>
                      )}
                      {permission.indexOf("merchant_credit_request") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("merchant")}
                            to="/admin/merchant-credit-request"
                          >
                            Merchant Credit Request
                          </NavLink>
                        </li>
                      )}
                      {/* {(permission.indexOf('merchant_transactions_list') > -1 ) && 
                          <li><NavLink onClick={() => menuclick('merchant')} to="/admin/merchant-transactions">Merchant Transactions List</NavLink></li>
                      } */}
                      {permission.indexOf("merchant_banking_details") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("merchant")}
                            to="/admin/banking-details"
                          >
                            Banking Details
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </>
            ) : (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setHideShow(hideShow === "none" ? "block" : "none");
                      }}
                    >
                      <i data-feather="clipboard" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                      <span> Merchant</span> <span className={arrowMerchant} />
                    </div>
                  </Link>
                  <ul style={{ display: hideShow }}>
                    <li>
                      <NavLink
                        onClick={() => menuclick("merchant")}
                        to="/admin/merchant-registration-request"
                      >
                        Registration Requests
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("merchant")}
                        to="/admin/merchants"
                      >
                        Merchants
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("merchant")}
                        to="/admin/merchant-wallet-request"
                      >
                        Merchant Wallet Request
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("merchant")}
                        to="/admin/merchant-credit-request"
                      >
                        Merchant Credit Request
                      </NavLink>
                    </li>
                    {/* <li><NavLink onClick={() => menuclick('merchant')} to="/admin/merchant-transactions">Merchant Transactions List</NavLink></li> */}
                    <li>
                      <NavLink
                        onClick={() => menuclick("merchant")}
                        to="/admin/banking-details"
                      >
                        Banking Details
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {(permission.indexOf("manage_customers") > -1 ||
                  permission.indexOf("customer_wallet_request") > -1 ||
                  permission.indexOf("customer_credit_request") > -1 ||
                  permission.indexOf("customer_bookings") > -1) && (
                  // permission.indexOf('customer_wallet') > -1 ||
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setCustomerHideShow(
                            customerHideShow === "none" ? "block" : "none"
                          );
                        }}
                      >
                        <i data-feather="clipboard" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-users"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                          <circle cx="9" cy="7" r="4"></circle>
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>
                        <span> Customer</span>{" "}
                        <span className={arrowCustomer} />
                      </div>
                    </Link>
                    <ul style={{ display: customerHideShow }}>
                      {permission.indexOf("manage_customers") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("customer")}
                            to="/admin/customer"
                          >
                            Customers
                          </NavLink>
                        </li>
                      )}
                      {/* {(permission.indexOf('customer_wallet') > -1 ) &&
                      <li><NavLink onClick={() => menuclick('customer')} to="/admin/wallet-transaction">Customer Wallet</NavLink></li>
                    } */}

                      {permission.indexOf("customer_wallet_request") > -1 && (
                        <li className="submenu">
                          <Link to="#">
                            <div
                              onClick={() => {
                                setCustomerWalletHideShow(
                                  customerWalletHideShow === "none"
                                    ? "block"
                                    : "none"
                                );
                              }}
                            >
                              <i data-feather="clipboard" />
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg> */}
                              <span className="custom-sub-menu">
                                Customer Wallet Request
                              </span>{" "}
                              <span className={arrowCustomerWallet} />
                            </div>
                          </Link>
                          <ul style={{ display: customerWalletHideShow }}>
                            <li>
                              <NavLink
                                onClick={() => menuclick("card-wallet-request")}
                                to="/admin/wallet-request"
                              >
                                Cash/Cheque
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                onClick={() => menuclick("card-wallet-request")}
                                to="/admin/card-wallet-request"
                              >
                                Card
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                      {permission.indexOf("customer_credit_request") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("customer")}
                            to="/admin/customer-credit-request"
                          >
                            Customer Credit Request
                          </NavLink>
                        </li>
                      )}
                      {permission.indexOf("customer_bookings") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("customer")}
                            to="/admin/customer-booking-list"
                          >
                            Customer Booking List
                          </NavLink>
                        </li>
                      )}
                      {permission.indexOf("customer_credit_request") > -1 && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("customer")}
                            to="/admin/customer-credit-line"
                          >
                            Customer Credit Line
                          </NavLink>
                        </li>
                      )}
                      {/* <li><NavLink onClick={() => menuclick('customer')} to="/admin/view-booking">Customer Booking Details</NavLink></li> */}
                    </ul>
                  </li>
                )}
              </>
            ) : (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setCustomerHideShow(
                          customerHideShow === "none" ? "block" : "none"
                        );
                      }}
                    >
                      <i data-feather="clipboard" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                      <span> Customer</span> <span className={arrowCustomer} />
                    </div>
                  </Link>
                  <ul style={{ display: customerHideShow }}>
                    <li>
                      <NavLink
                        onClick={() => menuclick("customer")}
                        to="/admin/customer"
                      >
                        Customers
                      </NavLink>
                    </li>
                    {/* <li><NavLink onClick={() => menuclick('customer')} to="/admin/wallet-transaction">Customer Wallet</NavLink></li> */}
                    <li className="submenu">
                      <Link to="#">
                        <div
                          onClick={() => {
                            setCustomerWalletHideShow(
                              customerWalletHideShow === "none"
                                ? "block"
                                : "none"
                            );
                          }}
                        >
                          <i data-feather="clipboard" />
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg> */}
                          <span className="custom-sub-menu">
                            Customer Wallet Request
                          </span>{" "}
                          <span className={arrowCustomerWallet} />
                        </div>
                      </Link>
                      <ul style={{ display: customerWalletHideShow }}>
                        <li>
                          <NavLink
                            onClick={() => menuclick("card-wallet-request")}
                            to="/admin/wallet-request"
                          >
                            Cash/Cheque
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={() => menuclick("card-wallet-request")}
                            to="/admin/card-wallet-request"
                          >
                            Card
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("customer")}
                        to="/admin/customer-credit-request"
                      >
                        Customer Credit Request
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("customer")}
                        to="/admin/customer-booking-list"
                      >
                        Customer Booking List
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("customer")}
                        to="/admin/customer-credit-line"
                      >
                        Customer Credit Line
                      </NavLink>
                    </li>
                    {/* <li><NavLink onClick={() => menuclick('customer')} to="/admin/view-booking">Customer Booking Details</NavLink></li> */}
                  </ul>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {permission.indexOf("reports") > -1 && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setReport(report === "none" ? "block" : "none");
                        }}
                      >
                        <i data-feather="pie-chart" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-pie-chart"
                        >
                          <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                          <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                        </svg>
                        <span> Reports</span> <span className={arrowReport} />
                      </div>
                    </Link>
                    <ul style={{ display: report }}>
                      <li>
                        <NavLink
                          onClick={() => menuclick("report")}
                          to="/admin/view-earning-report"
                        >
                          View Earning Report
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => menuclick("report")}
                          to="/admin/customer-transaction-report"
                        >
                          Customer transaction Report
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => menuclick("report")}
                          to="/admin/customer-amount-due-report"
                        >
                          Customer Amount Due Report
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => menuclick("report")}
                          to="/admin/merchant-transaction-report"
                        >
                          Merchant Transaction Report
                        </NavLink>
                      </li>
                      {/* <li><NavLink onClick={() => menuclick('report')} to="/admin/merchant-amount-due-report">Merchant Amount Due Report</NavLink></li> */}
                    </ul>
                  </li>
                )}
              </>
            ) : (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setReport(report === "none" ? "block" : "none");
                      }}
                    >
                      <i data-feather="pie-chart" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-pie-chart"
                      >
                        <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                        <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                      </svg>
                      <span> Reports</span> <span className={arrowReport} />
                    </div>
                  </Link>
                  <ul style={{ display: report }}>
                    <li>
                      <NavLink
                        onClick={() => menuclick("report")}
                        to="/admin/view-earning-report"
                      >
                        View Earning Report
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("report")}
                        to="/admin/customer-transaction-report"
                      >
                        Customer Transaction Report
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("report")}
                        to="/admin/customer-amount-due-report"
                      >
                        Customer Amount Due Report
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("report")}
                        to="/admin/merchant-transaction-report"
                      >
                        Merchant Transaction Report
                      </NavLink>
                    </li>
                    {/* <li><NavLink onClick={() => menuclick('report')} to="/admin/merchant-amount-due-report">Merchant Amount Due Report</NavLink></li> */}
                  </ul>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {permission.indexOf("merchant_api_keys") > -1 && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setMerchantApi(
                            merchantApi === "none" ? "block" : "none"
                          );
                        }}
                      >
                        <i data-feather="edit" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit"
                        >
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </svg>
                        <span> Merchant API </span>{" "}
                        <span className={arrowMerchantApi} />
                      </div>
                    </Link>
                    <ul style={{ display: merchantApi }}>
                      <li>
                        <NavLink
                          onClick={() => menuclick("merchant-api-keys")}
                          to="/admin/merchant-api-key"
                        >
                          Merchant API Keys
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() =>
                            menuclick("merchant-api-documentation")
                          }
                          to="/admin/merchant-api-documentation"
                        >
                          API Documentation
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}
              </>
            ) : (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setMerchantApi(
                          merchantApi === "none" ? "block" : "none"
                        );
                      }}
                    >
                      <i data-feather="edit" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                      <span> Merchant API </span>{" "}
                      <span className={arrowMerchantApi} />
                    </div>
                  </Link>
                  <ul style={{ display: merchantApi }}>
                    <li>
                      <NavLink
                        onClick={() => menuclick("merchant-api-keys")}
                        to="/admin/merchant-api-key"
                      >
                        Merchant API Keys
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("merchant-api-documentation")}
                        to="/admin/merchant-api-documentation"
                      >
                        API Documentation
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {permission.indexOf("manage_investors") > -1 && (
                  <li onClick={() => menuclick("investor")}>
                    <NavLink to="/admin/investor">
                      <img
                        src="/inner-assets/img/investment.svg"
                        alt="investor"
                        style={{
                          height: "24px",
                          width: "24px",
                          marginLeft: "14px",
                        }}
                        className=""
                      />
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> */}
                      <span>Investors</span>
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              <>
                <li onClick={() => menuclick("investor")}>
                  <NavLink to="/admin/investor">
                    <img
                      src="/inner-assets/img/investment.svg"
                      alt="investor"
                      style={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "14px",
                      }}
                      className=""
                    />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> */}
                    <span>Investors</span>
                  </NavLink>
                </li>
              </>
            )}

            <li onClick={() => menuclick("newsbulletins")}>
              <NavLink to="/admin/news-bulletins">
                <i data-feather="news-bulletins" />
                <AnnouncementIcon />
                <span>News Bulletins</span>
              </NavLink>
            </li>

            <li onClick={() => menuclick("profile")}>
              <NavLink to="/admin/profile">
                <i data-feather="user" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span>Profile</span>
              </NavLink>
            </li>

            <li onClick={() => menuclick("settings")}>
              <NavLink to="/admin/setting">
                <i data-feather="settings" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-settings"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
                <span>Settings</span>
              </NavLink>
            </li>

            {role == "sub_admin" ? (
              <>
                {permission.indexOf("cr_penalty_setting") > -1 && (
                  <li onClick={() => menuclick("cr penalty setting")}>
                    <NavLink to="/admin/credit-request-penalty">
                      <i data-feather="credit-request-penalty" />
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> */}
                      <SettingsApplicationsIcon />
                      <span>CR Penalty Setting</span>
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              <>
                <li onClick={() => menuclick("cr penalty setting")}>
                  <NavLink to="/admin/credit-request-penalty">
                    <i data-feather="credit-request-penalty" />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> */}
                    <SettingsApplicationsIcon />
                    <span>CR Penalty Setting</span>
                  </NavLink>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {permission.indexOf("deposit_options") > -1 && (
                  <li onClick={() => menuclick("deposit option")}>
                    <NavLink to="/admin/deposit-option">
                      <i data-feather="credit-request-penalty" />
                      <i
                        className="fas fa-money-bill-wave"
                        style={{ color: "#7638ff" }}
                      ></i>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> */}
                      <span>Deposit Option</span>
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              <>
                <li onClick={() => menuclick("deposit option")}>
                  <NavLink to="/admin/deposit-option">
                    <i data-feather="credit-request-penalty" />
                    <i
                      className="fas fa-money-bill-wave"
                      style={{ color: "#7638ff" }}
                    ></i>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> */}
                    <span>Deposit Option</span>
                  </NavLink>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {permission.indexOf("banking_options") > -1 && (
                  <li onClick={() => menuclick("banking options")}>
                    <NavLink to="/admin/banking-options">
                      <i data-feather="banking-options" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-briefcase"
                      >
                        <rect
                          x="2"
                          y="7"
                          width="20"
                          height="14"
                          rx="2"
                          ry="2"
                        ></rect>
                        <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                      </svg>
                      <span>Banking Options</span>
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              <>
                <li onClick={() => menuclick("banking options")}>
                  <NavLink to="/admin/banking-options">
                    <i data-feather="banking-options" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-briefcase"
                    >
                      <rect
                        x="2"
                        y="7"
                        width="20"
                        height="14"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                    </svg>
                    <span>Banking Options</span>
                  </NavLink>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {permission.indexOf("manage_banks") > -1 && (
                  <li onClick={() => menuclick("banks")}>
                    <NavLink to="/admin/banks">
                      <i data-feather="banks" />
                      <AccountBalanceIcon />
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg> */}
                      <span>Banks</span>
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              <>
                <li onClick={() => menuclick("banks")}>
                  <NavLink to="/admin/banks">
                    <i data-feather="banks" />
                    <AccountBalanceIcon />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg> */}
                    <span>Banks</span>
                  </NavLink>
                </li>
              </>
            )}

            {role == "sub_admin" ? (
              <>
                {permission.indexOf("manage_feedback") > -1 && (
                  <li onClick={() => menuclick("feedback")}>
                    <NavLink to="/admin/feedback">
                      <i data-feather="feedback" />
                      <RateReviewIcon />
                      <span>Feedbacks</span>
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              <>
                <li onClick={() => menuclick("feedback")}>
                  <NavLink to="/admin/feedback">
                    <i data-feather="feedback" />
                    <RateReviewIcon />
                    <span>Feedbacks</span>
                  </NavLink>
                </li>
              </>
            )}
            
            {role == "sub_admin" ? (
              <>
                {permission.indexOf("manage_contact_us") > -1 && (
                  <li onClick={() => menuclick("contact_us")}>
                    <NavLink to="/admin/contact-us">
                      <i data-feather="contact_us" />
                      <ForumIcon />
                      <span>Contact Us</span>
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              <>
                <li onClick={() => menuclick("contact_us")}>
                  <NavLink to="/admin/contact-us">
                    <i data-feather="contact_us" />
                    <ForumIcon />
                    <span>Contact Us</span>
                  </NavLink>
                </li>
              </>
            )}

            <li onClick={() => menuclick("notification")}>
              <NavLink to="/admin/notifications">
                <i data-feather="bells" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-bell"
                >
                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg>
                <span>Notifications</span>
              </NavLink>
            </li>

            <li onClick={() => menuclick("faq")}>
              <NavLink to="/admin/faq-list">
                <i data-feather="help-circle" />
                <HelpIcon />
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-bell"
                >
                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg> */}
                <span>Faq</span>
              </NavLink>
            </li>

            <li onClick={() => menuclick("logout")}>
              <NavLink to="/admin/logout">
                <i data-feather="log-out" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AdminSidebar;
