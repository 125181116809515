import { Route, Routes, useLocation } from "react-router-dom";
import {
  EditMerchant,
  Merchants,
  MerchantTransaction,
  MerchantAgreement,
  MerchantLegalDocument,
  AddWalletAmount,
  MerchantCreditRequest,
  MerchantListAllCreditRequest,
  AddMerchant,
  MerchantWalletRequest,
  EditWalletAmount,
  BankAccounts,
  MerchantStatement,
  MerchantRegistrationRequest,
} from "../Components/Admin/Merchant";
import {
  Customer,
  CustomerBookingList,
  EditCustomer,
  AddCustomer,
  BookingPaid,
  BookingOverdue,
  BookingFailed,
  ViewBooking,
  BookingCancelled,
  WalletTransaction,
  WalletRequest,
  WalletDetails,
  CardWalletRequest,
  ServiceCharge,
  AddServiceCharge,
  EditServiceCharge,
  CustomerCreditRequestDetail,
  CustomerStatement,
  CustomerCreditRequest,
  CustomerLegalDocument,
  CustomerAgreement,
  CustomerCreditLineReport,
} from "../Components/Admin/Customer";
import { AdminLayout } from "../Components/Layout";
import PrivateRoute from "./PrivateRoute";
import { useEffect } from "react";
import { Notification } from "../Components/Admin/Notification";
import {
  ChangePassword,
  Settings,
  CRPenaltyRequest,
} from "../Components/Admin/settings";
import {
  BankingOptions,
  AddBankingOption,
  EditBankingOption,
} from "../Components/Admin/Banking";
import {
  MerchantApiKeys,
  ApiDocumentation,
} from "../Components/Admin/MerchantApi";
import {
  AddRoleManagement,
  EditRolePermission,
  Role,
} from "../Components/Admin/RoleManagement";
import { AddBank, Banks, EditBank } from "../Components/Admin/Banks";
import { DepositOption } from "../Components/Admin/DepositOption";
import ComponentPrint from "../Components/Admin/Common/ComponentPrint";
import {
  AddInvestor,
  Investor,
  InvestorFundStatement,
  ViewFund,
} from "../Components/Admin/Investor";
import { AddUser, EditUser, User } from "../Components/Admin/UserManagement";
import { Dashboard } from "../Components/Admin/Dashboard";
import { Profile } from "../Components/Admin/Profile";
import { useState } from "react";
import { PermissionAlert } from "../Components/Admin/PermissionAlert";
import { ListNews, AddNews, EditNews } from "../Components/Admin/NewsBulletins";
import {
  CustomerAmountDueReport,
  CustomerTransactionReport,
  MerchantTransactionReport,
  ViewEarningReport,
} from "../Components/Admin/Reports";
import { AddFaq, EditFaq, FaqList } from "../Components/Admin/Faq";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("adminPermission");
    window.location.assign("/");
  }, []);
};
const AdminRoutesPermission = () => {
  const location = useLocation();
  const pathname = location.pathname.slice(7);
  const route = pathname.split("/")[0];
  const [loading, setLoading] = useState(true);
  const permission = JSON.parse(localStorage.getItem("adminPermission"));

  useEffect(() => {
    setLoading(false);
  }, [permission]);

  return (
    <>
      <AdminLayout />
      {!loading && (
        <Routes>
          <Route
            exact
            path="/"
            element={<PrivateRoute Component={Dashboard} />}
          />
          <Route
            exact
            path="/dashboard"
            element={<PrivateRoute Component={Dashboard} />}
          />

          {permission?.indexOf("user_management") !== -1 &&
          route === "users" ? (
            <Route
              exact
              path="/users"
              element={<PrivateRoute Component={User} />}
            />
          ) : (
            <Route exact path="/users" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("user_management") !== -1 &&
          route === "add-user" ? (
            <Route
              exact
              path="/add-user"
              element={<PrivateRoute Component={AddUser} />}
            />
          ) : (
            <Route exact path="/add-user" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("user_management") !== -1 &&
          route === "edit-user" ? (
            <Route
              exact
              path="/edit-user"
              element={<PrivateRoute Component={EditUser} />}
            />
          ) : (
            <Route exact path="/edit-user" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("role_management") !== -1 && route === "role" ? (
            <Route
              exact
              path="/role"
              element={<PrivateRoute Component={Role} />}
            />
          ) : (
            <Route exact path="/role" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("role_management") !== -1 &&
          route === "add-role-management" ? (
            <Route
              exact
              path="/add-role-management"
              element={<PrivateRoute Component={AddRoleManagement} />}
            />
          ) : (
            <Route
              exact
              path="/add-role-management"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("role_management") !== -1 &&
          route === "edit-role-permission" ? (
            <Route
              exact
              path="/edit-role-permission"
              element={<PrivateRoute Component={EditRolePermission} />}
            />
          ) : (
            <Route
              exact
              path="/edit-role-permission"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_customers") !== -1 &&
          route === "customer" ? (
            <Route
              exact
              path="/customer"
              element={<PrivateRoute Component={Customer} />}
            />
          ) : (
            <Route exact path="/customer" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_customers") !== -1 &&
          route === "add-customer" ? (
            <Route
              exact
              path="/add-customer"
              element={<PrivateRoute Component={AddCustomer} />}
            />
          ) : (
            <Route exact path="/add-customer" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_customers") !== -1 &&
          route === "edit-customer" ? (
            <Route
              exact
              path="/edit-customer"
              element={<PrivateRoute Component={EditCustomer} />}
            />
          ) : (
            <Route exact path="/edit-customer" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("customer_bookings") !== -1 &&
          route === "customer-booking-list" ? (
            <Route
              exact
              path="/customer-booking-list"
              element={<PrivateRoute Component={CustomerBookingList} />}
            />
          ) : (
            <Route
              exact
              path="/customer-booking-list"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("customer_credit_request") !== -1 &&
          route === "customer-credit-line" ? (
            <Route
              exact
              path="/customer-credit-line"
              element={<PrivateRoute Component={CustomerCreditLineReport} />}
            />
          ) : (
            <Route
              exact
              path="/customer-credit-line"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("customer_bookings") !== -1 &&
          route === "booking-paid" ? (
            <Route
              exact
              path="/booking-paid"
              element={<PrivateRoute Component={BookingPaid} />}
            />
          ) : (
            <Route exact path="/booking-paid" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("customer_bookings") !== -1 &&
          route === "booking-overdue" ? (
            <Route
              exact
              path="/booking-overdue"
              element={<PrivateRoute Component={BookingOverdue} />}
            />
          ) : (
            <Route
              exact
              path="/booking-overdue"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("customer_bookings") !== -1 &&
          route === "booking-failed" ? (
            <Route
              exact
              path="/booking-failed"
              element={<PrivateRoute Component={BookingFailed} />}
            />
          ) : (
            <Route exact path="/booking-failed" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("customer_bookings") !== -1 &&
          route === "booking-cancelled" ? (
            <Route
              exact
              path="/booking-cancelled"
              element={<PrivateRoute Component={BookingCancelled} />}
            />
          ) : (
            <Route
              exact
              path="/booking-cancelled"
              element={<PermissionAlert />}
            />
          )}
          {/* <Route exact path="/view-booking" element={<PrivateRoute Component={ViewBooking} />} /> */}

          {permission?.indexOf("customer_bookings") !== -1 &&
          route === "view-booking" ? (
            <Route
              exact
              path="/view-booking"
              element={<PrivateRoute Component={ComponentPrint} />}
            />
          ) : (
            <Route exact path="/view-booking" element={<PermissionAlert />} />
          )}

          {/* {permission?.indexOf("customer_wallet")!==-1 && route==="wallet-transaction" ?  
                <Route exact path="/wallet-transaction" element={<PrivateRoute Component={WalletTransaction} />} />:
                <Route exact path="/wallet-transaction" element={<PermissionAlert/>} /> 
            } */}

          {permission?.indexOf("customer_wallet_request") !== -1 &&
          route === "wallet-request" ? (
            <Route
              exact
              path="/wallet-request"
              element={<PrivateRoute Component={WalletRequest} />}
            />
          ) : (
            <Route exact path="/wallet-request" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("customer_wallet_request") !== -1 &&
          route === "card-wallet-request" ? (
            <Route
              exact
              path="/card-wallet-request"
              element={<PrivateRoute Component={CardWalletRequest} />}
            />
          ) : (
            <Route
              exact
              path="/card-wallet-request"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("customer_wallet_request") !== -1 &&
          route === "wallet-details" ? (
            <Route
              exact
              path="/wallet-details/:id"
              element={<PrivateRoute Component={WalletDetails} />}
            />
          ) : (
            <Route
              exact
              path="/wallet-details/:id"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("merchant_wallet_request") !== -1 &&
          route === "merchant-wallet-request" ? (
            <Route
              exact
              path="/merchant-wallet-request"
              element={<PrivateRoute Component={MerchantWalletRequest} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-wallet-request"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_customers") !== -1 &&
          route === "customer-agreement" ? (
            <Route
              exact
              path="/customer-agreement"
              element={<PrivateRoute Component={CustomerAgreement} />}
            />
          ) : (
            <Route
              exact
              path="/customer-agreement"
              element={<PermissionAlert />}
            />
          )}
          {permission?.indexOf("manage_customers") !== -1 &&
          route === "customer-legaldocument" ? (
            <Route
              exact
              path="/customer-legaldocument"
              element={<PrivateRoute Component={CustomerLegalDocument} />}
            />
          ) : (
            <Route
              exact
              path="/customer-legaldocument"
              element={<PermissionAlert />}
            />
          )}
          {permission?.indexOf("manage_customers") !== -1 &&
          route === "customer-statement" ? (
            <Route
              exact
              path="/customer-statement"
              element={<PrivateRoute Component={CustomerStatement} />}
            />
          ) : (
            <Route
              exact
              path="/customer-statement"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_merchants") !== -1 &&
          route === "merchant-agreement" ? (
            <Route
              exact
              path="/merchant-agreement"
              element={<PrivateRoute Component={MerchantAgreement} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-agreement"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_merchants") !== -1 &&
          route === "merchant-registration-request" ? (
            <Route
              exact
              path="/merchant-registration-request"
              element={<PrivateRoute Component={MerchantRegistrationRequest} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-registration-request"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_merchants") !== -1 &&
          route === "merchants" ? (
            <Route
              exact
              path="/merchants"
              element={<PrivateRoute Component={Merchants} />}
            />
          ) : (
            <Route exact path="/merchants" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_merchants") !== -1 &&
          route === "edit-merchant" ? (
            <Route
              exact
              path="/edit-merchant"
              element={<PrivateRoute Component={EditMerchant} />}
            />
          ) : (
            <Route exact path="/edit-merchant" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_merchants") !== -1 &&
          route === "add-merchant" ? (
            <Route
              exact
              path="/add-merchant"
              element={<PrivateRoute Component={AddMerchant} />}
            />
          ) : (
            <Route exact path="/add-merchant" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_merchants") !== -1 &&
          route === "merchant-legaldocument" ? (
            <Route
              exact
              path="/merchant-legaldocument"
              element={<PrivateRoute Component={MerchantLegalDocument} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-legaldocument"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_merchants") !== -1 &&
          route === "merchant-statement" ? (
            <Route
              exact
              path="/merchant-statement"
              element={<PrivateRoute Component={MerchantStatement} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-statement"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("merchant_wallet_request") !== -1 &&
          route === "add-wallet-amount" ? (
            <Route
              exact
              path="/add-wallet-amount"
              element={<PrivateRoute Component={AddWalletAmount} />}
            />
          ) : (
            <Route
              exact
              path="/add-wallet-amount"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("merchant_wallet_request") !== -1 &&
          route === "edit-wallet-amount" ? (
            <Route
              exact
              path="/edit-wallet-amount"
              element={<PrivateRoute Component={EditWalletAmount} />}
            />
          ) : (
            <Route
              exact
              path="/edit-wallet-amount"
              element={<PermissionAlert />}
            />
          )}

          {/* <Route exact path="/merchant-transactions" element={<PrivateRoute Component={MerchantTransaction} />} /> */}

          {/* {permission?.indexOf("customer_wallet_request")!==-1 && route==="wallet-details" ?
            <Route exact path="/merchant-transactions" element={<PrivateRoute Component={MerchantTransaction} />} />:
            <Route exact path="/wallet-details" element={<PermissionAlert/>} /> 
        } */}

          {permission?.indexOf("merchant_banking_details") !== -1 &&
          route === "banking-details" ? (
            <Route
              exact
              path="/banking-details"
              element={<PrivateRoute Component={BankAccounts} />}
            />
          ) : (
            <Route
              exact
              path="/banking-details"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("merchant_api_keys") !== -1 &&
          route === "merchant-api-key" ? (
            <Route
              exact
              path="/merchant-api-key"
              element={<PrivateRoute Component={MerchantApiKeys} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-api-key"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("merchant_api_keys") !== -1 &&
          route === "merchant-api-documentation" ? (
            <Route
              exact
              path="/merchant-api-documentation"
              element={<PrivateRoute Component={ApiDocumentation} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-api-documentation"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("merchant_credit_request") !== -1 &&
          route === "merchant-credit-request-add" ? (
            <Route
              exact
              path="/merchant-credit-request-add"
              element={<PrivateRoute Component={MerchantCreditRequest} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-credit-request-add"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("merchant_credit_request") !== -1 &&
          route === "merchant-credit-request" ? (
            <Route
              exact
              path="/merchant-credit-request"
              element={
                <PrivateRoute Component={MerchantListAllCreditRequest} />
              }
            />
          ) : (
            <Route
              exact
              path="/merchant-credit-request"
              element={<PermissionAlert />}
            />
          )}

          <Route
            exact
            path="/profile"
            element={<PrivateRoute Component={Profile} />}
          />
          <Route
            exact
            path="/notifications"
            element={<PrivateRoute Component={Notification} />}
          />
          <Route
            exact
            path="/setting"
            element={<PrivateRoute Component={Settings} />}
          />
          <Route
            exact
            path="/setting/change-password"
            element={<PrivateRoute Component={ChangePassword} />}
          />
          <Route
            exact
            path="/edit-banking-options"
            element={<PrivateRoute Component={EditBankingOption} />}
          />

          {permission?.indexOf("customer_credit_request") !== -1 &&
          route === "customer-credit-request" ? (
            <Route
              exact
              path="/customer-credit-request"
              element={<PrivateRoute Component={CustomerCreditRequest} />}
            />
          ) : (
            <Route
              exact
              path="/customer-credit-request"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("customer_credit_request") !== -1 &&
          route === "customer-credit-request-details" ? (
            <Route
              exact
              path="/customer-credit-request-details"
              element={<PrivateRoute Component={CustomerCreditRequestDetail} />}
            />
          ) : (
            <Route
              exact
              path="/customer-credit-request-details"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("cr_penalty_setting") !== -1 &&
          route === "credit-request-penalty" ? (
            <Route
              exact
              path="/credit-request-penalty"
              element={<PrivateRoute Component={CRPenaltyRequest} />}
            />
          ) : (
            <Route
              exact
              path="/credit-request-penalty"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("deposit_options") !== -1 &&
          route === "deposit-option" ? (
            <Route
              exact
              path="/deposit-option"
              element={<PrivateRoute Component={DepositOption} />}
            />
          ) : (
            <Route exact path="/deposit-option" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("banking_options") !== -1 &&
          route === "banking-options" ? (
            <Route
              exact
              path="/banking-options"
              element={<PrivateRoute Component={BankingOptions} />}
            />
          ) : (
            <Route
              exact
              path="/banking-options"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("banking_options") !== -1 &&
          route === "add-banking-options" ? (
            <Route
              exact
              path="/add-banking-options"
              element={<PrivateRoute Component={AddBankingOption} />}
            />
          ) : (
            <Route
              exact
              path="/add-banking-options"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_customers") !== -1 &&
          route === "service-charge" ? (
            <Route
              exact
              path="/service-charge"
              element={<PrivateRoute Component={ServiceCharge} />}
            />
          ) : (
            <Route exact path="/service-charge" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_customers") !== -1 &&
          route === "add-service-charge" ? (
            <Route
              exact
              path="/add-service-charge"
              element={<PrivateRoute Component={AddServiceCharge} />}
            />
          ) : (
            <Route
              exact
              path="/add-service-charge"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_customers") !== -1 &&
          route === "edit-service-charge" ? (
            <Route
              exact
              path="/edit-service-charge"
              element={<PrivateRoute Component={EditServiceCharge} />}
            />
          ) : (
            <Route
              exact
              path="/edit-service-charge"
              element={<PermissionAlert />}
            />
          )}

          {/* {permission?.indexOf("reports")!==-1 && route==="wallet-report" ?
                <Route exact path="/wallet-report" element={<PrivateRoute Component={WalletReport} />} />:
                <Route exact path="/wallet-report" element={<PermissionAlert/>} /> 
            } */}

          {permission?.indexOf("manage_banks") !== -1 && route === "banks" ? (
            <Route
              exact
              path="/banks"
              element={<PrivateRoute Component={Banks} />}
            />
          ) : (
            <Route exact path="/banks" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_banks") !== -1 &&
          route === "add-banks" ? (
            <Route
              exact
              path="/add-banks"
              element={<PrivateRoute Component={AddBank} />}
            />
          ) : (
            <Route exact path="/add-banks" element={<PermissionAlert />} />
          )}
          {permission?.indexOf("manage_banks") !== -1 &&
          route === "edit-banks" ? (
            <Route
              exact
              path="/edit-banks"
              element={<PrivateRoute Component={EditBank} />}
            />
          ) : (
            <Route exact path="/edit-banks" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_investors") !== -1 &&
          route === "investor" ? (
            <Route
              exact
              path="/investor"
              element={<PrivateRoute Component={Investor} />}
            />
          ) : (
            <Route exact path="/investor" element={<PermissionAlert />} />
          )}
          {permission?.indexOf("manage_investors") !== -1 &&
          route === "investor-fund-statement" ? (
            <Route
              exact
              path="/investor-fund-statement"
              element={<PrivateRoute Component={InvestorFundStatement} />}
            />
          ) : (
            <Route
              exact
              path="/investor-fund-statement"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("manage_investors") !== -1 &&
          route === "add-investor" ? (
            <Route
              exact
              path="/add-investor"
              element={<PrivateRoute Component={AddInvestor} />}
            />
          ) : (
            <Route exact path="/add-investor" element={<PermissionAlert />} />
          )}

          {permission?.indexOf("manage_investors") !== -1 &&
          route === "view-fund" ? (
            <Route
              exact
              path="/view-fund"
              element={<PrivateRoute Component={ViewFund} />}
            />
          ) : (
            <Route exact path="/view-fund" element={<PermissionAlert />} />
          )}

          {/* Reports routes */}

          {permission?.indexOf("reports") !== -1 &&
          route === "customer-transaction-report" ? (
            <Route
              exact
              path="/customer-transaction-report"
              element={<PrivateRoute Component={CustomerTransactionReport} />}
            />
          ) : (
            <Route
              exact
              path="/customer-transaction-report"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("reports") !== -1 &&
          route === "customer-amount-due-report" ? (
            <Route
              exact
              path="/customer-amount-due-report"
              element={<PrivateRoute Component={CustomerAmountDueReport} />}
            />
          ) : (
            <Route
              exact
              path="/customer-amount-due-report"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("reports") !== -1 &&
          route === "merchant-transaction-report" ? (
            <Route
              exact
              path="/merchant-transaction-report"
              element={<PrivateRoute Component={MerchantTransactionReport} />}
            />
          ) : (
            <Route
              exact
              path="/merchant-transaction-report"
              element={<PermissionAlert />}
            />
          )}

          {permission?.indexOf("reports") !== -1 &&
          route === "view-earning-report" ? (
            <Route
              exact
              path="/view-earning-report"
              element={<PrivateRoute Component={ViewEarningReport} />}
            />
          ) : (
            <Route
              exact
              path="/view-earning-report"
              element={<PermissionAlert />}
            />
          )}

          {/* <Route exact path="/customer-transaction-report" element={<PrivateRoute Component={CustomerTransactionReport} />} />
            <Route exact path="/customer-amount-due-report" element={<PrivateRoute Component={CustomerAmountDueReport} />} />
            <Route exact path="/merchant-transaction-report" element={<PrivateRoute Component={MerchantTransactionReport} />} />
            <Route exact path="/view-earning-report" element={<PrivateRoute Component={ViewEarningReport} />} /> */}

          {/* Routes for news bulletins */}
          <Route
            exact
            path="/news-bulletins"
            element={<PrivateRoute Component={ListNews} />}
          />
          <Route
            exact
            path="/add-news"
            element={<PrivateRoute Component={AddNews} />}
          />
          <Route
            exact
            path="/edit-news"
            element={<PrivateRoute Component={EditNews} />}
          />

          {/* Routes for faq */}
          <Route
            exact
            path="/faq-list"
            element={<PrivateRoute Component={FaqList} />}
          />
          <Route
            exact
            path="/add-faq"
            element={<PrivateRoute Component={AddFaq} />}
          />
          <Route
            exact
            path="/edit-faq"
            element={<PrivateRoute Component={EditFaq} />}
          />

          <Route exact path="/logout" element={<Logout />} />
        </Routes>
      )}
    </>
  );
};
export default AdminRoutesPermission;
