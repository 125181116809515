import React,{useEffect, useRef, useState } from 'react'
import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'

const AdminLayout = () => {

  const [openSidebar, setOpenSidebar] = useState(true);
  const sidebarRef = useRef(null); // used to get sidebar width
  const [isMobile, setMobile] = useState(document.body.clientWidth < 991);
  // use 'init' | 'open' | 'close', that you don't need remember if suer clicked
  const [sidebarStatus, setSidebarStatus] = useState("init");

  useEffect(() => {
    // add listener only once, or many listeners would be created every render
    const mq = window.matchMedia("(max-width: 991px)");
    mq.addListener((res) => {
      setMobile(res.matches);
    });
    return () => mq.removeListener(toggleSidebar);
  }, []);

  const showSidebar =
    sidebarStatus === "open" || (!isMobile && sidebarStatus === "init");

  const toggleSidebar = (open) => {
    setSidebarStatus(open ? "open" : "close");
  };
  return (
    <>
      <div className="header header-one">
        <AdminHeader onClick={toggleSidebar} showSideBar={showSidebar}/>
      </div>
      <div className="sidebar" id="sidebar" ref={sidebarRef}>
      {showSidebar && <AdminSidebar  setOpenSidebar={openSidebar} handleonClick={toggleSidebar} showSideBar={showSidebar}/>}
      </div>
      {/* <footer class="text-center text-lg-start" style="bottom: 0;position: absolute;width: 90%;">
      <div class="text-center p-2" style="">
        Powered by Alsaffron System.
      </div>
      </footer> */}
    </>
  )
}
export default AdminLayout