import Api from "../../Utils/Axios";

export const PostBankingOption = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/bank_account/admin/add', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetBankingOptionPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/bank_account`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetBankingAccountingOption = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/bank_account/admin/merchants?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetBankingOptionById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/bankingoption/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const PutBankingOption = async ({ id, data }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put(`/admin/bankingoption/${id}`, data));
    } catch (error) {
      reject(error);
    }
  })
}
export const DeleteBankingOption = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/admin/bankingoption/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const StatusBankingOption = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/bank_account/admin/change_status", data));
    } catch (error) {
      reject(error);
    }
  })
}

export const PostBankAdd = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/banks/add', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetBankingList = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/banks?all=1`));
    } catch (error) {
      reject(error);
    }
  })
}

export const StatusBank = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/banks/change_status", data));
    } catch (error) {
      reject(error);
    }
  })
}

export const AddBankingListOptions = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/banks?all=0`));
    } catch (error) {
      reject(error);
    }
  })
}

export const getBankingOptionByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/bank_account/${id}`));
    } catch (error) {
      reject(error);
    }
  })
};

export const getEditBankingOptionService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("master/bank_account/admin/update",data));
    } catch (error) {
      reject(error);
    }
  })
};