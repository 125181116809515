import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { Editor } from "@tinymce/tinymce-react";
import { GetMerchantPaginate } from "../../../Services/Api/merchant";
import { GetCustomerUserPaginate } from "../../../Services/Api/customer";
import { AddNewsBulletin } from "../../../Services/Api/newsBulletins";

const AddNews = () => {
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const [customerList, setCustomerList] = useState([]);
  const [MerchantList, setMerchantList] = useState([]);

  const signInSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
    type: Yup.string().required("Please select type"),
    users: Yup.array().when("userRequire", {
      is: true,
      then: Yup.array().min(1, "Please select at-least one user"),
    }),
  });

  const initialValues = {
    userRequire: false,
    message: "",
    type: "",
    users: [],
  };

  const typeOfUser = [
    { label: "All User", value: "all_user" },
    { label: "All Customer", value: "all_customer" },
    { label: "All Merchant", value: "all_merchant" },
    { label: "Customer", value: "customer" },
    { label: "Merchant", value: "merchant" },
  ];

  const getCustomerList = async () => {
    await GetCustomerUserPaginate({ limit: 10, page: 0, paginate: false })
      .then((res) => {
        if (res?.data?.data?.customers.length > 0) {
          let customerArr = res?.data?.data?.customers;
          customerArr = customerArr.map((value) => {
            return {
              label: value.name,
              value: value.id,
            };
          });
          setCustomerList(customerArr);
        } else {
          // ErrorToast("No Results.");
          setCustomerList([]);
        }
      })
      .catch((err) => {
        setCustomerList([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const getMerchantList = async () => {
    await GetMerchantPaginate({ limit: 10, page: 0, paginate: false })
      .then((res) => {
        if (res?.data?.data?.merchants.length > 0) {
          let merchantArr = res?.data?.data?.merchants;
          merchantArr = merchantArr.map((value) => {
            return {
              label: value.name,
              value: value.id,
            };
          });
          setMerchantList(merchantArr);
        } else {
          // ErrorToast("No Results.");
          setMerchantList([]);
        }
      })
      .catch((err) => {
        setMerchantList([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  useEffect(() => {
    getCustomerList();
    getMerchantList();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Add News</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add News</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/news-bulletins">News Bulletin</Link>
                  </li>
                  <li className="breadcrumb-item active">Add News</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/news-bulletins"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">News Info</h4>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        message: values.message,
                        start_date: "",
                        end_date: "",
                        type: values.type,
                        users: values.users || [],
                      };
                      await AddNewsBulletin(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "News added successfully."
                          );
                          setSubmitting(false);
                          navigate("/admin/news-bulletins");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { isSubmitting, setFieldValue, values } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>
                                  Message <span className="text-danger">*</span>
                                </label>
                                <Editor
                                  apiKey="0cjhdp8cn3vtdkcr174ql1pan43comxnovk9u1cqs95aa80f"
                                  onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                  }
                                  initialValue=""
                                  textareaName="message"
                                  onEditorChange={(newValue, editor) =>
                                    setFieldValue("message", newValue)
                                  }
                                  init={{
                                    height: 300,
                                    menubar: false,
                                    // plugins: [
                                    //   'advlist autolink lists link image charmap print preview anchor',
                                    //   'searchreplace visualblocks code fullscreen',
                                    //   'insertdatetime media table paste code help wordcount emoticons'
                                    // ],
                                    // toolbar: 'undo redo | formatselect | ' +
                                    // 'bold italic backcolor | alignleft aligncenter ' +
                                    // 'alignright alignjustify | bullist numlist outdent indent | ' +
                                    // 'removeformat | help | emoticons',
                                    plugins:
                                      "emoticons code anchor link textcolor",
                                    toolbar:
                                      "emoticons | bold italic link forecolor | undo redo | code",
                                    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    setup: function (ed) {
                                      ed.on("keydown", function (e) {
                                        if (
                                          e.code === "Enter" ||
                                          e.code === "NumpadEnter"
                                        ) {
                                          e.preventDefault();
                                        }
                                      });
                                    },
                                  }}
                                />
                                <ErrorMessage
                                  name="message"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Type <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={typeOfUser}
                                onChange={(e) => {
                                  setFieldValue("type", e.value);
                                  if (e.value === "customer") {
                                    getCustomerList();
                                    setFieldValue("userRequire", true);
                                  } else if (e.value === "merchant") {
                                    getMerchantList();
                                    setFieldValue("userRequire", true);
                                  } else {
                                    setFieldValue("users", []);
                                    setFieldValue("userRequire", false);
                                  }
                                }}
                                name="type"
                                placeholder="Select type"
                              />
                              <ErrorMessage
                                name="type"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>

                          {(values.type === "customer" ||
                            values.type === "merchant") &&
                            (customerList.length > 0 ||
                              MerchantList.length > 0) && (
                              <div className="card-header">
                                <h5 className="card-title">
                                  Users <span className="text-danger">*</span>
                                </h5>
                              </div>
                            )}
                          <ErrorMessage
                            name="users"
                            component="span"
                            className="error"
                          />
                          {values.type === "customer" &&
                            customerList.length > 0 && (
                              <div className="row mx-3 my-4">
                                {customerList.map((customer, index) => (
                                  <div className="col-md-3" key={index}>
                                    <div className="form-group">
                                      <div
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                      >
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name="users"
                                            value={customer?.value}
                                          />
                                          &nbsp;&nbsp;&nbsp;
                                          {customer.label}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          {values.type === "merchant" &&
                            MerchantList.length > 0 && (
                              <div className="row mx-3 my-4">
                                {MerchantList.map((merchant, index) => (
                                  <div className="col-md-3" key={index}>
                                    <div className="form-group">
                                      <div
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                      >
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name="users"
                                            value={merchant?.value}
                                          />
                                          &nbsp;&nbsp;&nbsp;
                                          {merchant.label}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}

                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add News
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNews;
