import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { PostMerchant } from '../../../Services/Api/merchant';
import { ErrorToast, SuccessToast } from '../../../Utils/SweetAlert';
import { GetCountry, GetCRPenaltySetting } from '../../../Services/Api/master';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import YupPassword from "yup-password";
import { Helmet } from 'react-helmet-async';
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { useDebounce } from 'use-debounce';
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import { url } from '../../../Utils/constants';

YupPassword(Yup);

const AddMerchant = () => {
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [imageKey, setImageKey] = useState(0);
  const [debounceKey] = useDebounce(imageKey, 1000);

  let dfltFormData = {
    profile_picture: "",
    vatNumber : "",
    email: "",
    name: "",
    compnayName: "",
    website:"",
    currency: "",
    pName: "",
    state: "",
    address: "",
    city: "",
    bphoneNumber: "",
    country: "",
    zipcode: "",
    tphoneNumber: "",
    mobileNumber: "",
    password: "",
    confirm_password: "",
    cr_due_days: "",
    cr_penalty_start_days: "",
    cr_penalty_amount: "",
    allow_cash_credit: "1"
  };
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [country, setCountry] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [initData, setInitData] = useState(dfltFormData);
  const [id, setId] = useState();
  const [dfltCr, setDfltCr] = useState(null);

  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/

  const getCountryData = async () => {
    await GetCountry().then((res) => {
      const resCountryData = res?.data?.data || [];
      const countryFilterData = resCountryData.map((country) => {
        return {
          label: country?.name,
          value: country?.name
        }
      });
      const currencyFilterData = resCountryData.map((country) => {
        return {
          label: `${country?.currency} - ${country?.name}`,
          value: country?.currency,
        };
      });
      setCountry(countryFilterData);
      setCurrency(currencyFilterData);
    });
    await GetCRPenaltySetting().then((res) => {
      const dfltDataCRP = res?.data?.data?.creditReqPenalty || null;
      setInitData({ ...initData, cr_due_days: dfltDataCRP.cr_due_days, cr_penalty_start_days: dfltDataCRP.cr_penalty_start_days, cr_penalty_amount: dfltDataCRP.cr_penalty_amount })
      setDfltCr(dfltDataCRP);
    });
  }

  useEffect(() => {
    let items = JSON.parse(localStorage.getItem('userData'));
    setId(items._id);
    getCountryData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Add Merchant</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Merchants</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to="/admin/merchants">Merchants</Link></li>
                  <li className="breadcrumb-item active">Add Merchant</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/merchants" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initData}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email().required("Email is required"),
                      name: Yup.string().required("Name is required"),
                      vatNumber: Yup.string().required("VAT number is required"),
                      compnayName: Yup.string().required("Compnay name is required"),
                      website: Yup.string().matches(url, 'Invalid URL format').required('Website URL is required'),
                      mobileNumber: Yup.string().matches(phoneRegExp, "Mobile number is not valid").required('Mobile number is required'),
                      tphoneNumber: Yup.string().matches(phoneRegExp, "Telephone number is not valid").required('Telephone number is required'),
                      password: Yup.string().password().required("Password is required"),
                      confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("Confirm password is required"),
                      pName: Yup.string().required("Primary contact name is required"),
                      state: Yup.string().required("State is required"),
                      address: Yup.string().required("Address is required"),
                      city: Yup.string().required("City is required"),
                      bphoneNumber: Yup.string().matches(phoneRegExp, "Mobile number is not valid").required('Mobile number is required'),
                      country: Yup.object().required("Please select a country"),
                      currency: Yup.object().required("Please select a currency"),
                      zipcode: Yup.string().required("Pin code / PO box number is required"),
                      profile_picture: Yup.mixed().required("Profile picture is required"),
                    })}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postObj = {
                        name: values.name,
                        authorized_person_name: values.pName,
                        email: values.email,
                        company_name: values.compnayName,
                        website: values.website,
                        telephone_number: values.tphoneNumber,
                        primary_currency: values.currency.value,
                        mobile: values.mobileNumber,
                        profile_picture: values.profile_picture,
                        password: values.password,
                        vat_number: values.vatNumber,
                        billing_address: {
                          name: values.name,
                          address: values.address,
                          phone: values.bphoneNumber,
                          country: values.country.value,
                          state: values.state,
                          city: values.city,
                          zip_code: values.zipcode.toString()
                        },
                        allow_cash_credit: values?.allow_cash_credit || "1",
                      };
                      await PostMerchant(postObj).then((res) => {
                        SuccessToast(res?.data?.message || 'Merchnat Created.');
                        setSubmitting(false);
                        navigate('/admin/merchants');
                      }).catch((err) => {
                        if (typeof err.response.data.message !== 'undefined') {
                          ErrorToast(err.response.data.message || 'Server Error!!');
                        } else {
                          ErrorToast(err?.message || 'Server Error!!');
                        }
                        setSubmitting(false);
                      })
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      values,
                      isSubmitting,
                      setFieldValue
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Name <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  className={`form-control ${errors.name && touched.name
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="name"
                                  value={values.name}
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Email <span className="text-danger">*</span></label>
                                <Field
                                  type="email"
                                  className={`form-control ${errors.email && touched.email
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="email"
                                  value={values.email}
                                  placeholder="Enter email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Telephone Number <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${errors.tphoneNumber && touched.tphoneNumber
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="tphoneNumber"
                                  value={values.tphoneNumber}
                                  placeholder="Enter telephone number"
                                />
                                <ErrorMessage
                                  name="tphoneNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Currency <span className="text-danger">*</span></label>
                                <Select
                                  options={currency}
                                  onChange={(newVal) => {
                                    setFieldValue("currency", newVal);
                                  }}
                                  name="currency"
                                  value={values.currency}
                                  placeholder="Select currency"
                                />
                                <ErrorMessage
                                  name="currency"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className='form-group dropzone-image-area'>
                                <label className='image-label'>Profile Picture <span className='text-danger'>*</span></label>
                                <DragAndDropFile
                                  randerKey={debounceKey}
                                  maxFileSize={2000000} //2mb
                                  label="Drag and drop a image or click"
                                  fileTypes={['image/*', '.pdf', '.docx', '.doc', '.pptx']}
                                  className={`form-control dropzoneBox ${errors.profile_picture && touched.profile_picture
                                    ? "input-error"
                                    : null
                                    }`}
                                  handleChange={async (file) => {
                                    setFieldValue("profile_picture", file[0]);
                                    let imageData = file[0];
                                    const imagePostData = {
                                      user_id: id,
                                      file_name: imageData.name,
                                      file_module: "PROFILE_PICTURE",
                                      mime_type: imageData.type,
                                      document_sides: "NONE",
                                    };
                                    await fileUploadToBucket({
                                      data: imagePostData,
                                      file: imageData,
                                    })
                                      .then(async (res) => {
                                        setFieldValue('profile_picture', res?.data?.data?.s3_key)
                                      })
                                      .catch((err) => {
                                        // if (
                                        //   typeof err.response.data.message !== "undefined"
                                        // ) {
                                        //   ErrorToast(
                                        //     err.response.data.message || "Server Error!!"
                                        //   );
                                        // } else {
                                        //   ErrorToast(err?.message || "Server Error!!");
                                        // }
                                      });
                                  }}
                                  acceptedFiles={fileTypes}
                                />
                                <ErrorMessage
                                  name="profile_picture"
                                  component="span"
                                  className="error"
                                />
                              </div>

                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Primary Contact Name <span className="text-danger">*</span></label>
                                
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${errors.pName && touched.pName
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="pName"
                                  value={values.pName}
                                  placeholder="Enter primary contact name"
                                />
                                <ErrorMessage
                                  name="pName"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="form-group">
                                <label>Password <span className="text-danger">*</span></label>
                                <div className="inputWrap">
                                <Field
                                  type={isShowPassword ? "text" : "password"}
                                  className={`form-control ${errors.password && touched.password
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="password"
                                  value={values.password}
                                  placeholder="Enter password"
                                />
                                <span
                                  onClick={() =>
                                    setIsShowPassword(!isShowPassword)
                                  }
                                  className={`fas ${isShowPassword ? "fa-eye-slash" : "fa-eye"
                                    } toggle-password-user`}
                                  />
                                  </div>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Confirm Password <span className="text-danger">*</span></label>
                                <div className="inputWrap">
                                <Field
                                  type={isShowConfirmPassword ? "text" : "password"}
                                  className={`form-control ${errors.password && touched.password
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="confirm_password"
                                  value={values.confirm_password}
                                  placeholder="Confirm password"
                                />
                                <span
                                  onClick={() =>
                                    setIsShowConfirmPassword(!isShowConfirmPassword)
                                  }
                                  className={`fas ${isShowConfirmPassword ? "fa-eye-slash" : "fa-eye"
                                    } toggle-password-user`}
                                  />
                                  </div>
                                <ErrorMessage
                                  name="confirm_password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group remove-inner-input">
                                <label>Mobile Number (Country Code) <span className="text-danger">*</span></label>
                                <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="AE"
                                  type="text"
                                  value={values.mobileNumber}
                                  onChange={(newVal) => {
                                    setFieldValue('mobileNumber', newVal);
                                  }}
                                  className={`form-control ${errors.mobileNumber && touched.mobileNumber
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="mobileNumber"
                                />
                                <ErrorMessage
                                  name="mobileNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Compnay Name <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  placeholder="Enter company name"
                                  onChange={handleChange}
                                  className={`form-control ${errors.compnayName && touched.compnayName
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="compnayName"
                                  value={values.compnayName}
                                />
                                <ErrorMessage
                                  name="compnayName"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Website</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Website Url"
                                  onChange={handleChange}
                                  className={`form-control ${errors.website && touched.website
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="website"
                                  value={values.website}
                                />
                                <ErrorMessage
                                  name="website"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>VAT Number <span className="text-danger">*</span></label>

                                <Field
                                placeholder="Enter VAT number"
                                  type="text"
                                  className={`form-control ${errors.vatNumber && touched.vatNumber
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="vatNumber"
                                  value={values.vatNumber}
                                />
                                <ErrorMessage
                                  name="vatNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <h4 className="card-title mt-4">Billing Address</h4>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>City <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  placeholder="Enter city"
                                  onChange={handleChange}
                                  className={`form-control ${errors.city && touched.city
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="city"
                                  value={values.city}
                                />
                                <ErrorMessage
                                  name="city"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Country <span className="text-danger">*</span></label>
                                <Select
                                  options={country}
                                  onChange={(newVal) => {
                                    setFieldValue('country', newVal)
                                  }}
                                  name="country"
                                  value={values.country}
                                  placeholder="Select country"
                                />
                                <ErrorMessage
                                  name="country"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Address <span className="text-danger">*</span></label>
                                <Field
                                  as="textarea"
                                  rows={3}
                                  cols={5}
                                  type="text"
                                  placeholder="Enter address"
                                  className={`form-control ${errors.address && touched.address
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="address"
                                  onChange={handleChange}
                                  value={values.address}
                                />
                                <ErrorMessage
                                  name="address"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>State <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  placeholder="Enter state"
                                  className={`form-control ${errors.state && touched.state
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="state"
                                  onChange={handleChange}
                                  value={values.state}
                                />
                                <ErrorMessage
                                  name="state"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>PO box number / Pin code <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  placeholder="Enter PO box number / pin code"
                                  className={`form-control ${errors.zipcode && touched.zipcode
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="zipcode"
                                  onChange={handleChange}
                                  value={values.zipcode}
                                />
                                <ErrorMessage
                                  name="zipcode"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group remove-inner-input">
                                <label>Mobile Number (Country Code) <span className="text-danger">*</span></label>
                                <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="AE"
                                  type="text"
                                  value={values.bphoneNumber}
                                  onChange={(newVal) => {
                                    setFieldValue('bphoneNumber', newVal);
                                  }}
                                  className={`form-control ${errors.bphoneNumber && touched.bphoneNumber
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="bphoneNumber"
                                />
                                <ErrorMessage
                                  name="bphoneNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                            <label className="image-label">
                              Allow Customer To Pay Amount Via Cash/Credit OR Cash Only
                            </label>
                              <div className="input-radio">
                                <label htmlFor="allow_cash_credit_both">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="allow_cash_credit"
                                  id="allow_cash_credit_both"
                                  value="1"
                                />{" "}
                                Allow Both
                                </label>
                                &nbsp;&nbsp;
                                <label htmlFor="allow_cash_credit_cash">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="allow_cash_credit"
                                  id="allow_cash_credit_cash"
                                  value="2"
                                />{" "}
                                Cash Only
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}

                            >
                              Add Merchant
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div class="content container-fluid absolute px-2">
              <footer className="text-center text-lg-start">
                <div className="text-center pb-4">
                  Powered by Alsaffron System.
                </div>
              </footer>
            </div>
        </div>
      </div>
    </>
  )
}

export default AddMerchant