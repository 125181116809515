import React from 'react'
import { Link } from 'react-router-dom'

const SideMenu = () => {
  return (
    <div>
      <div className="widget settings-menu">
        <ul>
          <li className="nav-item">
            <Link to="/admin/setting" className="nav-link ">
              <i className="fas fa-tools"></i> <span>Profile Settings</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/setting/change-password" className="nav-link">
              <i className="fas fa-unlock-alt"></i> <span>Change Password</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideMenu