import React, { useState, useEffect } from "react";
import { Pagination } from "../../../Utils";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import format from "date-fns/format";
import { StartCase } from "react-lodash";
import _ from "lodash";
import { saveAs } from "file-saver";
import {
  GetCustomerTransactionList,
  getCustomerTransactionReportWidget,
  GetGenerateCustomerReport,
} from "../../../Services/Api/reports";
import { ErrorToast } from "../../../Utils/SweetAlert";
import { FilterCustomerTransactionReport } from "../Common";
import { showCustName } from "../../../Utils/CommanFunctions";

const CustomerTransactionReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [widgetData, setWidgetData] = useState([]);
  const [customerTransaction, setCustomerTransaction] = useState([]);
  const [transactionListFilter, setTransactionListFilter] = useState({
    offset: page || 0,
    limit: limit || 10,
    filter_date: "",
    filter_status: "",
  });
  const [resetDate, setResetDate] = useState(0);
  const navigate = useNavigate();

  const handleReset = async ({ fDate, fStatus }) => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      filter_date: fDate || "",
      filter_status: fStatus || "",
    };
    setTransactionListFilter(postData);
  };

  const handleSubmitFilter = async ({ fDate, fStatus }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      filter_date: fDate || "",
      filter_status: fStatus || "",
    };
    setTransactionListFilter(postData);
  };
  const handleExportFilter = async ({ fDate, fStatus }) => {
    setResetDate(1);
    setPage(0);
    const postData = {
      filter_date: fDate || "",
      filter_status: fStatus || "",
    };
    await GetGenerateCustomerReport(postData)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
          const postData = {
            offset: 0,
            limit: limit || 10,
            filter_date: "",
            filter_status: "",
          };
          setTransactionListFilter(postData);
        } else {
          setTransactionListFilter({
            offset: page || 0,
            limit: limit || 10,
            filter_date: "",
            filter_status: "",
          });
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        setTransactionListFilter({
          offset: page || 0,
          limit: limit || 10,
          filter_date: "",
          filter_status: "",
        });
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const getWidgetData = async () => {
    await getCustomerTransactionReportWidget()
      .then((res) => {
        setWidgetData(res?.data?.data?.widget_counts);
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };

  const getCustomerTransaction = async (requestPayload) => {
    setIsLoading(true);
    await GetCustomerTransactionList(requestPayload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setIsLoading(false);
          setCustomerTransaction(res?.data?.data?.transactions);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerTransaction([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCustomerTransaction([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if(typeof err?.response?.data?.message !== "undefined"){
        //     ErrorToast(err?.response?.data?.message || 'Server Error!!');
        // }else{
        //     ErrorToast(err?.message || "Server Error!!")
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    setTransactionListFilter((prevState) => ({
      ...prevState,
      offset: pageNew,
    }));
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    setTransactionListFilter((prevState) => ({
      ...prevState,
      limit: event.target.value || 10,
      offset: 0,
    }));
    //  setTransactionListFilter({offset : 0 ,
    //   limit : event.target.value || 10,
    //   filter_date : "",
    //   filter_status : ""});
  };

  useEffect(() => {
    getWidgetData();
  }, []);
  useEffect(() => {
    getCustomerTransaction(transactionListFilter);
  }, [transactionListFilter]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Customer Transaction Report</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Customer Transaction Report</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Customer Transaction Report
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <FilterCustomerTransactionReport
            handleSubmitFilter={handleSubmitFilter}
            handleReset={handleReset}
            resetDate={resetDate}
            handleExportFilter={handleExportFilter}
          />
          <div className="row">
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon3.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">AED </span>
                        {widgetData?.pending_amount?.amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Pending Amount{" "}
                    <span>{widgetData?.pending_amount?.records}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon1.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">AED </span>
                        {widgetData?.credit_transactions?.amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Credit Transactions{" "}
                    <span>{widgetData?.credit_transactions?.records}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon2.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">AED </span>
                        {widgetData?.debit_transactions?.amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Debit Transactions{" "}
                    <span>{widgetData?.debit_transactions?.records}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Reference Number</th>
                          <th>Pax Details</th>
                          <th>Status</th>
                          <th>Type</th>
                          <th>Created On</th>
                          <th>Customer</th>
                          <th>Merchant</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerTransaction.map((transaction, index) => (
                              <tr key={index}>
                                <td>{transaction?.reference_number}</td>
                                <td>
                                  {transaction?.booking?.product === "flight" && (
                                    <>
                                      {transaction?.booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                            <span>
                                              ({traveller?.passenger_type})
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {transaction?.booking?.product === "hotel" && (
                                    <>
                                      {transaction?.booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {transaction?.booking?.product === "visa" && (
                                    <div>
                                      {transaction?.booking?.metadata?.visa?.first_name}{" "}
                                      {transaction?.booking?.metadata?.visa?.last_name}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      transaction?.transaction_type == "CREDIT"
                                        ? "badge bg-success-light"
                                        : "badge bg-danger-light"
                                    }`}
                                  >
                                    <StartCase
                                      string={_.toLower(
                                        _.replace(
                                          transaction?.transaction_type,
                                          "_",
                                          " "
                                        )
                                      )}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <StartCase
                                    string={_.toLower(
                                      _.replace(transaction?.motive, "_", " ")
                                    )}
                                  />
                                </td>
                                <td>
                                  {format(
                                    new Date(transaction?.transactionDate),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                                <td>
                                  <a
                                    className="invoice-link"
                                    onClick={() =>
                                      navigate("/admin/customer-statement", {
                                        state: { id: transaction?.user?._id, user_name: transaction?.user?.name },
                                      })
                                    }
                                  >
                                    {showCustName(transaction?.user?.name, transaction?.user?.company_name)}
                                  </a>
                                </td>
                                <td> {transaction?.booking?.merchant?.name || ''} </td>
                                <td>
                                  <span className="currency-type-text">
                                    {transaction?.transaction_currency}{" "}
                                  </span>
                                  {transaction?.transaction_amount}
                                </td>
                              </tr>
                            ))}
                            {customerTransaction.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={6}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerTransactionReport;
