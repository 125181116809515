import React from 'react'

const SearchBox = ({ search, setSearch }) =>  {

    return (
      <div className="row justify-content-end">
        <div className='col-lg-3 col-md-12 col-sm-12'>
          <div className="form-group">
            <input
              type="text"
              className={`form-control`}
              name="name"
              placeholder="Search"
              value={search}
              onChange={(e)=>setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
    )
}

export default SearchBox