import { Pages } from "@mui/icons-material";
import Api from "../../Utils/Axios";

export const AddUserService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post('/admin/users',data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetUserPaginate = async ({limit,page}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/admin/users?offset=${page}&limit=${limit}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const DeleteUserById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.delete(`/admin/users/${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }
  
  export const ActiveInactiveUser = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post("/admin/users/active_inactive",data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetUserByIdService = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/admin/users/${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }
  
  export const EditUserService = async (id,data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.put(`/admin/users/${id}`,data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const adminGetUserPermissionService = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get("/admin/users/permissions/retrive"));
      } catch (error) {
        reject(error);
      }
    })
  }