import Api from "../../Utils/Axios";

export const GetCustomerBookingPaginate = async ({limit, page, status = -1}) =>{
    return new Promise(async (resolve, reject) =>{
        try{
            resolve(await Api.get(`/admin/bookings/customer?offset=${page}&limit=${limit}&status=${status}`));
        }catch(error){
            reject(error);
        }
    })
}

export const GetWidgetCountBookingService = async () =>{
    return new Promise(async (resolve,reject) => {
        try{
            resolve(await Api.get('/admin/bookings/customer_widget_count'));
        }catch(error){
            reject(error);
        }
    })
}

export const getDepositOptionService = async () =>{
    return new Promise(async (resolve, reject) =>{
        try{
            resolve(await Api.get("/master/deposit_options"));
        }catch(error){
            reject(error);
        }
    })
}
export const depositOptionService = async (data) =>{
    return new Promise (async (resolve,reject) => {
        try{
            resolve(await Api.post("/master/deposit_options/update",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetBookingDetails = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/admin/bookings/details/${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const getCustomerBookingGenerateReport = async (data) =>{
    return new Promise (async (resolve,reject) => {
        try{
            resolve(await Api.post("/admin/bookings/generate_report",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetBookingAnalytics = async (period) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/master/dashboard/booking_analytics?period=${period}`));
      } catch (error) {
        reject(error);
      }
    })
  }

export const refundBookingAmountAPI = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post(`/admin/bookings/refund_booking_amount`, data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetCpayReceiptservice = async (id) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.get(`/customer/bookings/download_receipt/${id}`));
        }catch(error){
            reject(error);
        }
    })
}