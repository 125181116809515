import React, { useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoginService } from "../../Services/Api/auth";
import { SuccessToast, ErrorToast } from "../../Utils/SweetAlert";
import { Helmet } from 'react-helmet-async';

const Login = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>CPay | Admin Login</title>
      </Helmet>
      <div>
        <div className="main-wrapper login-body">
          <div className="login-wrapper">
            <div className="container">
              <img
                className="img-fluid logo-dark mb-2"
                src="/inner-assets/img/logo.png"
                alt="Logo"
              />
              <div className="loginbox">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <h1>Login</h1>
                    <p className="account-subtitle">Access to our dashboard</p>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().email().required("Email is required"),
                        password: Yup.string().required("Password is required"),
                      })}
                      validateOnChange
                      onSubmit={async (values, { setSubmitting }) => {
                        await LoginService(values).then((res) => {
                          localStorage.setItem('accessToken', res?.data?.data?.token);
                          localStorage.setItem('userData', JSON.stringify(res?.data?.data?.user));
                          SuccessToast(res?.data?.message || 'Login successful.');
                          setSubmitting(false);
                          navigate('/admin/dashboard');
                        }).catch((err) => {
                          if (typeof err.response.data.message !== 'undefined') {
                            ErrorToast(err.response.data.message || 'Server Error!!');
                          } else {
                            ErrorToast(err?.message || 'Server Error!!');
                          }
                          setSubmitting(false);
                        })
                      }}
                    >
                      {({
                        errors,
                        touched,
                        isValid,
                        handleChange,
                        handleSubmit,
                        isSubmitting
                      }) => {
                        return (
                          <Form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label className="form-control-label">
                                Email <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="email"
                                onChange={handleChange}
                                className={`form-control ${errors.email && touched.email
                                  ? "input-error"
                                  : null
                                  }`}
                                name="email"
                                placeholder="Enter email"
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">Password <span className="text-danger">*</span></label>
                              <div className="pass-group">
                                <Field
                                  type={isShowPassword ? 'text' : 'password'}
                                  onChange={handleChange}
                                  className={`form-control ${errors.password && touched.password
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="password"
                                  placeholder="Enter password"
                                />
                                <span onClick={() => setIsShowPassword(!isShowPassword)} className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                              </div>
                              <ErrorMessage
                                name="password"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-6">
                                  <div className="custom-control custom-checkbox">
                                    {/* <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="cb1"
                                    />&nbsp;&nbsp; */}

                                    {/* <label className="custom-control-label" htmlFor="cb1">
                                      Remember me
                                    </label> */}

                                  <label className="custom_check" htmlFor="cb1">
                                      <input type="checkbox" name="invoice" id="cb1"/>Remember me
                                      <span className="checkmark" />
                                    </label>
                                    
                                  </div>
                                </div>
                                <div className="col-6 text-end">
                                  <NavLink to="/forgot-password">Forgot Password ?</NavLink>
                                </div>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className={`btn btn-lg btn-block btn-primary w-100 ${!isValid ? "disabled-btn" : ""}`}
                              disabled={isSubmitting}
                            >
                              Login&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                            <div className="login-or"></div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
