import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "../../../Utils";
// import {  ErrorToast } from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
import { GetInvestorStatementPaginate } from "../../../Services/Api/investor";
import format from "date-fns/format";

const InvestorFundStatement = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [investorStatementList, setInvestorStatementList] = useState([]);
  const stateData = useLocation();
  const { id, user_name } = stateData?.state || {};

  const getInvestorStatement = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetInvestorStatementPaginate({ limit: limitO, page: pageO, id: id })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setInvestorStatementList(res?.data?.data?.statement);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setInvestorStatementList([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setInvestorStatementList([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getInvestorStatement(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Investor Statement</title>
      </Helmet>
      <div>
        <div className="main-wrapper">
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title"> {(user_name) ? `Investor Statement For `+user_name : `Investor Statement`} </h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Investor Statement
                      </li>
                    </ul>
                  </div>
                  <div className="col-auto">
                    <Link to="/admin/investor" className="btn btn-primary me-1">
                      <i className="fas fa-angle-double-left" /> Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-center table-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Debit</th>
                              <th>Credit</th>
                              <th>Opening Balance</th>
                              <th>Closing Balance</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading && (
                              <>
                                {investorStatementList.map(
                                  (investor, index) => (
                                    <tr key={index}>
                                      <td>{limit * page + (index + 1)}</td>
                                      <td>
                                        {format(
                                          new Date(investor?.createdAt),
                                          "dd MMMM, yyyy, HH:mm a"
                                        )}
                                      </td>
                                      <td>
                                        {investor?.transaction_type ==
                                        "DEBIT" ? (
                                          <>
                                            <span className="currency-type-text">
                                              AED
                                            </span>
                                            &nbsp;
                                            {investor?.amount}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        {investor?.transaction_type ==
                                        "CREDIT" ? (
                                          <>
                                            <span className="currency-type-text">
                                              AED
                                            </span>
                                            &nbsp;
                                            {investor?.amount}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        <span className="currency-type-text">
                                          AED{" "}
                                        </span>
                                        {investor?.opening_balance || 0}
                                      </td>
                                      <td>
                                        <span className="currency-type-text">
                                          AED{" "}
                                        </span>
                                        {investor?.closing_balance || 0}
                                      </td>
                                      <td>{investor?.notes || "-"}</td>
                                    </tr>
                                  )
                                )}
                                {investorStatementList.length === 0 && (
                                  <tr>
                                    <td colSpan={7}>No Records Found.</td>
                                  </tr>
                                )}
                              </>
                            )}
                            <Pagination
                              totalItem={totalRecords}
                              itemsPerPage={limit}
                              page={page}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleLimitChange}
                              colSpan={7}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorFundStatement;
