import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { Helmet } from "react-helmet-async";
import {
  ErrorToast,
  SuccessToast,
  Confirmation,
} from "../../../Utils/SweetAlert";
import { getAllAgreement } from "../../../Services/Api/master";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { DeleteFile } from "../../../Services/Api/master";
import format from "date-fns/format";
import { useDebounce } from "use-debounce";

const CustomerAgreement = () => {
  const [frontImageKey, setfrontImageKey] = useState(0);
  const [debounceKey] = useDebounce(frontImageKey, 1000);
  const stateData = useLocation();
  const { id, companyName } = stateData?.state || {};
  const [agreementList, setAgreementList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];

  const initData = {
    agreementDoc: "",
    document_name: "",
  };
  const agreementSchema = Yup.object().shape({
    agreementDoc: Yup.mixed().required("Agreement is required"),
    document_name: Yup.string().required("Document name is required"),
  });
  const getUploadedAgreementList = async () => {
    setIsLoader(true);
    await getAllAgreement(id)
      .then(async (res) => {
        setAgreementList(res.data.data.agreement);
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        setAgreementList([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const deleteDocument = (deleteFileId) => {
    Confirmation("Want to delete document?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteFile(deleteFileId)
          .then((res) => {
            SuccessToast(res?.data?.message || "Customer Deleted.");
            getUploadedAgreementList();
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  useEffect(() => {
    getUploadedAgreementList();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Customer Agreement</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title"> {(companyName) ? `Customer Documents For `+companyName : `Customer Documents`} </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Customer Documents</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Customer agreement</h5>
                  <p>Upload Customer agreement...</p>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={initData}
                    validationSchema={agreementSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const frontPostData = {
                        document_name: values.document_name,
                        file_name: values.agreementDoc.name,
                        file_module: "USER_AGREEMENT",
                        mime_type: values.agreementDoc.type,
                        document_sides: "NONE",
                        user_id: id,
                      };
                      await fileUploadToBucket({
                        data: frontPostData,
                        file: values.agreementDoc,
                      })
                        .then(async (res) => {
                          setfrontImageKey(Math.random());
                          SuccessToast(
                            res?.data?.message ||
                              "Your ducument uploaded successfully."
                          );
                          getUploadedAgreementList();
                          setSubmitting(false);
                          resetForm();
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          <div className="form-row row">
                            <div className="col-md-12 mb-3">
                              <label>
                                Agreement Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.document_name && touched.document_name
                                    ? "input-error"
                                    : null
                                }`}
                                id="name"
                                name="document_name"
                                value={values.document_name}
                                placeholder="Enter agreement name"
                              />
                              <ErrorMessage
                                name="document_name"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="col-md-6 mb-3"></div>
                          </div>
                          <div className="form-row row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group dropzone-input-area">
                                <label>
                                  Upload Customer Agreement Pdf{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DragAndDropFile
                                  randerKey={debounceKey}
                                  maxFileSize={5000000} //5mb
                                  label="Drag and drop a pdf or click"
                                  className={`form-control ${
                                    errors.agreementDoc && touched.agreementDoc
                                      ? "input-error"
                                      : null
                                  }`}
                                  handleChange={(file) => {
                                    setFieldValue("agreementDoc", file[0]);
                                  }}
                                  acceptedFiles={fileTypes}
                                />
                                <ErrorMessage
                                  name="agreementDoc"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3"></div>
                          </div>
                          <div className="form-row row">
                            <div className="col-md-6 mb-3"></div>
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Submit&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Customer agreement list</h5>
                  <p>Please review Customer agreement...</p>
                </div>
                <div className="row">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped custom-table mb-0">
                        <thead>
                          <tr>
                            <th>Number</th>
                            <th>Name</th>
                            <th>Uploaded Date</th>
                            <th className="text-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoader &&
                            agreementList.map((agreement, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>

                                <td>{agreement.document_name}</td>
                                <td>
                                  {format(
                                    new Date(agreement.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                                <td className="text-end">
                                  <Tooltip
                                    title="view"
                                    className="btn btn-sm btn-white text-success me-2"
                                    target="_blank"
                                    href={agreement.media_url}
                                  >
                                    <IconButton>
                                      <RemoveRedEyeIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() => deleteDocument(agreement.id)}
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content container-fluid absolute px-2">
              <footer className="text-center text-lg-start">
                <div className="text-center pb-4">
                  Powered by Alsaffron System.
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerAgreement;
