import { useEffect } from "react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { adminGetUserPermissionService } from "../Services/Api/user";
import AdminRoutes from "./AdminRoutes";
import AdminRoutesPermission from "./AdminRoutesPermission";
import AuthRoutes from "./AuthRoutes";

export default function Routings() {

  const [roleClientAdmin,setRoleAdmin] = useState("admin")
  const [loading, setLoading] = useState(true);

  const getAdminPermission =async () => {
    await  adminGetUserPermissionService().then((res) => {
      const permissionArr = res?.data.data.permissions.role_meta
      const resourceArr = permissionArr.map((value) => {
        return value.role_resource.resource_key
      })
      localStorage.setItem("adminPermission",JSON.stringify(resourceArr)
      );
      setLoading(false);
    }).catch((err) =>{
      // if (typeof err.response.data.message !== "undefined") {
      //   ErrorToast(err.response.data.message || "Server Error!!");
      // } else {
      //   ErrorToast(err?.message || "Server Error!!");
      // }
    })
  }

  useEffect(() => {

    const admin = JSON.parse(localStorage.getItem('userData'));
    setRoleAdmin(admin?.role);
    if (admin?.role === "sub_admin") {
      getAdminPermission();
    }
    if ((admin?.role === "admin" || typeof admin?.role === "undefined")) {
      setLoading(false);
    }

  }, []);

  return (
    <>
    <Routes>
      {!loading &&
       <>
        {roleClientAdmin === 'admin'? 
            <Route path="/admin/*" element={<AdminRoutes />} /> :
            <Route path="/admin/*" element={<AdminRoutesPermission />} />
        }
        <Route path="/*" element={<AuthRoutes />} />
        </>
      }
    </Routes>
    </>
  );
}
