import { Route, Routes } from "react-router-dom";
import {
  EditMerchant,
  Merchants,
  MerchantTransaction,
  MerchantAgreement,
  MerchantLegalDocument,
  AddWalletAmount,
  MerchantCreditRequest,
  MerchantListAllCreditRequest,
  AddMerchant,
  MerchantWalletRequest,
  EditWalletAmount,
  BankAccounts,
  MerchantStatement,
  ExpiredDocumentUser,
  MerchantRegistrationRequest,
} from "../Components/Admin/Merchant";
import {
  Customer,
  CustomerBookingList,
  EditCustomer,
  AddCustomer,
  BookingPaid,
  BookingOverdue,
  BookingFailed,
  ViewBooking,
  BookingCancelled,
  WalletTransaction,
  WalletRequest,
  WalletDetails,
  CardWalletRequest,
  ServiceCharge,
  AddServiceCharge,
  EditServiceCharge,
  CustomerCreditRequestDetail,
  CustomerStatement,
  CustomerCreditRequest,
  CustomerLegalDocument,
  CustomerAgreement,
  ExpiredDocumentUsers,
  CustomerCreditLineReport,
} from "../Components/Admin/Customer";
import { AdminLayout } from "../Components/Layout";
import PrivateRoute from "./PrivateRoute";
import { useEffect } from "react";
import { Notification } from "../Components/Admin/Notification";
import {
  ChangePassword,
  Settings,
  CRPenaltyRequest,
} from "../Components/Admin/settings";
import {
  BankingOptions,
  AddBankingOption,
  EditBankingOption,
} from "../Components/Admin/Banking";
import {
  MerchantApiKeys,
  ApiDocumentation,
} from "../Components/Admin/MerchantApi";
import {
  AddRoleManagement,
  EditRolePermission,
  Role,
} from "../Components/Admin/RoleManagement";
import { AddBank, Banks, EditBank } from "../Components/Admin/Banks";
import { DepositOption } from "../Components/Admin/DepositOption";
import ComponentPrint from "../Components/Admin/Common/ComponentPrint";
import {
  AddInvestor,
  Investor,
  InvestorFundStatement,
  ViewFund,
} from "../Components/Admin/Investor";
import { AddUser, EditUser, User } from "../Components/Admin/UserManagement";
import { Dashboard } from "../Components/Admin/Dashboard";
import { Profile } from "../Components/Admin/Profile";
import { ListNews, AddNews, EditNews } from "../Components/Admin/NewsBulletins";
import {
  CustomerAmountDueReport,
  CustomerTransactionReport,
  MerchantTransactionReport,
  ViewEarningReport,
} from "../Components/Admin/Reports";
import { AddFaq, EditFaq, FaqList } from "../Components/Admin/Faq";
import { ListContactUs, ViewContactUs } from "../Components/Admin/ContactUs";
import { ListFeedback } from "../Components/Admin/Feedback";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("adminPermission");
    window.location.assign("/");
  }, []);
};
const AdminRoutes = () => {
  return (
    <>
      <AdminLayout />
      <Routes>
        <Route
          exact
          path="/"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          exact
          path="/users"
          element={<PrivateRoute Component={User} />}
        />
        <Route
          exact
          path="/add-user"
          element={<PrivateRoute Component={AddUser} />}
        />
        <Route
          exact
          path="/edit-user"
          element={<PrivateRoute Component={EditUser} />}
        />
        <Route exact path="/role" element={<PrivateRoute Component={Role} />} />
        <Route
          exact
          path="/add-role-management"
          element={<PrivateRoute Component={AddRoleManagement} />}
        />
        <Route
          exact
          path="/edit-role-permission"
          element={<PrivateRoute Component={EditRolePermission} />}
        />
        <Route
          exact
          path="/customer"
          element={<PrivateRoute Component={Customer} />}
        />
        <Route
          exact
          path="/add-customer"
          element={<PrivateRoute Component={AddCustomer} />}
        />
        <Route
          exact
          path="/edit-customer"
          element={<PrivateRoute Component={EditCustomer} />}
        />
        <Route
          exact
          path="/customer-booking-list"
          element={<PrivateRoute Component={CustomerBookingList} />}
        />
        <Route
          exact
          path="/customer-credit-line"
          element={<PrivateRoute Component={CustomerCreditLineReport} />}
        />
        <Route
          exact
          path="/booking-paid"
          element={<PrivateRoute Component={BookingPaid} />}
        />
        {/* <Route exact path="/booking-overdue" element={<PrivateRoute Component={BookingOverdue} />} /> */}
        <Route
          exact
          path="/booking-failed"
          element={<PrivateRoute Component={BookingFailed} />}
        />
        <Route
          exact
          path="/booking-cancelled"
          element={<PrivateRoute Component={BookingCancelled} />}
        />
        {/* <Route exact path="/view-booking" element={<PrivateRoute Component={ViewBooking} />} /> */}
        <Route
          exact
          path="/view-booking"
          element={<PrivateRoute Component={ComponentPrint} />}
        />
        {/* <Route exact path="/wallet-transaction" element={<PrivateRoute Component={WalletTransaction} />} /> */}
        <Route
          exact
          path="/wallet-request"
          element={<PrivateRoute Component={WalletRequest} />}
        />
        <Route
          exact
          path="/wallet-details/:id"
          element={<PrivateRoute Component={WalletDetails} />}
        />
        <Route
          exact
          path="/merchant-wallet-request"
          element={<PrivateRoute Component={MerchantWalletRequest} />}
        />
        <Route
          exact
          path="/merchant-agreement"
          element={<PrivateRoute Component={MerchantAgreement} />}
        />
        <Route
          exact
          path="/customer-agreement"
          element={<PrivateRoute Component={CustomerAgreement} />}
        />
        <Route
          exact
          path="/merchants"
          element={<PrivateRoute Component={Merchants} />}
        />
        <Route
          exact
          path="/add-wallet-amount"
          element={<PrivateRoute Component={AddWalletAmount} />}
        />
        <Route
          exact
          path="/edit-wallet-amount"
          element={<PrivateRoute Component={EditWalletAmount} />}
        />
        {/* <Route exact path="/merchant-transactions" element={<PrivateRoute Component={MerchantTransaction} />} /> */}
        <Route
          exact
          path="/banking-details"
          element={<PrivateRoute Component={BankAccounts} />}
        />
        <Route
          exact
          path="/merchant-api-key"
          element={<PrivateRoute Component={MerchantApiKeys} />}
        />
        <Route
          exact
          path="/merchant-api-documentation"
          element={<PrivateRoute Component={ApiDocumentation} />}
        />
        <Route
          exact
          path="/merchant-credit-request-add"
          element={<PrivateRoute Component={MerchantCreditRequest} />}
        />
        <Route
          exact
          path="/merchant-credit-request"
          element={<PrivateRoute Component={MerchantListAllCreditRequest} />}
        />
        <Route
          exact
          path="/edit-merchant"
          element={<PrivateRoute Component={EditMerchant} />}
        />
        <Route
          exact
          path="/profile"
          element={<PrivateRoute Component={Profile} />}
        />
        <Route
          exact
          path="/add-merchant"
          element={<PrivateRoute Component={AddMerchant} />}
        />
        <Route
          exact
          path="/merchant-legaldocument"
          element={<PrivateRoute Component={MerchantLegalDocument} />}
        />
        <Route
          exact
          path="/customer-legaldocument"
          element={<PrivateRoute Component={CustomerLegalDocument} />}
        />
        <Route
          exact
          path="/card-wallet-request"
          element={<PrivateRoute Component={CardWalletRequest} />}
        />
        <Route
          exact
          path="/customer-credit-request"
          element={<PrivateRoute Component={CustomerCreditRequest} />}
        />
        <Route
          exact
          path="/customer-credit-request-details"
          element={<PrivateRoute Component={CustomerCreditRequestDetail} />}
        />
        <Route
          exact
          path="/notifications"
          element={<PrivateRoute Component={Notification} />}
        />
        <Route
          exact
          path="/setting"
          element={<PrivateRoute Component={Settings} />}
        />
        <Route
          exact
          path="/setting/change-password"
          element={<PrivateRoute Component={ChangePassword} />}
        />
        <Route
          exact
          path="/credit-request-penalty"
          element={<PrivateRoute Component={CRPenaltyRequest} />}
        />
        <Route
          exact
          path="/deposit-option"
          element={<PrivateRoute Component={DepositOption} />}
        />
        <Route
          exact
          path="/banking-options"
          element={<PrivateRoute Component={BankingOptions} />}
        />
        <Route
          exact
          path="/add-banking-options"
          element={<PrivateRoute Component={AddBankingOption} />}
        />
        <Route
          exact
          path="/edit-banking-options"
          element={<PrivateRoute Component={EditBankingOption} />}
        />
        <Route
          exact
          path="/customer-statement"
          element={<PrivateRoute Component={CustomerStatement} />}
        />
        <Route
          exact
          path="/merchant-statement"
          element={<PrivateRoute Component={MerchantStatement} />}
        />
        <Route
          exact
          path="/service-charge"
          element={<PrivateRoute Component={ServiceCharge} />}
        />
        <Route
          exact
          path="/add-service-charge"
          element={<PrivateRoute Component={AddServiceCharge} />}
        />
        <Route
          exact
          path="/edit-service-charge"
          element={<PrivateRoute Component={EditServiceCharge} />}
        />
        <Route
          exact
          path="/banks"
          element={<PrivateRoute Component={Banks} />}
        />
        <Route
          exact
          path="/add-banks"
          element={<PrivateRoute Component={AddBank} />}
        />
        <Route
          exact
          path="/edit-banks"
          element={<PrivateRoute Component={EditBank} />}
        />
        <Route
          exact
          path="/investor"
          element={<PrivateRoute Component={Investor} />}
        />
        <Route
          exact
          path="/investor-fund-statement"
          element={<PrivateRoute Component={InvestorFundStatement} />}
        />
        <Route
          exact
          path="/add-investor"
          element={<PrivateRoute Component={AddInvestor} />}
        />
        <Route
          exact
          path="/view-fund"
          element={<PrivateRoute Component={ViewFund} />}
        />
        <Route
          exact
          path="/customer-expired-document"
          element={<PrivateRoute Component={ExpiredDocumentUsers} />}
        />
        <Route
          exact
          path="/merchant-expired-document"
          element={<PrivateRoute Component={ExpiredDocumentUser} />}
        />
        <Route
          exact
          path="/merchant-registration-request"
          element={<PrivateRoute Component={MerchantRegistrationRequest} />}
        />
        <Route
          exact
          path="/customer-transaction-report"
          element={<PrivateRoute Component={CustomerTransactionReport} />}
        />
        <Route
          exact
          path="/customer-amount-due-report"
          element={<PrivateRoute Component={CustomerAmountDueReport} />}
        />
        <Route
          exact
          path="/merchant-transaction-report"
          element={<PrivateRoute Component={MerchantTransactionReport} />}
        />
        <Route
          exact
          path="/view-earning-report"
          element={<PrivateRoute Component={ViewEarningReport} />}
        />

        {/* Routes for news bulletins */}
        <Route
          exact
          path="/news-bulletins"
          element={<PrivateRoute Component={ListNews} />}
        />
        <Route
          exact
          path="/add-news"
          element={<PrivateRoute Component={AddNews} />}
        />
        <Route
          exact
          path="/edit-news"
          element={<PrivateRoute Component={EditNews} />}
        />

        {/* Routes for faq */}
        <Route
          exact
          path="/faq-list"
          element={<PrivateRoute Component={FaqList} />}
        />
        <Route
          exact
          path="/add-faq"
          element={<PrivateRoute Component={AddFaq} />}
        />
        <Route
          exact
          path="/edit-faq"
          element={<PrivateRoute Component={EditFaq} />}
        />

        {/* Routes for contact us */}
        <Route exact path="/contact-us" element={<PrivateRoute Component={ListContactUs} />} />
        <Route exact path="/contact-us/view" element={<PrivateRoute Component={ViewContactUs} />} />

        {/* Routes for feedback */}
        <Route exact path="/feedback" element={<PrivateRoute Component={ListFeedback} />} />

        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
};
export default AdminRoutes;
