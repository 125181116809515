import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import { Pagination } from "../../../Utils";
// import { ErrorToast } from "../../../Utils/SweetAlert";
// import { GetMerchantPaginate } from "../../../Services/Api/merchant";
import { AdminGetWalletBalanceService } from "../../../Services/Api/auth";
import {
  GetCustomerBookingPaginate,
  GetBookingAnalytics,
} from "../../../Services/Api/booking";
import format from "date-fns/format";
import "chartjs-adapter-date-fns";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { onImageError, showCustName } from "../../../Utils/CommanFunctions";

const Dashboard = () => {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(15);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customerBooking, setCustomerBooking] = useState([]);
  const [walletData, setWalletData] = useState([]);

  ChartJS.register(
    TimeScale,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Booking Analytics",
      },
    },
    scales: {
      x: {
        // type: "time",
        // time: {
        //   unit: "week",
        // },
      },
      y: {
        title: {
          display: true,
          text: "AED",
        },
      },
    },
  });
  const [chartData, setChartData] = useState({});
  const [analyticsPeriod, setAnalyticsPeriod] = useState("week");

  const getcustomerBooking = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetCustomerBookingPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerBooking(res?.data?.data?.bookings);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          // ErrorToast("No Results.");
          setCustomerBooking([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setCustomerBooking([]);
        setTotalRecords(0);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const getWalletBalanceData = async () => {
    AdminGetWalletBalanceService()
      .then((res) => {
        setWalletData(res?.data?.data?.widget_counts || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  // const handlePageChange = async (e, pageNew) => {
  //   setPage(pageNew);
  //   await getcustomerBookingPaid(limit, pageNew);
  // };
  // const handleLimitChange = async (event) => {
  //   setLimit(parseInt(event.target.value, 10));
  //   setPage(0);
  //   await getcustomerBookingPaid(parseInt(event.target.value, 10), 0);
  // };

  const getBookingAnalytics = async () => {
    await GetBookingAnalytics(analyticsPeriod)
      .then((res) => {
        setOptions((prevOptions) => {
          return {
            ...prevOptions,
            scales: {
              ...prevOptions.scales,
              x: {
                ...prevOptions.scales.x,
                time: {
                  ...prevOptions.scales.x.time,
                  // unit: analyticsPeriod,
                },
              },
            },
          };
        });
        setChartData({
          datasets: [
            {
              label: "Paid",
              data: res?.data?.data?.successBooking,
              backgroundColor: "rgba(0, 128, 0, 0.8)",
            },
            {
              label: "Failed",
              data: res?.data?.data?.failedBooking,
              backgroundColor: "rgba(255, 0, 0, 0.8)",
            },
            {
              label: "Cancelled",
              data: res?.data?.data?.cancelledBooking,
              backgroundColor: "rgba(253, 166, 0, 0.8)",
            },
          ],
        });
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  useEffect(() => {
    getBookingAnalytics();
  }, [analyticsPeriod]);

  useEffect(() => {
    getcustomerBooking(limit, page);
    getWalletBalanceData();
  }, []);

  const loginUser = JSON.parse(localStorage.getItem("userData"));

  return (
    <>
      <Helmet>
        <title>CPay | Admin Dashboard</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {loginUser.role !== 'sub_admin' && (
            <>
              <div className="row">
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-2">
                          <i className="fas fa-users" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/customer">Customers</Link>
                          </div>
                          <div className="dash-counts">
                            <td>{walletData?.customers}</td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-4">
                          <i className="fas fa-file-alt" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/merchants">Merchants</Link>
                          </div>
                          <div className="dash-counts">
                            <td>{walletData?.merchants}</td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-3">
                          <i className="fas fa-file-alt" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/customer-booking-list">Bookings</Link>
                          </div>
                          <div className="dash-counts">
                            <td>{walletData?.bookings}</td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-1">
                          <i className="fas fa-money-bill" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/customer-credit-request">
                              Amount Due
                            </Link>
                          </div>
                          <div className="dash-counts">
                            <td>
                              <span className="currency-type-text">AED</span>{" "}
                              {walletData?.amount_due}
                            </td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-2-balance">
                          <i className="fas fa-money-bill" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/merchants">
                              Total Balance with Merchant
                            </Link>
                          </div>
                          <div className="dash-counts">
                            <td>{walletData?.balance_with_merchant}</td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-2">
                          <i className="fas fa-credit-card" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/view-fund">
                              Total Investment for Credit
                            </Link>
                          </div>
                          <div className="dash-counts">
                            <td>
                              <span className="currency-type-text">AED</span>{" "}
                              {walletData?.total_investment || 0}
                            </td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-4">
                          <i className="fas fa-file-alt" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/customer-credit-request">
                              Total Credit Given
                            </Link>
                          </div>
                          <div className="dash-counts">
                            <td>
                              <span className="currency-type-text">AED </span>
                              {walletData?.total_credit_given || 0}
                            </td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-3">
                          <i className="fas fa-money-bill" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/customer-credit-request">
                              Remaining Investment for Credit
                            </Link>
                          </div>
                          <div className="dash-counts">
                            <td>
                              <span className="currency-type-text">AED </span>
                              {walletData?.remaining_investment || 0}
                            </td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-1">
                          <i className="fas fa-user" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/merchant-expired-document">
                              Expired Document Merchant
                            </Link>
                          </div>
                          <div className="dash-counts">
                            <td>{walletData?.expired_document_user || 0}</td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-title">Booking Analytics</h5>
                        <div className="dropdown">
                          <select
                            name="period"
                            onChange={(e) => setAnalyticsPeriod(e.target.value)}
                          >
                            <option value="week">Weekly</option>
                            <option value="month">Monthly</option>
                            <option value="year">Yearly</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      {Object.keys(chartData).length === 0 ? (
                        <span>Loading...</span>
                      ) : (
                        <Bar options={options} data={chartData} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title">Recent Bookings</h5>
                        </div>
                        <div className="col-auto">
                          <Link
                            to="/admin/customer-booking-list"
                            className="btn-right btn btn-sm btn-outline-primary"
                          >
                            View All
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card card-table">
                      <div className="card-body">
                        {/* <div className="mb-3">
                        <div className="progress progress-md rounded-pill mb-3">
                          <div className="progress-bar bg-success" role="progressbar" style={{ width: '47%' }} aria-valuenow={47} aria-valuemin={0} aria-valuemax={100} />
                          <div className="progress-bar bg-warning" role="progressbar" style={{ width: '28%' }} aria-valuenow={28} aria-valuemin={0} aria-valuemax={100} />
                          <div className="progress-bar bg-danger" role="progressbar" style={{ width: '15%' }} aria-valuenow={15} aria-valuemin={0} aria-valuemax={100} />
                          <div className="progress-bar bg-info" role="progressbar" style={{ width: '10%' }} aria-valuenow={10} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                        <div className="row">
                          <div className="col-auto">
                            <i className="fas fa-circle text-success me-1" /> Paid
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-circle text-warning me-1" /> Unpaid
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-circle text-danger me-1" /> Overdue
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-circle text-info me-1" /> Cancelled
                          </div>
                        </div>
                      </div> */}
                        <div className="table-responsive">
                          <table className="table table-stripped table-hover">
                            <thead className="thead-light">
                              <tr>
                                <th>Customer</th>
                                <th>Merchant</th>
                                <th>Pax Details</th>
                                <th>Amount</th>
                                <th>Created On</th>
                                <th>Status</th>
                                <th className="text-right">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!isLoading && (
                                <>
                                  {customerBooking.map((booking, index) => (
                                    <tr key={index}>
                                      <td>
                                        <h2 className="table-avatar">
                                          <img
                                            className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                            src={booking?.customer?.profile_picture || ""}
                                            alt="User"
                                            onError={onImageError}
                                          />
                                          {/* {booking?.customer?.name} */}
                                          {
                                            (booking?.customer?.name && booking?.customer?.company_name)
                                            ?
                                              showCustName(booking?.customer?.name, booking?.customer?.company_name)
                                            :
                                              (booking?.customer?.name)
                                              ?
                                                booking?.customer?.name
                                              :
                                                ""
                                          }
                                        </h2>
                                      </td>
                                      <td>
                                        <h2 className="table-avatar">
                                        <img
                                            className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                            src={booking?.merchant?.profile_picture || ""}
                                            alt="Merchant"
                                            onError={onImageError}
                                          />
                                          {booking?.merchant?.name}
                                        </h2>
                                      </td>
                                      <td>
                                        {booking?.product === "flight" && (
                                          <>
                                            {booking?.metadata?.travellers.map(
                                              (traveller, index) => (
                                                <div key={index}>
                                                  {index + 1}. {traveller?.title}{" "}
                                                  {traveller?.first_name}{" "}
                                                  {traveller?.last_name}{" "}
                                                  <span>
                                                    ({traveller?.passenger_type})
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                        {booking?.product === "hotel" && (
                                          <>
                                            {booking?.metadata?.travellers.map(
                                              (traveller, index) => (
                                                <div key={index}>
                                                  {index + 1}. {traveller?.title}{" "}
                                                  {traveller?.first_name}{" "}
                                                  {traveller?.last_name}{" "}
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                        {booking?.product === "visa" && (
                                          <div>
                                            {booking?.metadata?.visa?.first_name}{" "}
                                            {booking?.metadata?.visa?.last_name}
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <span className="currency-type-text">
                                          AED{" "}
                                        </span>
                                        {/* {booking?.customer_payment_amount} */}
                                        {booking?.amount}
                                      </td>
                                      <td>
                                        <span>
                                          {" "}
                                          {format(
                                            new Date(booking?.createdAt),
                                            "dd MMMM, yyyy hh:mm:ss a"
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        {booking?.status === 0 && (
                                          <span className="badge bg-warning-light">
                                            Pending
                                          </span>
                                        )}
                                        {booking?.status === 1 && (
                                          <span className="badge bg-success-light">
                                            Success
                                          </span>
                                        )}
                                        {booking?.status === 2 && (
                                          <span className="badge bg-danger-light">
                                            Failed
                                          </span>
                                        )}
                                        {booking?.status === 3 && (
                                          <span className="badge bg-danger-light">
                                            Cancelled
                                          </span>
                                        )}
                                      </td>
                                      <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                          <Link
                                            to="#"
                                            className="action-icon dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="fas fa-ellipsis-v" />
                                          </Link>
                                          <div className="dropdown-menu dropdown-menu-right">
                                            {/* <Link className="dropdown-item" to="#"><i className="far fa-edit me-2" />Edit</Link> */}
                                            <Link
                                              className="dropdown-item"
                                              to="/admin/view-booking"
                                              state={{
                                                id: booking?.booking_reference,
                                              }}
                                            >
                                              <i className="far fa-eye me-2" />
                                              View
                                            </Link>
                                            {/* <Link className="dropdown-item" to="#"><i className="far fa-trash-alt me-2" />Delete</Link> */}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                  {customerBooking.length === 0 && (
                                    <tr>
                                      <td align="left" colSpan={8}>
                                        No Records Found.
                                      </td>
                                    </tr>
                                  )}
                                  {isLoading && (
                                    <tr>
                                      <td align="center" colSpan={8}>
                                        Loading...
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )}
                              {/* <Pagination
                              totalItem={totalRecords}
                              itemsPerPage={limit}
                              page={page}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleLimitChange}
                              colSpan={8}
                            /> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content container-fluid absolute px-2">
                    <footer className="text-center text-lg-start">
                      <div className="text-center pb-4">
                        Powered by Alsaffron System.
                      </div>
                    </footer>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
