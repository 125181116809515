import React, { useState } from 'react'
import { StyledMenu } from '../../../Utils';
import moment from "moment";
import DateRangeFilter from '../../../Utils/DateRangeFilter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterViewEarningReoprt = ({handleSubmitFilter, handleReset, resetDate, handleExportFilter }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [passDate,setPassDate] = useState()
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    const [openCal, setOpenCal] = useState(false);
    const onChange = (ranges) => {
      if (
        moment(ranges.startDate).format("MM-DD-YYYY") !==
        moment(ranges.endDate).format("MM-DD-YYYY")
      ) {
        setOpenCal(false);
      } else if (ranges.startDate === "" && ranges.endDate === "") {
        setOpenCal(false);
      }
      const formatDate = (date) => moment(date).format("YYYY-MM-DD");
      const endDate = ranges.endDate === null ? formatDate(ranges.startDate) : formatDate(ranges.endDate);
      const startDate = ranges.startDate ? formatDate(ranges.startDate) : "";
      setPassDate(startDate +","+endDate)
    };

  return (
    <div className="card report-card">
    <div className="card-body pb-0">
      <div className="row">
        <ul className="transactions-listing">
          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <li>
              <div className="multipleSelection">
               <DateRangeFilter onChange={onChange} openCal={openCal} setOpenCal={setOpenCal} resetDate={resetDate}/>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                </StyledMenu>
              </div>
            </li>
          </div>
          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <li>
              <div className="report-btn">
                {/* <button type="button" className="btn btn-block btn-outline-primary px-4">Submit</button> */}
                <button type="button" className="btn btn-block btn-outline-primary btn-reposrt px-4"
                    //   onClick={() => handleSubmitFilter({fStatus:status1,fDate :passDate})}
                    //  > Submit
                       onClick={() => handleSubmitFilter({fDate :passDate})}
                     > Submit          
                </button>
                <button type="button" className="btn btn-block btn-outline-primary  btn-reposrt mx-2 px-4"
                  onClick={() =>{
                      handleReset({fDate: "" });
                      setPassDate("");
                    }}
                     >Reset</button>
                <button type="button" className="btn btn-block btn-outline-primary btn-reposrt px-4"
                    // onClick={() =>{ handleExportFilter({fStatus:status1,fDate :passDate})
                    onClick={() =>{ handleExportFilter({fDate :passDate})
                     setPassDate("");
                    //  setStatus([])
                    }}
                    >
                    <img src="../inner-assets/img/icons/invoices-icon5.svg" alt="" className="me-2" />
                    Generate Report</button>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
    </div>
  )
}

export default FilterViewEarningReoprt