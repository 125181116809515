import { BrowserRouter as Router} from "react-router-dom";
import Routings from './routes'
import { HelmetProvider } from 'react-helmet-async';
function App() {
  return (
    <div className="App">
      <HelmetProvider>
      <Router>
       <Routings/>
      </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;