export const checkExpiration = (date) => {
  const currentDate = new Date();
  const expiryDate = new Date(date);
  if (currentDate > expiryDate) {
    return true;
  }
  return false;
};

export const showCustName = (customer_name, company_name) => {
  return company_name ? `${customer_name} (${company_name})` : `${customer_name}`;
};

export const onImageError = (e) => {
  /* if (e.target.src !== "../inner-assets/img/profiles/default_image.jpg"){ */
    e.target.src="/inner-assets/img/profiles/default_image.jpg";
  /* } */
};