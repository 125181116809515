import Api from "../../Utils/Axios";

export const GetFaqsPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await Api.get(`/admin/faqs?offset=${page}&limit=${limit}&type=all`)
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const FetchFaqsById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/faqs/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const AddFaqs = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/faqs", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditFaqs = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put("/admin/faqs", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const ChangeStatus = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/faqs/change_status", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteFaqs = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/admin/faqs/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};
