import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import { GetBankingAccountingOption } from "../../../Services/Api/bankingOption";
import { Pagination } from "../../../Utils";

const BankAccounts = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [bankingOptionListPagination, setBankingOptionListPagination] =
    useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const getGetBankingOptionList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetBankingAccountingOption({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.accounts.length > 0) {
          setBankingOptionListPagination(res?.data?.data?.accounts);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setBankingOptionListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setBankingOptionListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getGetBankingOptionList(limit, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getGetBankingOptionList(parseInt(event.target.value, 10), 0);
  };

  useEffect(() => {
    getGetBankingOptionList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Banking Details</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Banking Details</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Banking Details</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Logo</th>
                          <th>Merchant Name</th>
                          <th>Title</th>
                          <th>Bank Holder / Beneficiary</th>
                          <th>Account Number</th>
                          <th>IBAN</th>
                          <th>Swift Code</th>
                          <th>Currency</th>
                          {/* <th className="text-center">Status</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {bankingOptionListPagination.map(
                              (accounts, index) => (
                                <tr key={index}>
                                  <td>
                                    <img
                                      className="logoImg"
                                      src={accounts?.logo}
                                      alt="User"
                                    />
                                  </td>
                                  <td>{accounts?.user?.name}</td>
                                  <td>{accounts?.title}</td>
                                  <td>{accounts?.beneficiary}</td>
                                  <td>{accounts?.account_number}</td>
                                  <td>{accounts?.iban}</td>
                                  <td>{accounts?.swift_code}</td>
                                  <td>{accounts?.currency}</td>
                                </tr>
                              )
                            )}
                            {bankingOptionListPagination.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={8}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={8}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="content container-fluid absolute px-2">
              <footer className="text-center text-lg-start">
                <div className="text-center pb-4">
                  Powered by Alsaffron System.
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccounts;
