import Api from "../../Utils/Axios";

export const GetNewsBulletinsPaginate = async ({limit,page}) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/news_bulletins?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const FetchNewsBulletinById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/news_bulletins/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const AddNewsBulletin = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/admin/news_bulletins',data));
    } catch (error) {
      reject(error);
    }
  })
}

export const EditNewsBulletin = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put("/admin/news_bulletins",data));
    } catch (error) {
      reject(error);
    }
  })
}

export const ChangeStatus = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/news_bulletins/change_status",data));
    } catch (error) {
      reject(error);
    }
  })
}

export const DeleteNewsBulletin = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/admin/news_bulletins/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}