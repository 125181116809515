import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {
  getBankingOptionByIdService,
  getEditBankingOptionService,
} from "../../../Services/Api/bankingOption";
import { GetCountry } from "../../../Services/Api/master";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { getProfile } from "../../../Services/Api/auth";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import "react-phone-number-input/style.css";
import { Helmet } from "react-helmet-async";
// import { AddBankingListOptions } from "../../../Services/Api/bankingOption";

const EditBankingOption = () => {
  const navigate = useNavigate();
  const [currency, setCurrency] = useState([]);
  const stateData = useLocation();
  const { id } = stateData?.state || "";

  const [profileData, setProfileData] = useState({});
  const [editBank, setEditBank] = useState({});
  const [logoImage, setLogoImage] = useState(false);
  const [logo, setLogo] = useState([]);

  // const [bankList, setBankList] = useState([]);
  // const [logo_path, setLogo_path] = useState("");
  // const [name, setName] = useState('');
  // const [isBankShow, setIsBankShow] = useState(false);

  const getProfileData = async () => {
    getProfile()
      .then((res) => {
        setProfileData(res?.data?.data || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //     ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //     ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  const GetBankingOptionById = async () => {
    getBankingOptionByIdService(id)
      .then((res) => {
        setEditBank(res?.data?.data?.bank_account);
        let bankData = res?.data?.data.bank_account;
        if (typeof bankData !== "undefined" && bankData !== "") {
          setLogo(bankData.logo);
        }
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //     ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //     ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  const getCountryData = async () => {
    await GetCountry().then((res) => {
      const resCountryData = res?.data?.data || [];
      const currencyFilterData = resCountryData.map((country) => {
        return {
          label: `${country?.currency} - ${country?.name}`,
          value: country?.currency,
        };
      });
      setCurrency(currencyFilterData);
    });
  };
  const uploadLogo = async (e) => {
    const logoFile = e.target.files[0];
    const profilePostData = {
      user_id: profileData._id,
      file_name: logoFile.name,
      file_module: "BANK_LOGOS",
      mime_type: logoFile.type,
      document_sides: "NONE",
    };
    await fileUploadToBucket({ data: profilePostData, file: logoFile }).then(
      (res) => {
        setLogoImage(res?.data?.data?.s3_key || false);
      }
    );
  };
  const ibanRegExp = /^[a-zA-Z0-9]+$/;
  const accountNumberRegExp = /^[0-9 ]+$/;

  // const GetBankList = async () => {
  //     await AddBankingListOptions({ limit: 10, page: 0, paginate: false })
  //         .then((res) => {
  //             if (res?.data?.data?.accounts.length > 0) {
  //                 let bankArr = res?.data?.data?.accounts;
  //                 bankArr = bankArr.map((value) => {
  //                     return {
  //                         label: value.name,
  //                         value: value.id,
  //                         currencyValue: value.primary_currency,
  //                         logo: value.logo,
  //                         name: value.name,
  //                         logo_path:value.logo_path,
  //                     };
  //                 });
  //                 bankArr.push({ label: "Other", name: "" })
  //                 setBankList(bankArr);
  //             } else {
  //                 // ErrorToast("No Results.");
  //                 setBankList([]);
  //             }
  //         })
  //         .catch((err) => {
  //            setBankList([]);
  //             // if (typeof err.response.data.message !== "undefined") {
  //             //   ErrorToast(err.response.data.message || "Server Error!!");
  //             // } else {
  //             //   ErrorToast(err?.message || "Server Error!!");
  //             // }
  //         });
  // };
  let initData = {
    name: editBank.title || "",
    bankHolderBeneficiary: editBank?.beneficiary || "",
    accountNumber: editBank?.account_number || "",
    iban: editBank?.iban || "",
    swiftCode: editBank?.swift_code || "",
    currency:
      currency.filter((option) => option.value === editBank.currency)[0] || "",
    logoFile: "",
  };
  useEffect(() => {
    GetBankingOptionById();
    getCountryData();
    getProfileData();
    // GetBankList();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Edit Banking Options</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Banking Options</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/banking-options">Banking Options</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edit Banking Options
                  </li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/banking-options"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initData}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Title is required"),
                      bankHolderBeneficiary: Yup.string().required(
                        "Bank holder / Beneficiary is required"
                      ),
                      accountNumber: Yup.string()
                        .matches(
                          accountNumberRegExp,
                          "Please enter only digits and space"
                        )
                        .required("Account number is required"),
                      iban: Yup.string()
                        .matches(
                          ibanRegExp,
                          "Please enter only alphanumeric value"
                        )
                        .required("IBAN is required"),
                      swiftCode: Yup.string().required(
                        "Swift code is required"
                      ),
                      currency: Yup.object().required(
                        "Please select a currency"
                      ),
                      logoFile: Yup.string(),
                    })}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      console.log("faizan");

                      const postObj = {
                        account_id: id,
                        title: values.name,
                        logo: logoImage || "",
                        beneficiary: values.bankHolderBeneficiary,
                        account_number: values.accountNumber,
                        iban: values.iban,
                        swift_code: values.swiftCode,
                        currency: values.currency.value,
                      };
                      await getEditBankingOptionService(postObj)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Bank edited successfully."
                          );
                          setSubmitting(false);
                          navigate("/admin/banking-options");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>
                                Title <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.name && touched.name
                                    ? "input-error"
                                    : null
                                }`}
                                name="name"
                                value={values.name}
                                placeholder="Enter title"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Bank Holder / Beneficiary{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.bankHolderBeneficiary &&
                                  touched.bankHolderBeneficiary
                                    ? "input-error"
                                    : null
                                }`}
                                name="bankHolderBeneficiary"
                                value={values.bankHolderBeneficiary}
                                placeholder="Enter bank holder /beneficiary"
                              />
                              <ErrorMessage
                                name="bankHolderBeneficiary"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Account Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.accountNumber && touched.accountNumber
                                    ? "input-error"
                                    : null
                                }`}
                                name="accountNumber"
                                value={values.accountNumber}
                                placeholder="Enter account number"
                              />
                              <ErrorMessage
                                name="accountNumber"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                IBAN <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Enter IBAN"
                                className={`form-control ${
                                  errors.iban && touched.iban
                                    ? "input-error"
                                    : null
                                }`}
                                name="iban"
                                value={values.iban}
                              />
                              <ErrorMessage
                                name="iban"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Swift Code{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Enter swift code"
                                className={`form-control ${
                                  errors.swiftCode && touched.swiftCode
                                    ? "input-error"
                                    : null
                                }`}
                                name="swiftCode"
                                value={values.swiftCode}
                              />
                              <ErrorMessage
                                name="swiftCode"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Currency <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={currency}
                                onChange={(newVal) => {
                                  setFieldValue("currency", newVal);
                                }}
                                name="currency"
                                value={values.currency}
                                placeholder="Select currency"
                              />
                              <ErrorMessage
                                name="currency"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>Logo</label>
                              <input
                                type="file"
                                name="logoFile"
                                onChange={(e) => {
                                  uploadLogo(e);
                                  setFieldValue(
                                    "logoFile",
                                    e.currentTarget.files[0]
                                  );
                                }}
                                className={`form-control`}
                              />
                              <ErrorMessage
                                name="logoFile"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-md-6 text-center">
                              <img
                                src={logo}
                                alt="logo"
                                className="img-size i-size"
                              />
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Banking Option&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBankingOption;
