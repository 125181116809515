import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Helmet } from "react-helmet-async";
import { addInvestor } from "../../../Services/Api/investor";

const AddInvestor = () => {
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const website =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  const navigate = useNavigate();

  let initData = {
    name: "",
    email: "",
    contact_number: "",
    compnayName: "",
    website: "",
  };

  return (
    <>
      <Helmet>
        <title>CPay | Admin Add Investor</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Investor</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/investor">Investor</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Investor</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/investor" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initData}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Name is required"),
                      email: Yup.string().email().required("Email is required"),
                      contact_number: Yup.string()
                        .matches(phoneRegExp, "Contact number is not valid")
                        .required("Contact number is required"),
                      compnayName: Yup.string(),
                      website: Yup.string().matches(
                        website,
                        "Enter correct url!"
                      ),
                    })}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postObj = {
                        name: values.name,
                        email: values.email,
                        contact_number: values.contact_number,
                        company_name: values.compnayName || "",
                        website: values.website || "",
                      };
                      await addInvestor(postObj)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Investor Created."
                          );
                          setSubmitting(false);
                          navigate("/admin/investor");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  value={values.name}
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="form-group remove-inner-input">
                                <label>
                                  Contact Number (Country Code){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.contact_number}
                                  onChange={(newVal) => {
                                    setFieldValue("contact_number", newVal);
                                  }}
                                  className={`form-control ${
                                    errors.contact_number &&
                                    touched.contact_number
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="contact_number"
                                />
                                <ErrorMessage
                                  name="contact_number"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>website</label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.website && touched.website
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="website"
                                  value={values.website}
                                  placeholder="Enter website"
                                />
                                <ErrorMessage
                                  name="website"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  value={values.email}
                                  placeholder="Enter email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Company Name</label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.compnayName && touched.compnayName
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="compnayName"
                                  value={values.compnayName}
                                  placeholder="Enter company name"
                                />
                                <ErrorMessage
                                  name="compnayName"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Investor
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInvestor;
