import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import format from "date-fns/format";
import { StartCase } from "react-lodash";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import {
  GetNewsBulletinsPaginate,
  ChangeStatus,
  DeleteNewsBulletin,
} from "../../../Services/Api/newsBulletins";
import parse from "html-react-parser";

const ListNews = () => {
  // const parse = require('html-react-parser');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [newsBulletins, setNewsBulletins] = useState([]);
  const navigate = useNavigate();

  const GetNewsBulletins = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetNewsBulletinsPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setNewsBulletins(res?.data?.data?.news_bulletins);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setNewsBulletins([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setNewsBulletins([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetNewsBulletins(limit, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetNewsBulletins(parseInt(event.target.value, 10), 0);
  };

  const changeStatus = (news, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          id: news._id,
          status: `${news.status ^ 1}`,
        };
        await ChangeStatus(postObj)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            GetNewsBulletins(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  const deleteNewsBulletin = (newsId) => {
    Confirmation("Want to delete news?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteNewsBulletin(newsId)
          .then((res) => {
            SuccessToast(res?.data?.message || "News Deleted.");
            GetNewsBulletins(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetNewsBulletins(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin - News Bulletins</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">News Bulletins</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">News Bulletins</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/add-news" className="btn btn-primary me-1">
                  <i className="fas fa-plus" /> Add News Bulletin
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Type</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                          <th>Message</th>
                          <th>Users</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {newsBulletins.map((news, index) => (
                              <tr key={index}>
                                <td>
                                  {
                                    <StartCase
                                      string={_.replace(news?.type, "_", " ")}
                                    />
                                  }
                                </td>
                                <td>
                                  {news?.status === 0 && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeStatus(
                                          news,
                                          "Want to active news?"
                                        );
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                  {news?.status === 1 && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-success"
                                      onClick={() => {
                                        changeStatus(
                                          news,
                                          "Want to deactivate news?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                                <td className="text-center">
                                  <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate("/admin/edit-news", {
                                        state: { id: news?._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() =>
                                      deleteNewsBulletin(news?._id)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                                <td>{parse(news?.message) || "-"}</td>
                                <td>
                                  {news?.users.length > 0 ? (
                                    <>
                                      {news?.users.map((user, index) => (
                                        <div key={index}>{user?.name}</div>
                                      ))}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  {format(
                                    new Date(news?.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                              </tr>
                            ))}
                            {newsBulletins.length === 0 && (
                              <tr>
                                <td colSpan={6}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}

                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={6}>
                              Loading...
                            </td>
                          </tr>
                        )}

                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListNews;
