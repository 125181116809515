import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import {
  ClientGetWalletBalanceService,
  GetStatementPaginateFilter,
  GetTransactionExportFileFilter,
  debitAmountAPI,
} from "../../../Services/Api/customer";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import FilterComponentCustomerStatement from "../Common/FilterComponentCustomerStatement";
import { ErrorToast, DebitCashPopup, SuccessToast } from "../../../Utils/SweetAlert";

const CustomerStatement = () => {
  const stateData = useLocation();
  const { id, user_name } = stateData?.state || {};
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [dateForReport, setDateForReport] = useState("");
  const [statementListPagination, setStatementListPagination] = useState([]);
  const [statementFilter, setStatementFilter] = useState({
    offset: page || 0,
    limit: limit || 10,
    user_id: id,
    filter_date: "",
  });
  const [walletData, setWalletData] = useState([]);
  const [resetDate, setResetDate] = useState(0);

  const handleReset = async ({ fDate }) => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      user_id: id,
      filter_date: fDate || "",
    };
    setDateForReport("");
    setStatementFilter(postData);
  };

  const handleSubmitFilter = async ({ fDate }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      user_id: id,
      filter_date: fDate || "",
    };
    setStatementFilter(postData);
  };

  // const getStatementList = async (limitO = "", pageO = "") => {
  //     setIsLoading(true);
  //     await GetStatementPaginate({ limit: limitO, page: pageO, user_id: id })
  //         .then((res) => {
  //             setIsLoading(false);
  //             if (res?.data?.data?.totalRecords > 0) {
  //                 setStatementListPagination(res?.data?.data?.transactions);
  //                 setTotalRecords(res?.data?.data?.totalRecords);
  //             } else {
  //                 setStatementListPagination([]);
  //                 setTotalRecords(0);
  //                 // ErrorToast("No Results.");
  //             }
  //         })
  //         .catch((err) => {
  //             setStatementListPagination([]);
  //             setTotalRecords(0);
  //             setIsLoading(false);
  //             // if (typeof err.response.data.message !== "undefined") {
  //             //     ErrorToast(err.response.data.message || "Server Error!!");
  //             // } else {
  //             //     ErrorToast(err?.message || "Server Error!!");
  //             // }
  //         });
  // };

  const getStatementListFilter = async (requestPayload) => {
    setIsLoading(true);
    await GetStatementPaginateFilter(requestPayload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setStatementListPagination(res?.data?.data?.transactions);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setStatementListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setStatementListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handleStatementExport = async (type) => {
    setResetDate(0);
    const postData = {
      type: type,
      user_id: id,
      filter_date: dateForReport || "",
    };
    await GetTransactionExportFileFilter(postData)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          // ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const getWalletBalanceData = async () => {
    ClientGetWalletBalanceService(id)
      .then((res) => {
        setWalletData(res?.data?.data || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    // await getStatementList(limit, pageNew);
    setStatementFilter((prevState) => ({ ...prevState, offset: pageNew }));
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    // await getStatementList(parseInt(event.target.value, 10), 0);
    setStatementFilter((prevState) => ({
      ...prevState,
      limit: event.target.value || 10,
      offset: 0,
    }));
  };

  useEffect(() => {
    // getStatementList(limit, page);
    getStatementListFilter(statementFilter);
    getWalletBalanceData();
  }, [statementFilter]);

  const debitAmount = () => {
    DebitCashPopup({ denyButtonText: "Cancel", label: 'Debit Amount' }).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          user_id: id,
          amount: result.value.amount,
          notes: result.value.notes,
        }
        await debitAmountAPI(postObj)
          .then((res) => {
            SuccessToast(res?.data?.message || "Cash debit to customer successfully.");
            getStatementListFilter(statementFilter);
            getWalletBalanceData();
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>CPay | Customer Statement</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title"> {(user_name) ? `Customer Statement For `+user_name : `Customer Statement`} </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Customer Statement</li>
                </ul>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-sm btn-success"
                  style={{ marginRight: "5px" }}
                  onClick={() => handleStatementExport("EXCEL")}
                >
                  Export as Excel
                </button>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => handleStatementExport("PDF")}
                >
                  Export as PDF
                </button>
              </div>
            </div>
          </div>
          <FilterComponentCustomerStatement
            handleSubmitFilter={handleSubmitFilter}
            handleReset={handleReset}
            resetDate={resetDate}
            setDateForReport={setDateForReport}
          />
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon1.svg"
                        alt="wallet"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {walletData?.wallet?.currency || ""}
                        </span>{" "}
                        {walletData?.wallet?.total_amount || "0"}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all text-nowrap d-flex align-items-baseline justify-content-between mt-1">
                    Wallet Balance
                    <span className="inovices-dash-count inovices-amount currency-type-text">
                      <button
                        type="button"
                        className="btn btn-block btn-outline-primary btn-reposrt mx-0 px-2"
                        onClick={() => debitAmount()}
                      >
                        Debit
                      </button>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon2.svg"
                        alt="wallet"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {walletData?.wallet?.currency || ""}
                        </span>{" "}
                        {walletData?.wallet?.cpay_credit || "0"}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all text-nowrap d-flex align-items-baseline justify-content-between mt-4">
                    CPay Credit
                    <span className="inovices-dash-count inovices-amount currency-type-text">
                      <span style={{ fontSize: '10px', marginLeft: '2px' }}>
                        Total Credit : {walletData?.wallet?.currency || ""} 
                      </span>
                      <span style={{ fontSize: '13px', marginLeft: '2px' }}>
                        {walletData?.total_active_cpay_credit?.total_sum || "0"}
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon3.svg"
                        alt="wallet"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {walletData?.wallet?.currency || ""}
                        </span>{" "}
                        {walletData?.wallet?.amount || "0"}
                      </div>
                    </div>
                  </div>
                  {walletData?.wallet?.amount > 0 ? (
                    <p className="inovices-all"> Card / Cash Amount </p>
                  ) : (
                    <p className="inovices-all">
                      Card / Cash Amount
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon3.svg"
                        alt="wallet"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount text-danger">
                        <span className="currency-type-text">
                          {walletData?.wallet?.currency || ""}
                        </span>{" "}
                        {walletData?.total_due_cpay_credit?.total_sum || "0"}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">Due Amount</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Sr. No.</th>
                          <th>Reference Number</th>
                          <th>Description</th>
                          <th className="text-end">Debit</th>
                          <th className="text-end">Credit</th>
                          <th className="text-end">Opening Balance</th>
                          <th className="text-end">Closing Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {statementListPagination.map((statement, index) => (
                              <tr
                                key={index}
                                // className={
                                //   statement.motive == "CASH_DEPOSIT" ||
                                //   statement.motive == "CHEQUE_DEPOSIT"
                                //     ? "table-success"
                                //     : statement.motive == "CREDIT_REQUEST"
                                //     ? "table-danger-light"
                                //     : "table-info"
                                // }
                                // style={ { backgroundColor: '#e5e5e5' } }
                                className={
                                  statement.motive == "CREDIT_REQUEST" &&
                                  statement.closing_balance < 0
                                    ? "table-danger-regular"
                                    : statement.motive != "CREDIT_REQUEST"
                                    ? "table-normal"
                                    : "table-success"
                                }

                              >
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  {/* <a className="invoice-link" onClick={() =>
                                                                        history("/admin/customer-statement", {
                                                                            state: { id: statement.reference_number },
                                                                        })}> */}
                                  {statement.reference_number}
                                  <br />
                                  {/* </a> */}
                                  <span>
                                    {" "}
                                    {format(
                                      new Date(statement.transactionDate),
                                      "dd MMMM, yyyy, HH:mm a"
                                    )}
                                  </span>
                                </td>
                                <td className="text-wrap">
                                  {statement.description}
                                  <br />
                                  {(statement?.booking?.merchant) ? statement?.booking?.merchant?.name+` (`+statement?.booking?.merchant?.company_name+`)` : ''}
                                </td>
                                <td className="text-end">
                                  {statement.transaction_type == "DEBIT" ? (
                                    <>
                                      <span className="currency-type-text">
                                        {statement.transaction_currency}
                                      </span>
                                      &nbsp;
                                      {statement.transaction_amount}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="text-end">
                                  {statement.transaction_type == "CREDIT" ? (
                                    <>
                                      <span className="currency-type-text">
                                        {statement.transaction_currency}
                                      </span>
                                      &nbsp;
                                      {statement.transaction_amount}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="text-end">
                                  <span className="currency-type-text">
                                    {statement.transaction_currency}
                                  </span>
                                  &nbsp;
                                  {statement.opening_balance}
                                </td>
                                <td className="text-end">
                                  <span className="currency-type-text">
                                    {statement.transaction_currency}
                                  </span>
                                  &nbsp;
                                  {statement.closing_balance}
                                </td>
                              </tr>
                            ))}
                            {statementListPagination.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={7}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={7}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerStatement;
