import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import { ApiKeyService, StatusApi } from "../../../Services/Api/apikey";
import { Pagination } from "../../../Utils";

const MerchantApiKeys = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [apiKeys, setApiKeys] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const ApiKeyList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await ApiKeyService({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.apiKeys?.length > 0) {
          setApiKeys(res?.data?.data?.apiKeys);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setApiKeys([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setApiKeys([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StatusChangeApi = (api, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          id: api?._id,
          status: `${api.status ^ 1}`,
        };
        await StatusApi(postObj)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            ApiKeyList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  useEffect(() => {
    ApiKeyList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Banking Options</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Merchant API Keys</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Merchant API Keys</li>
                </ul>
              </div>
              {/* <div className="col-auto">
                <Link to="/admin/add-banking-options" className="btn btn-primary me-1">
                  <i className="fas fa-plus" /> Add Banking Options
                </Link>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th className="text-center">Status</th>
                          <th>Merchant Name</th>
                          <th>Test Id</th>
                          <th>Test Token</th>
                          <th>Production Id</th>
                          <th>Production Token</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {apiKeys.map((api, index) => (
                              <tr key={index}>
                                <td>{limit * page + (index + 1)}</td>
                                <td className="text-center">
                                  {api?.status === 0 && (
                                    <button
                                      className="btn btn-sm btn-block btn-outline-danger"
                                      onClick={() => {
                                        StatusChangeApi(
                                          api,
                                          "Want to active merchant API keys.?"
                                        );
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                  {api?.status === 1 && (
                                    <button
                                      className="btn btn-sm btn-block btn-outline-success"
                                      onClick={() => {
                                        StatusChangeApi(
                                          api,
                                          "Want to deactivate merchant API keys.?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                                <td>{api?.user?.name}</td>
                                <td>{api?.test?.merchantId}</td>
                                <td>{api?.test?.token}</td>
                                <td>{api?.production?.merchantId}</td>
                                <td>{api?.production?.token}</td>
                              </tr>
                            ))}
                            {apiKeys.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={7}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={7}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={7}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantApiKeys;
