import React, { useState, useEffect } from "react";
import { Pagination } from "../../../Utils";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import {
  getEarningWidget,
  GetGenerateViewEarningReport,
  GetViewEarning,
} from "../../../Services/Api/reports";
import { ErrorToast } from "../../../Utils/SweetAlert";
import { FilterViewEarningReoprt } from "../Common";
import { showCustName } from "../../../Utils/CommanFunctions";


const ViewEarningReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [widgetData, setWidgetData] = useState([]);
  const [earningBreakDown, setEarningBreakDown] = useState([]);
  const [toggleEarningBreakDown, setToggleEarningBreakDown] = useState(false);
  const [earningList, setEarningList] = useState([]);
  const [updateWidget, setUpdateWidget] = useState("");
  const [transactionListFilter, setTransactionListFilter] = useState({
    offset: page || 0,
    limit: limit || 10,
    filter_date: "",
  });
  const [resetDate, setResetDate] = useState(0);

  const handleReset = async ({ fDate }) => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      filter_date: fDate || "",
    };
    setUpdateWidget("");
    setTransactionListFilter(postData);
  };

  const handleSubmitFilter = async ({ fDate }) => {
    setResetDate(0);
    setPage(0);
    setUpdateWidget(fDate);
    const postData = {
      offset: 0,
      limit: limit || 10,
      filter_date: fDate || "",
    };
    setTransactionListFilter(postData);
  };

  const handleExportFilter = async ({ fDate }) => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    const postData = {
      filter_date: fDate || "",
    };
    await GetGenerateViewEarningReport(postData)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
          const postData = {
            offset: 0,
            limit: limit || 10,
            filter_date: "",
          };
          setUpdateWidget("");
          setTransactionListFilter(postData);
        } else {
          setUpdateWidget("");
          setTransactionListFilter({
            offset: page || 0,
            limit: limit || 10,
            filter_date: "",
          });
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        setUpdateWidget("");
        setTransactionListFilter({
          offset: page || 0,
          limit: limit || 10,
          filter_date: "",
        });
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const getWidgetData = async () => {
    const data = { filter_date: updateWidget };
    await getEarningWidget(data)
      .then((res) => {
        setWidgetData(res?.data?.data?.widget_counts);
        setEarningBreakDown(
          res?.data?.data?.widget_counts?.earning_from_merchants
            ?.earning_breakdown
        );
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };

  const getEarning = async (requestPayload) => {
    setIsLoading(true);
    await GetViewEarning(requestPayload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setIsLoading(false);
          setEarningList(res?.data?.data?.earnings);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setEarningList([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setEarningList([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if(typeof err?.response?.data?.message !== "undefined"){
        //     ErrorToast(err?.response?.data?.message || 'Server Error!!');
        // }else{
        //     ErrorToast(err?.message || "Server Error!!")
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    setTransactionListFilter((prevState) => ({
      ...prevState,
      offset: pageNew,
    }));
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    setTransactionListFilter((prevState) => ({
      ...prevState,
      limit: event.target.value || 10,
      offset: 0,
    }));
    //  setTransactionListFilter({offset : 0 ,
    //   limit : event.target.value || 10,
    //   filter_date : ""});
  };

  useEffect(() => {
    getWidgetData();
  }, [updateWidget, transactionListFilter]);

  useEffect(() => {
    getEarning(transactionListFilter);
  }, [transactionListFilter]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin View Earning Report</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">View Earning Report</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    View Earning Report
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <FilterViewEarningReoprt
            handleSubmitFilter={handleSubmitFilter}
            handleReset={handleReset}
            resetDate={resetDate}
            handleExportFilter={handleExportFilter}
          />
          <div className="row">
            <div className="col-xl-6 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon3.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">AED </span>
                        {widgetData?.earning_from_customers?.amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Earning From Customers{" "}
                    <span>{widgetData?.earning_from_customers?.records}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon1.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        {widgetData?.earning_from_merchants?.amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    <a
                      className="invoice-link cursor"
                      onClick={() =>
                        setToggleEarningBreakDown(!toggleEarningBreakDown)
                      }
                    >
                      Earning From Merchants{" "}
                    </a>
                    <span>{widgetData?.earning_from_merchants?.records}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {toggleEarningBreakDown === true && (
            <>
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive custom-pagination">
                        <table className="table table-stripped table-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>Currency</th>
                              <th>Number Of Transactions</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {earningBreakDown.map((transaction, index) => (
                              <tr key={index}>
                                <td>{transaction?.currency}</td>
                                <td>{transaction?.count}</td>
                                <td>
                                  <span className="currency-type-text">
                                    {transaction?.currency}{" "}
                                  </span>
                                  {transaction?.totalAmount}
                                </td>
                              </tr>
                            ))}
                            {earningBreakDown.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={6}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3"></div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Reference Number</th>
                          <th>Date</th>
                          <th>Pax Details</th>
                          <th>Customer</th>
                          <th>Customer Fee</th>
                          <th>Merchant</th>
                          <th>Merchant Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {earningList.map((transaction, index) => (
                              <tr key={index}>
                                <td>{transaction?.booking_reference}</td>
                                <td>
                                  {format(
                                    new Date(transaction?.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                                <td>
                                  {transaction?.product === "flight" && (
                                    <>
                                      {transaction?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                            <span>
                                              ({traveller?.passenger_type})
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {transaction?.product === "hotel" && (
                                    <>
                                      {transaction?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {transaction?.product === "visa" && (
                                    <div>
                                      {transaction?.metadata?.visa?.first_name}{" "}
                                      {transaction?.metadata?.visa?.last_name}
                                    </div>
                                  )}
                                </td>
                                <td>{showCustName(transaction?.customer?.name, transaction?.customer?.company_name)}</td>
                                <td>
                                  <span className="currency-type-text">
                                    AED{" "}
                                  </span>
                                  {transaction?.customer_service_charge}
                                </td>
                                <td>{transaction?.merchant?.name}</td>
                                <td>
                                  <span className="currency-type-text">
                                    {transaction?.currency}{" "}
                                  </span>
                                  {transaction?.merchant_service_charge}
                                </td>
                              </tr>
                            ))}
                            {earningList.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={6}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEarningReport;
