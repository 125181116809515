import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Pagination } from "../../../Utils";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import { GetMerchantWalletLogHistoryPaginate } from "../../../Services/Api/customer";
import format from "date-fns/format";
import { Capitalize, StartCase } from "react-lodash";
import _ from "lodash";

const MerchantWalletLogHistory = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [logHistoryListPagination, setLogHistoryListPagination] = useState([]);

  const getLogHistoryList = async (
    limitO = "",
    pageO = "",
    requestIdO = ""
  ) => {
    setIsLoading(true);
    if (requestIdO) {
      await GetMerchantWalletLogHistoryPaginate({
        limit: limitO,
        page: pageO,
        id: requestIdO,
      })
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.data?.totalRecords > 0) {
            setLogHistoryListPagination(res?.data?.data?.log_history);
            setTotalRecords(res?.data?.data?.totalRecords);
          } else {
            setLogHistoryListPagination([]);
            setTotalRecords(0);
            // ErrorToast("No Results.");
          }
        })
        .catch((err) => {
          setLogHistoryListPagination([]);
          setTotalRecords(0);
          setIsLoading(false);
          // if (typeof err.response.data.message !== "undefined") {
          //     ErrorToast(err.response.data.message || "Server Error!!");
          // } else {
          //     ErrorToast(err?.message || "Server Error!!");
          // }
        });
    }
    setIsLoading(false);
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getLogHistoryList(limit, pageNew, props.logHistory);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getLogHistoryList(
      parseInt(event.target.value, 10),
      0,
      props.logHistory
    );
  };

  useEffect(() => {
    getLogHistoryList(limit, page, props.logHistory);
  }, [limit, page, props.logHistory]);

  return (
    <Modal
      show={props.isOpen}
      onHide={() => props.toggle(false)}
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Log History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table table-center table-hover datatable">
            <thead className="thead-light">
              <tr>
                <th>Sr. No.</th>
                <th>Page</th>
                <th>Action</th>
                <th>User</th>
                <th>Deposit</th>
                <th>Credit</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading && (
                <>
                  {logHistoryListPagination.map((logHistory, index) => (
                    <tr>
                      <td>{page * limit + index + 1}</td>
                      <td>
                        <Capitalize string={logHistory.page} />
                      </td>
                      {/* <td><Capitalize string={logHistory.action} /></td> */}
                      <td>
                        <StartCase
                          string={_.toLower(
                            _.replace(logHistory.action, "_", " ")
                          )}
                        />
                      </td>
                      <td>
                        {logHistory.user ? (
                          <>
                            {logHistory.user.name} <br></br>
                            {logHistory.user.mobile
                              ? "(" + logHistory.user.mobile + ")"
                              : ""}
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {logHistory.transaction ? (
                          <>
                            <span className="currency-type-text">
                              {logHistory.transaction.deposited_currency}
                            </span>{" "}
                            {logHistory.transaction.deposited_amount}
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {logHistory.transaction ? (
                          <>
                            <span className="currency-type-text">
                              {logHistory.transaction.transaction_currency}
                            </span>{" "}
                            {logHistory.transaction.transaction_amount}
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {format(
                          new Date(logHistory.createdAt),
                          "dd MMMM, yyyy hh:mm a"
                        )}
                      </td>
                    </tr>
                  ))}
                  {logHistoryListPagination.length === 0 && (
                    <tr>
                      <td className="text-center" colSpan={7}>
                        No Records Found.
                      </td>
                    </tr>
                  )}
                </>
              )}
              {isLoading && (
                <tr>
                  <td align="center" colSpan={7}>
                    Loading...
                  </td>
                </tr>
              )}
              <Pagination
                totalItem={totalRecords}
                itemsPerPage={limit}
                page={page}
                handleChangePage={handlePageChange}
                handleChangeRowsPerPage={handleLimitChange}
                colSpan={8}
              />
            </tbody>
          </table>
          <div class="content container-fluid absolute pt-2">
            <footer className="text-center text-lg-start">
              <div className="text-center">
                Powered by Alsaffron System.
              </div>
            </footer>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MerchantWalletLogHistory;
