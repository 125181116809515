import React, { useState, useEffect, useMemo } from 'react'
import { StyledMenu } from '../../../Utils';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { GetMerchantUserPaginate } from "../../../Services/Api/merchant";
import moment from "moment";
import DateRangeFilter from '../../../Utils/DateRangeFilter';
import {  ListSubheader,TextField,InputAdornment} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const statusArr = [
    { label :'Active', value :0},
    { label :'Inactive', value :1},
  ];

const FilterComponentMerchant = ({handleGenerateReport, resetDate, handleSubmitFilter, handleReset}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [status, setStatus] = React.useState([]);
    const [merchnatListPagination, setMerchantListPagination] = useState([]);
    const [user, setUser] = React.useState([]);
    const [passDate,setPassDate] = useState()
    const open = Boolean(anchorEl);
    const [selectedOption, setSelectedOption] = useState([]);
    const [searchText, setSearchText] = useState("");

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const status1 = status.map(u => u).join(",");
  
    const handleChangeUser = (event) => {
        setSelectedOption(event.target.value)
        const {
            target: { value },
        } = event;
        setUser(
            typeof value === 'string' ? value.split(',') : value,
        );
        setSelectedOption(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const user1 = user.map(u => u).join(",");

    const [openCal, setOpenCal] = useState(false);
    const onChange = (ranges) => {
        if (
            moment(ranges.startDate).format("MM-DD-YYYY") !==
            moment(ranges.endDate).format("MM-DD-YYYY")
        ) {
            setOpenCal(false);
        } else if (ranges.startDate === "" && ranges.endDate === "") {
            setOpenCal(false);
        }
        const formatDate = (date) => moment(date).format("YYYY-MM-DD");
        const endDate = ranges.endDate === null ? formatDate(ranges.startDate) : formatDate(ranges.endDate);
        const startDate = ranges.startDate ? formatDate(ranges.startDate) : "";
         setPassDate(startDate +","+endDate)
    };

    const getMerchantList = async () => {
       await GetMerchantUserPaginate({ limit: 10, page: 0, paginate: false })
            .then((res) => {
                if (res?.data?.data?.merchants.length > 0) {
                    let merchantArr = res?.data?.data?.merchants;
                    merchantArr = merchantArr.map((value) => {
                        return {
                            label: value.name,
                            value: value.id,
                            currencyValue: value.primary_currency,
                         };
                    });
                    setMerchantListPagination(merchantArr);
                    setSelectedOption(merchantArr)
                } else {
                    // ErrorToast("No Results.");
                    setMerchantListPagination([]);
                    setSelectedOption([])
                }
            })
            .catch((err) => {
                setMerchantListPagination([]);
                setSelectedOption([])
                // if (typeof err.response.data.message !== "undefined") {
                //   ErrorToast(err.response.data.message || "Server Error!!");
                // } else {
                //   ErrorToast(err?.message || "Server Error!!");
                // }
            });
    };

    const containsText = (text, searchText) =>
    text.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    
    const displayedOptions = useMemo(
        () => merchnatListPagination.filter((option) => {
            if(searchText == '')
                return option;
            else
            return containsText(option, searchText)
        }),
        [searchText, merchnatListPagination]
    );

    useEffect(() => {
        getMerchantList();
    }, [])

    return (
        <div className="card report-card">
            <div className="card-body pb-0">
                <div className="row justify-content-end">
                    {/* <div className="col-md-12">
                        <ul className="transactions-listing"> */}
                            <div className='col-lg-4 col-md-12 col-sm-12'>
                                {/* <li> */}
                                    <div className="multipleSelection">
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="demo-multiple-checkbox-label"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-plus me-1 select-icon"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
                                                Select Merchant</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={user}
                                                // options={merchnatListPagination}
                                                options={selectedOption}
                                                onChange={handleChangeUser}
                                                input={<OutlinedInput label={(<><svg xmlns="http://www.w3.org/2000/svg" 
                                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark me-1 select-icon"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                                                    Select Merchant</>)} />}
                                                renderValue={(selected) =>
                                                    merchnatListPagination.filter(merchant => selected.includes(merchant.value))
                                                        .map(record => record.label)
                                                        .join(", ")}
                                                onClose={() => setSearchText("")}
                                                MenuProps={MenuProps}
                                                onMouseDown={() => {setOpenCal(false)}}
                                            >
                                                 <ListSubheader>
                                                        <TextField
                                                        size="small"
                                                        // Autofocus on textfield
                                                        autoFocus
                                                        placeholder="Type to search..."
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={(e) => setSearchText(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                            // Prevents autoselecting item while typing (default Select behaviour)
                                                            e.stopPropagation();
                                                            }
                                                        }}
                                                        />
                                                </ListSubheader>
                                                {displayedOptions.map((name, index) => (
                                                    <MenuItem key={index} value={name.value}>
                                                        <Checkbox checked={user.indexOf(name.value) > -1} />
                                                        <ListItemText primary={name.label} />
                                                    </MenuItem>
                                                ))}
                                                {/* {merchnatListPagination.map((name, index) => (
                                                    <MenuItem key={index} value={name.value}>
                                                        <Checkbox checked={user.indexOf(name.value) > -1} />
                                                        <ListItemText primary={name.label} />
                                                    </MenuItem>
                                                ))} */}
                                            </Select>
                                        </FormControl>
                                    </div>
                                {/* </li> */}
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12'>
                                {/* <li> */}
                                    <div className="multipleSelection">
                                        <DateRangeFilter onChange={onChange} openCal={openCal} setOpenCal={setOpenCal} resetDate={resetDate}/>
                                    </div>
                                    <StyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                     </StyledMenu>
                                {/* </li> */}
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12'>
                                {/* <li> */}
                                    <div className="multipleSelection">
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="demo-multiple-checkbox-label"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book-open me-1 select-icon"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                                                Select Status</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={status}
                                                onChange={handleChangeStatus}
                                                input={<OutlinedInput label={(<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book-open me-1 select-icon"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                                                    Select Status</>)} />}
                                                renderValue={(selected) =>
                                                    statusArr.filter(status => selected.includes(status.value))
                                                                .map(record => record.label)
                                                                .join(", ")
                                                              }
                                                MenuProps={MenuProps} 
                                                onMouseDown={() => {setOpenCal(false)}}
                                            >
                                                {statusArr.map((name, index) => (
                                                    <MenuItem key={index} value={name.value}>
                                                        <Checkbox checked={status.indexOf(name.value) > -1} />
                                                        <ListItemText primary={name.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                {/* </li> */}
                            </div>
                            
                            <div className='col-lg-6 col-md-12 col-sm-12'>
                                {/* <li> */}
                                    <div className="report-btn justify-content-end">
                                    <button type="button" className="btn btn-block btn-outline-primary btn-reposrt px-4"
                       onClick={() => handleSubmitFilter({fStatus : status, fDate : passDate, fUser : user })}
                     > Submit          
                </button>
                <button type="button" className="btn btn-block btn-outline-primary  btn-reposrt mx-2 px-4"
                  onClick={() =>{
                    if (typeof handleReset != 'undefined') {
                        handleReset();
                    }
                      setPassDate("");
                      setStatus([])
                      setUser([])
                      setSearchText("")
                    }}
                     >Reset</button>
                                    <button type="button" className="btn btn-block btn-outline-primary btn-reposrt"
                                        onClick={() =>{
                                            handleGenerateReport({ fStatus : status1, fDate : passDate, fUser : user1 })
                                            setPassDate("");
                                            setStatus([])
                                            setUser([])
                                            setSearchText("")
                                        }}
                                    >
                                        <img src="../inner-assets/img/icons/invoices-icon5.svg" alt="" className="me-2" />
                                        Genereate Report</button>
                                    </div>
                                {/* </li> */}
                            </div>
                        {/* </ul>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default FilterComponentMerchant