import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import {
  GetCRPenaltySetting,
  UpdateCRPenaltySetting,
} from "../../../Services/Api/master";
import { Helmet } from "react-helmet-async";
import Select from "react-select";

const CRPenaltyRequest = () => {
  const paneltyPeriod = [
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Fort Night", value: "FORT_NIGHT" },
  ];

  let dfltFormData = {
    cr_due_days: "",
    cr_penalty_start_days: "",
    cr_penalty_amount: "",
    cr_penalty_period: "",
  };
  const [initData, setInitData] = useState(dfltFormData);

  const getCRSettingData = async () => {
    await GetCRPenaltySetting()
      .then((res) => {
        const dfltDataCRP = res?.data?.data?.creditReqPenalty || null;
        setInitData({
          cr_due_days: dfltDataCRP.cr_due_days,
          cr_penalty_start_days: dfltDataCRP.cr_penalty_start_days,
          cr_penalty_amount: dfltDataCRP.cr_penalty_amount,
          cr_penalty_period: dfltDataCRP.cr_penalty_period,
        });
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  useEffect(() => {
    getCRSettingData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin CR Penalty Request</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="page-title">CR Penalty</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Credit Request Penalty Setting
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Credit Request Penalty Setting</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initData}
                    validationSchema={Yup.object().shape({
                      cr_due_days: Yup.number()
                        .positive("Must be greater then 0")
                        .moreThan(-1, "Due days should not be less than zero")
                        .required("Due days is required")
                        .typeError("Please enter number only"),
                      cr_penalty_start_days: Yup.number()
                        .moreThan(
                          -1,
                          "Penalty start days should not be less than zero"
                        )
                        .required("Penalty start days is required")
                        .typeError("Please enter number only"),
                      cr_penalty_amount: Yup.number()
                        .positive("Must be greater then 0")
                        .nullable(true)
                        .required("Penalty amount is required")
                        .typeError("Please enter number only"),
                      cr_penalty_period: Yup.object().nullable(true),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      const data = {
                        cr_due_days: values.cr_due_days,
                        cr_penalty_start_days: values.cr_penalty_start_days,
                        cr_penalty_amount: values.cr_penalty_amount,
                        cr_penalty_period: values.cr_penalty_period,
                      };
                      UpdateCRPenaltySetting(data)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "Setting data has been update."
                          );
                          setSubmitting(false);
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Due Days{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_due_days && touched.cr_due_days
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_due_days"
                                  placeholder="Enter due days"
                                  value={values.cr_due_days}
                                />
                                <ErrorMessage
                                  name="cr_due_days"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Penalty Start Days{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_penalty_start_days &&
                                    touched.cr_penalty_start_days
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_penalty_start_days"
                                  value={values.cr_penalty_start_days}
                                  placeholder="Enter penalty start days"
                                />
                                <ErrorMessage
                                  name="cr_penalty_start_days"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                            <div className="form-group col-md-3">
                              <label>Penalty Period</label>
                              <Select
                                value={paneltyPeriod.filter(
                                  (option) =>
                                    option.value === values.cr_penalty_period
                                )}
                                options={paneltyPeriod}
                                onChange={(e) => {
                                  setFieldValue("cr_penalty_period", e.value);
                                }}
                                name="cr_penalty_period"
                                placeholder="Enter penalty period"
                              />
                              <ErrorMessage
                                name="cr_penalty_period"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Penalty Amount{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  placeholder="Enter penalty amount"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_penalty_amount &&
                                    touched.cr_penalty_amount
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_penalty_amount"
                                  value={values.cr_penalty_amount}
                                />
                                <ErrorMessage
                                  name="cr_penalty_amount"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Update Setting&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CRPenaltyRequest;
