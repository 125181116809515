import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SideMenu from "./SideMenu";
import { getProfile, updateProfile } from "../../../Services/Api/auth";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { GetCountry } from "../../../Services/Api/master";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import { Helmet } from "react-helmet-async";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import { onImageError } from "../../../Utils/CommanFunctions";

const Settings = () => {
  const [profileData, setProfileData] = useState({});
  const [countryOption, setCountryOption] = useState([]);
  const [loader, setLoader] = useState(false);
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  const getProfileData = async () => {
    getProfile()
      .then((res) => {
        setProfileData(res?.data?.data || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  const uploadProfile = async (e) => {
    setLoader(true);
    const profilePic = e.target.files[0];
    const profilePostData = {
      user_id: profileData._id,
      file_name: profilePic.name,
      file_module: "PROFILE_PICTURE",
      mime_type: profilePic.type,
      document_sides: "NONE",
    };
    await fileUploadToBucket({ data: profilePostData, file: profilePic }).then(
      (res) => {
        getProfileData();
        setLoader(false);
      }
    );
    e.target.value = null;
  };

  const getCountryData = async () => {
    await GetCountry().then((res) => {
      const resCountryData = res?.data?.data || [];
      const countryFilterData = resCountryData.map((country) => {
        return {
          label: country?.name,
          value: country?.name,
        };
      });
      setCountryOption(countryFilterData);
    });
  };

  useEffect(() => {
    getCountryData();
    getProfileData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin settings</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="page-title">Settings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Profile Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SideMenu />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Basic information</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      email: profileData?.email || "",
                      name: profileData?.name || "",
                      city: profileData?.billing_address?.city || "",
                      state: profileData?.billing_address?.state || "",
                      country:
                        countryOption.find(
                          (ct) =>
                            ct.value === profileData?.billing_address?.country
                        ) ||
                        countryOption.find(
                          (ct) => ct.value === countryOption[0].value
                        ),
                      zipcode: profileData?.billing_address?.zip_code || "",
                      address: profileData?.billing_address?.address || "",
                      phone: profileData?.mobile || "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email().required("Email is required"),
                      name: Yup.string().required("Name is required"),
                      city: Yup.string().required("City is required"),
                      country: Yup.object().required("Country is required"),
                      state: Yup.string().required("State is required"),
                      address: Yup.string().required("Address is required"),
                      zipcode: Yup.number().required(
                        "PO box number / Pin code is required"
                      ),
                      phone: Yup.string().matches(
                        phoneRegExp,
                        "Please enter a correct  number"
                      ),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      const data = {
                        user_id: profileData._id,
                        name: values.name,
                        mobile: values.phone,
                        billing_address: {
                          name: values.name,
                          address: values.address,
                          phone:
                            profileData?.billing_address?.phone || values.phone,
                          country: values.country.value,
                          state: values.state,
                          city: values.city,
                          zip_code: values.zipcode.toString(),
                        },
                      };
                      updateProfile(data)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "Profile data has been update."
                          );
                          setSubmitting(false);
                          getProfile()
                            .then((res) => {
                              setProfileData(res.data.data);
                              localStorage.setItem(
                                "userData",
                                JSON.stringify(res.data.data)
                              );
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                            });
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          <div className="row form-group">
                            <label
                              htmlFor="name"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Profile Image
                            </label>
                            <div className="col-sm-9">
                              <div className="d-flex align-items-center responsive-icon-center">
                                <label
                                  className="avatar avatar-xxl profile-cover-avatar m-0 "
                                  htmlFor="edit_img"
                                >
                                  <img
                                    // id="avatarImg"
                                    className="avatar-img center-pro"
                                    src={
                                      profileData?.profile_picture || ""
                                    }
                                    alt="Profile-Imagie"
                                    onError={onImageError}
                                  />
                                  <input
                                    type="file"
                                    id="edit_img"
                                    onChange={(e) => uploadProfile(e)}
                                  />
                                  <span className="avatar-edit">
                                    {loader && (
                                      <i className="fas fa-spinner fa-spin"></i>
                                    )}
                                    {!loader && (
                                      <i
                                        data-feather="edit-2"
                                        className="avatar-uploader-icon fa fa-pencil-alt shadow-soft"
                                      />
                                    )}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label
                              htmlFor="name"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Name <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.name && touched.name
                                    ? "input-error"
                                    : null
                                }`}
                                id="name"
                                placeholder="Enter name"
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                                // disabled={profileData?.name != ""}
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label
                              htmlFor="email"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="email"
                                className={`form-control ${
                                  errors.email && touched.email
                                    ? "input-error"
                                    : null
                                }`}
                                id="email"
                                placeholder="Enter email"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                disabled={profileData?.email != ""}
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label
                              htmlFor="phone"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Mobile Number{" "}
                              <span className="text-muted">
                                <span className="text-danger">*</span>
                              </span>
                            </label>
                            <div className="col-sm-9 remove-inner-input">
                              <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="AE"
                                type="text"
                                value={values.phone}
                                onChange={(newVal) => {
                                  setFieldValue("phone", newVal);
                                }}
                                className={`form-control ${
                                  errors.phone && touched.phone
                                    ? "input-error"
                                    : null
                                }`}
                                name="phone"
                                // disabled={profileData?.phone != ""}
                              />
                              <ErrorMessage
                                name="phone"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label
                              htmlFor="location"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Location <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <div className="mb-3">
                                <Select
                                  options={countryOption}
                                  onChange={(newVal) => {
                                    setFieldValue("country", newVal);
                                  }}
                                  name="country"
                                  placeholder="Select country"
                                  value={values.country}
                                  // isDisabled={profileData?.country != ""}
                                />
                                <ErrorMessage
                                  name="country"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="mb-3">
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.city && touched.city
                                      ? "input-error"
                                      : null
                                  }`}
                                  placeholder="Enter city"
                                  name="city"
                                  onChange={handleChange}
                                  value={values.city}
                                  // disabled={profileData?.city != ""}
                                />
                                <ErrorMessage
                                  name="city"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.state && touched.state
                                    ? "input-error"
                                    : null
                                }`}
                                placeholder="Enter state"
                                name="state"
                                value={values.state}
                                // disabled={profileData?.state != ""}
                              />
                              <ErrorMessage
                                name="state"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label
                              htmlFor="addressline"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Address <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.address && touched.address
                                    ? "input-error"
                                    : null
                                }`}
                                id="addressline"
                                placeholder="Enter address"
                                name="address"
                                onChange={handleChange}
                                value={values.address}
                                // disabled={profileData?.address != ""}
                              />
                              <ErrorMessage
                                name="address"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label
                              htmlFor="zipcode"
                              className="col-sm-3 col-form-label input-label"
                            >
                              PO box number / Pin code{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.zipcode && touched.zipcode
                                    ? "input-error"
                                    : null
                                }`}
                                id="zipcode"
                                placeholder="Enter PO box number / Pin code"
                                name="zipcode"
                                onChange={handleChange}
                                value={values.zipcode}
                                // disabled={profileData?.zipcode != ""}
                              />
                              <ErrorMessage
                                name="zipcode"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Save Changes&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
