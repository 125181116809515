import React, { useState } from 'react'
import { StyledMenu } from '../../../Utils';
import moment from "moment";
import DateRangeFilter from '../../../Utils/DateRangeFilter';

const FilterComponentCustomerStatement = ({handleSubmitFilter, handleReset, resetDate, setDateForReport }) => {
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [passDate,setPassDate] = useState()
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
 
  const [openCal, setOpenCal] = useState(false);
  const onChange = (ranges) => {
    if (
      moment(ranges.startDate).format("MM-DD-YYYY") !==
      moment(ranges.endDate).format("MM-DD-YYYY")
    ) {
      setOpenCal(false);
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      setOpenCal(false);
    }
    const formatDate = (date) => moment(date).format("YYYY-MM-DD");
    const endDate = ranges.endDate === null ? formatDate(ranges.startDate) : formatDate(ranges.endDate);
    const startDate = ranges.startDate ? formatDate(ranges.startDate) : "";
    setPassDate(startDate +","+endDate)
    setDateForReport(startDate +","+endDate)
  };
  return (
    <div className="card report-card">
            <div className="card-body pb-0">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="transactions-listing">
                          <div className='col-lg-6 col-md-12 col-sm-12'>
                                <li>
                                    <div className="multipleSelection">
                                        <DateRangeFilter onChange={onChange} openCal={openCal} setOpenCal={setOpenCal} resetDate={resetDate}/>
                                        <StyledMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'demo-customized-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                        >
                                        </StyledMenu>
                                    </div>
                                </li>
                          </div>
                           <div className='col-lg-6 col-md-12 col-sm-12'>
                                <li>
                                    <div className="report-btn">
                                        <button type="button" className="btn btn-block btn-outline-primary btn-reposrt px-4"
                                            onClick={() => handleSubmitFilter({fDate :passDate})}
                                            > Submit
                                                    
                                        </button>
                                        <button type="button" className="btn btn-block btn-outline-primary  btn-reposrt mx-2 px-4"
                                        onClick={() =>{
                                            handleReset({fDate: "" });
                                            setPassDate("");
                                            setDateForReport("")
                                            }}
                                            >Reset</button>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default FilterComponentCustomerStatement