import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import { GetMerchantWalletRequestPaginate } from "../../../Services/Api/customer";
import format from "date-fns/format";
import { Helmet } from "react-helmet-async";
import MerchantWalletLogHistory from "./MerchantWalletLogHistory";
import { StartCase } from "react-lodash";
import _ from "lodash";

const MerchantWalletRequest = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customerWalletRequest, setCustomerWalletRequest] = useState([]);
  const [showLogHistory, setShowLogHistory] = useState(false);
  const [logHistoryId, setLogHistoryId] = useState(false);

  const handleShowLogHistory = async (request) => {
    if (request) {
      setLogHistoryId(request);
      setShowLogHistory(true);
    } else {
      setLogHistoryId(false);
      setShowLogHistory(false);
    }
  };

  const getCustomerList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetMerchantWalletRequestPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerWalletRequest(res?.data?.data?.transactions);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerWalletRequest([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCustomerWalletRequest([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getCustomerList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Merchant Wallet Request</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Wallet Requests</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active"> Wallet Requests</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/add-wallet-amount"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-plus" /> Add Wallet Amount
                </Link>
              </div>
            </div>
          </div>
          {/* common component import here */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                          <th>Reference Number</th>
                          <th>Merchant</th>
                          <th>Bank Details</th>
                          <th>Type</th>
                          <th>Deposited Amount</th>
                          <th>Credited Amount</th>
                          <th>Bank Reference</th>
                          <th>User Reference</th>
                          <th>On Behalf Of</th>
                          <th>Reviewed By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerWalletRequest.map((customer, index) => (
                              <tr key={index}>
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  {customer.status === 1 && (
                                    <span className="badge badge-pill bg-success-light">
                                      Approved
                                    </span>
                                  )}
                                  {customer.status === 2 && (
                                    <>
                                      <span className="badge badge-pill bg-danger-light">
                                        Rejected
                                      </span>{" "}
                                      |{" "}
                                      <span
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          navigate(
                                            "/admin/edit-wallet-amount",
                                            {
                                              state: { tid: customer._id },
                                            }
                                          )
                                        }
                                      >
                                        Edit{" "}
                                      </span>
                                    </>
                                  )}
                                  {customer.status === 0 && (
                                    <>
                                      <span className="badge badge-pill bg-warning-light">
                                        Pending
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                      handleShowLogHistory(customer._id)
                                    }
                                  >
                                    Log History
                                  </button>
                                </td>
                                <td>
                                  {customer?.reference_number || "-"}
                                  <br />
                                  <span>
                                    {format(
                                      new Date(customer?.createdAt),
                                      "dd MMMM, yyyy hh:mm a"
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {customer?.user?.name || "-"}
                                  <br />
                                </td>
                                <td>
                                  <span>
                                    {customer?.destination_bank?.title}
                                  </span>
                                  -
                                  <span>
                                    {customer?.destination_bank?.account_number}
                                  </span>
                                  &nbsp;&nbsp;
                                  <span>
                                    {customer?.destination_bank?.currency}
                                  </span>
                                  <br />
                                  <span>
                                    {customer?.destination_bank?.beneficiary}
                                  </span>
                                </td>
                                <td>
                                  <StartCase
                                    string={_.toLower(
                                      _.replace(customer.motive, "_", " ")
                                    )}
                                  />
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer?.deposited_currency}
                                  </span>{" "}
                                  {customer?.deposited_amount}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer?.transaction_currency}
                                  </span>{" "}
                                  {customer?.transaction_amount}
                                </td>
                                <td>{customer?.bank_reference}</td>
                                <td>{customer?.user_reference}</td>
                                <td>{customer?.on_behalf_of || "-"}</td>
                                <td>
                                  {customer.statusUpdatedBy ? (
                                    <>
                                      {customer?.statusUpdatedBy.name}
                                      <br></br>
                                      {customer?.statusUpdatedBy.name
                                        ? "(" +
                                          customer?.statusUpdatedBy?.mobile +
                                          ")"
                                        : ""}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {customerWalletRequest.length === 0 && (
                              <tr>
                                <td colSpan={13}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={13}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="content container-fluid absolute px-2">
                <footer className="text-center text-lg-start">
                  <div className="text-center pb-4">
                    Powered by Alsaffron System.
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MerchantWalletLogHistory
        isOpen={showLogHistory}
        toggle={handleShowLogHistory}
        logHistory={logHistoryId}
      />
    </>
  );
};

export default MerchantWalletRequest;
