import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GppBadIcon from "@mui/icons-material/GppBad";
import {
  fileUploadToBucket,
  getUploadedList,
} from "../../../Services/Api/fileUpload";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
  TextArea,
} from "../../../Utils/SweetAlert";
import format from "date-fns/format";
import { ApproveRejectDocumentService } from "../../../Services/Api/customer";
import { DeleteFile } from "../../../Services/Api/master";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { useDebounce } from "use-debounce";
import { checkExpiration } from "../../../Utils/CommanFunctions";
import { documentType } from "../../../Utils/CommanData";
import { Helmet } from "react-helmet-async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";
// import { Pagination } from "../../../Utils";
// import { GetMerchantPaginate} from "../../../Services/Api/merchant";

const MerchantLegalDocument = () => {
  const [frontImageKey, setfrontImageKey] = useState(0);
  const [debounceKey] = useDebounce(frontImageKey, 1000);
  const stateData = useLocation();
  const { id, merchant_name } = stateData?.state || {};
  const [documentList, setDocumentList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [vat, setVat] = useState("");
  const [downloading, setDownloading] = useState(false);
  // const [totalRecords, setTotalRecords] = useState(0);
  // const [limit, setLimit] = useState(10);
  // const [page, setPage] = useState(0);
  // const [merchantListPagination, setMerchantListPagination] = useState([]);
  const legalInfoSchema = Yup.object().shape({
    date: Yup.date()
      .min(new Date(), "Expiry date should not be later than tomorrow.")
      .required("Please select date"),
    documentType: Yup.object()
      .nullable(true)
      .required("Please select document type"),
  });

  const basicInitialValues = {
    frontImage: "",
    backImage: "",
    date: "",
    documentType: null,
  };
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];

  const getUploadedDocumentList = async () => {
    setIsLoader(true);
    await getUploadedList(id)
      .then(async (res) => {
        setDocumentList(res.data.data.documents);
        // setTotalRecords(res?.data?.data?.totalRecords);
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        // setTotalRecords(0);
        setDocumentList([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  // const getMerchantList = async (limitO = "", pageO = "") => {
  //   setIsLoader(true);
  //   await GetMerchantPaginate({ limit: limitO, page: pageO })
  //     .then((res) => {
  //       setIsLoader(false);
  //       if (res?.data?.data?.merchants.length > 0) {
  //         setMerchantListPagination(res?.data?.data?.merchants);
  //         setTotalRecords(res?.data?.data?.totalRecords);
  //       } else {
  //         ErrorToast("No Results.");
  //         setMerchantListPagination([]);
  //         setTotalRecords(0);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoader(false);
  //       setMerchantListPagination([]);
  //       setTotalRecords(0);
  //       if (typeof err.response.data.message !== "undefined") {
  //         ErrorToast(err.response.data.message || "Server Error!!");
  //       } else {
  //         ErrorToast(err?.message || "Server Error!!");
  //       }
  //     });
  // };

  // const handlePageChange = async (e, pageNew) => {
  //   setPage(pageNew);
  //   await getMerchantList(limit, pageNew);
  // };
  // const handleLimitChange = async (event) => {
  //   setLimit(parseInt(event.target.value, 10));
  //   setPage(0);
  //   await getMerchantList(parseInt(event.target.value, 10), 0);
  // };

  const ApproveDocument = async (fileId) => {
    Confirmation("Want to approve document?").then(async (confirm) => {
      if (confirm.isConfirmed) {
        const approveData = {
          file_id: fileId,
          status: 1,
          reject_reason: "",
        };
        await ApproveRejectDocumentService(approveData)
          .then(async (res) => {
            SuccessToast(res?.data?.message || "Document approved.");
            getUploadedDocumentList();
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  const RejectDocument = async (fileId) => {
    TextArea("Approve document ?").then(async (confirm) => {
      if (confirm.isConfirmed) {
        const approveData = {
          file_id: fileId,
          status: 2,
          reject_reason: confirm.value,
        };
        await ApproveRejectDocumentService(approveData)
          .then(async (res) => {
            SuccessToast(res?.data?.message || "Document approved.");
            getUploadedDocumentList();
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  const deleteDocument = (deleteFileId) => {
    Confirmation("Want to delete document?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteFile(deleteFileId)
          .then((res) => {
            SuccessToast(res?.data?.message || "Merchant Deleted.");
            getUploadedDocumentList();
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  const downloadDocuments = async () => {
    setDownloading(true);
    await getUploadedList(id)
      .then(async (response) => {
        const documents = response.data.data.documents;
        const zip = new JSZip();
        let count = 0;
        const zipFilename = `documents_${id}.zip`;
        documents.forEach(async function (document) {
          const urlArr = document.media_url.split("/");
          const orgFilename = urlArr[urlArr.length - 1];
          const filename = orgFilename.split("?");
          const newFileName = filename[0];
          try {
            const file = await JSZipUtils.getBinaryContent(document.media_url);
            zip.file(newFileName, file, { binary: true });
            count++;
            if (count === documents.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, zipFilename);
                setDownloading(false);
              });
            }
          } catch (err) {
            // setDownloading(false);
          }
        });
      })
      .catch((err) => {
        setDownloading(false);
      });
  };

  useEffect(() => {
    getUploadedDocumentList();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Merchant Legal Document</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title"> {(merchant_name) ? `Legal Documents For `+merchant_name : `Legal Documents`} </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Legal Documents</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Legal Documents</h5>
                  <p>Let's verify your self...</p>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={basicInitialValues}
                    validationSchema={legalInfoSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      if (values?.frontImage?.name == null) {
                        ErrorToast("Please upload front side image.");
                        return false;
                      }
                      if (
                        vat != "VAT_CERTIFICATE" &&
                        values?.backImage?.name == null &&
                        vat != "COMPANY_LICENSE" &&
                        values?.backImage?.name == null
                      ) {
                        ErrorToast("Please upload back side image.");
                        return false;
                      }
                      const frontPostData = {
                        file_name: values.frontImage.name,
                        file_module: values.documentType.value,
                        mime_type: values.frontImage.type,
                        document_sides: "FRONT",
                        user_id: id,
                        expiry_date: moment(values.date).format("YYYY-MM-DD"),
                      };
                      const backPostData = {
                        file_name: values.backImage?.name,
                        file_module: values.documentType.value,
                        mime_type: values.backImage?.type,
                        document_sides: "BACK",
                        user_id: id,
                        expiry_date: moment(values.date).format("YYYY-MM-DD"),
                      };
                      await fileUploadToBucket({
                        data: frontPostData,
                        file: values.frontImage,
                      })
                        .then(async (res) => {
                          if (
                            vat == "VAT_CERTIFICATE" ||
                            vat == "COMPANY_LICENSE"
                          ) {
                            setfrontImageKey(Math.random());
                            SuccessToast(
                              res?.data?.message ||
                                "Your ducument uploaded successfully."
                            );
                            getUploadedDocumentList();
                            setSubmitting(false);
                            resetForm();
                          } else {
                            await fileUploadToBucket({
                              data: backPostData,
                              file: values.backImage,
                            })
                              .then(async (res) => {
                                setfrontImageKey(Math.random());
                                SuccessToast(
                                  res?.data?.message ||
                                    "Your ducument uploaded successfully."
                                );
                                getUploadedDocumentList();
                                setSubmitting(false);
                                resetForm();
                              })
                              .catch((err) => {
                                if (
                                  typeof err.response.data.message !==
                                  "undefined"
                                ) {
                                  ErrorToast(
                                    err.response.data.message ||
                                      "Server Error!!"
                                  );
                                } else {
                                  ErrorToast(err?.message || "Server Error!!");
                                }
                                setSubmitting(false);
                              });
                          }
                        })
                        .catch((err) => {
                          // if (typeof err.response.data.message !== "undefined") {
                          //   ErrorToast(err.response.data.message || "Server Error!!");
                          // } else {
                          //   ErrorToast(err?.message || "Server Error!!");
                          // }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      setFieldValue,
                      values,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          <div className="form-row row">
                            <div className="col-md-6 mb-3">
                              <div className="form-group">
                                <label>
                                  Select Document Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={documentType()}
                                  name="documentType"
                                  onChange={(newVal) => {
                                    setFieldValue("documentType", newVal);
                                    setVat(newVal.value);
                                  }}
                                  value={values.documentType}
                                  placeholder="Select document type"
                                />
                                <ErrorMessage
                                  name="documentType"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <div className="form-group">
                                <label>
                                  Expiry Date{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                {/* <Field
                                  type="date"
                                  min={format(new Date(), "yyyy-MM-dd")}
                                  className={`form-control ${errors.date && touched.date
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="date"
                                  value={values.date}
                                /> */}
                                <DatePicker
                                  autoComplete="off"
                                  className={`form-control ${
                                    errors.date && touched.date
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="date"
                                  onChange={(date) =>
                                    setFieldValue("date", date)
                                  }
                                  selected={values.date}
                                  minDate={new Date()}
                                  placeholderText="mm-dd-yyyy"
                                  // value={moment(values.date).format("YYYY-MM-DD")}
                                />
                                <ErrorMessage
                                  name="date"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row row">
                            <div className="col-md-6 mb-3">
                              <div className="form-group dropzone-input-area">
                                <label>
                                  Upload Front Side Image{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DragAndDropFile
                                  randerKey={debounceKey}
                                  maxFileSize={2000000} //2mb
                                  label="Drag and drop a image or click"
                                  fileTypes={[
                                    "image/*",
                                    ".pdf",
                                    ".docx",
                                    ".doc",
                                    ".pptx",
                                  ]}
                                  className={`form-control ${
                                    errors.frontImage && touched.frontImage
                                      ? "input-error"
                                      : null
                                  }`}
                                  handleChange={(file) => {
                                    setFieldValue("frontImage", file[0]);
                                  }}
                                  acceptedFiles={fileTypes}
                                />
                                <ErrorMessage
                                  name="frontImage"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {vat != "VAT_CERTIFICATE" &&
                            vat != "COMPANY_LICENSE" ? (
                              <div className="col-md-6 mb-3">
                                <div className="form-group dropzone-input-area">
                                  <label>
                                    Upload Back Side Image{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <DragAndDropFile
                                    randerKey={debounceKey}
                                    maxFileSize={2000000} //2mb
                                    label="Drag and drop a image or click"
                                    fileTypes={[
                                      "image/*",
                                      ".pdf",
                                      ".docx",
                                      ".doc",
                                      ".pptx",
                                    ]}
                                    className={`form-control ${
                                      errors.backImage && touched.backImage
                                        ? "input-error"
                                        : null
                                    }`}
                                    handleChange={(file) => {
                                      setFieldValue("backImage", file[0]);
                                    }}
                                    acceptedFiles={fileTypes}
                                  />
                                  <ErrorMessage
                                    name="backImage"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Submit&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
              <div className="card card-table">
                <div className="card-header">
                  <div className="row">
                    <div className="col-8">
                      <h5 className="card-title">Legal Documents List</h5>
                      <p>Let's verify your self...</p>
                    </div>
                    <div className="col-4">
                      {documentList.length > 0 && (
                        <button
                          type="button"
                          className="btn btn-primary float-end"
                          onClick={downloadDocuments}
                          disabled={downloading}
                        >
                          {downloading ? "Downloading..." : "Download All"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive avtart-border">
                    <table className="table table-stripped table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th>Document Type</th>
                          <th>Status</th>
                          <th className="text-right">Action</th>
                          <th>Image</th>
                          <th>Image Side </th>
                          <th>Date</th>
                          <th>Expire Date</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentList.map((document) => (
                          <tr
                            className={
                              checkExpiration(document.expiry_date)
                                ? "red-tr"
                                : ""
                            }
                            key={document.id}
                          >
                            <td>{document.file_module.replace("_", " ")}</td>
                            <td>
                              {(() => {
                                if (checkExpiration(document.expiry_date)) {
                                  return (
                                    <span className="badge bg-warning-light">
                                      Expired
                                    </span>
                                  );
                                }
                                if (document.status === 0) {
                                  return (
                                    <span className="badge bg-primary-light">
                                      Pending
                                    </span>
                                  );
                                }
                                if (document.status === 1) {
                                  return (
                                    <span className="badge bg-success-light">
                                      Approved
                                    </span>
                                  );
                                }
                                if (document.status === 2) {
                                  return (
                                    <span className="badge bg-danger-light">
                                      Rejected
                                    </span>
                                  );
                                }
                              })()}
                            </td>
                            <td className="text-right">
                              {document.status === 0 && (
                                <>
                                  <Tooltip
                                    title="Approve"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      ApproveDocument(document._id)
                                    }
                                  >
                                    <IconButton>
                                      <AssignmentTurnedInIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Reject"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() => RejectDocument(document._id)}
                                  >
                                    <IconButton>
                                      <GppBadIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                              <Tooltip
                                title="Delete"
                                className="btn btn-sm btn-white text-danger me-2"
                                onClick={() => deleteDocument(document._id)}
                              >
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </td>
                            <td>
                              {document.mime_type.slice(0, 5) === "image" && (
                                <Gallery>
                                  <Item
                                    className={
                                      "avatar avatar-sm me-2 avatar-img"
                                    }
                                    src={document.media_url}
                                    alt={document.id}
                                    width="1024"
                                    height="768"
                                  >
                                    {({ ref, open }) => (
                                      <img
                                        ref={ref}
                                        onClick={open}
                                        src={document.media_url}
                                        alt={document.id}
                                      />
                                    )}
                                  </Item>
                                </Gallery>
                              )}
                              {document.mime_type === "application/pdf" && (
                                <>
                                  <a href={document.media_url} target="blank">
                                    <img
                                      href={document.media_url}
                                      target="_blank"
                                      className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                      src="/inner-assets/img/pdf-icon.png"
                                      alt="pdf uplodaed"
                                    />
                                  </a>
                                </>
                              )}
                              {document.mime_type ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                                <>
                                  <a href={document.media_url} target="blank">
                                    <img
                                      href={document.media_url}
                                      target="_blank"
                                      className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                      src="/inner-assets/img/word-icon.png"
                                      alt={"wordfile uploded"}
                                    />
                                  </a>
                                </>
                              )}
                              {document.mime_type ===
                                "application/vnd.openxmlformats-officedocument.presentationml.presentation" && (
                                <a href={document.media_url} target="blank">
                                  <img
                                    href={document.media_url}
                                    target="_blank"
                                    className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                    src="/inner-assets/img/ppt-icon.png"
                                    alt={"ppt uploded"}
                                  />
                                </a>
                              )}
                            </td>
                            <td>{document.document_sides}</td>
                            <td>
                              {format(
                                new Date(document.createdAt),
                                "dd MMMM, yyyy"
                              )}
                            </td>
                            <td>
                              {format(
                                new Date(document.expiry_date),
                                "dd MMMM, yyyy"
                              )}
                            </td>
                            <td>{document.reject_reason || "-"}</td>
                          </tr>
                        ))}
                        {documentList.length === 0 && (
                          <tr>
                            <td align="left" colSpan={8}>
                              No Records Found.
                            </td>
                          </tr>
                        )}
                        {isLoader && (
                          <tr>
                            <td align="center" colSpan={8}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        {/* <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={8}
                        /> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="content container-fluid absolute px-2">
              <footer className="text-center text-lg-start">
                <div className="text-center pb-4">
                  Powered by Alsaffron System.
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantLegalDocument;
