import React, { useState, useEffect } from "react";
import format from "date-fns/format";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Pagination } from "../../../Utils";
import { GetContactUsPaginate } from "../../../Services/Api/contactUs";

const ListContactUs = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [contactUs, setContactUs] = useState([]);

  const GetContactUs = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetContactUsPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setContactUs(res?.data?.data?.contactUs);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setContactUs([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setContactUs([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetContactUs(limit, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetContactUs(parseInt(event.target.value, 10), 0);
  };

  useEffect(() => {
    GetContactUs(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin - Contact Us</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Contact Us</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>User Role</th>
                          <th>User Name</th>
                          <th>User Email</th>
                          <th>Subject</th>
                          <th>Created On</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {contactUs.map((contact_us, index) => (
                              <tr key={`admin-contactus-list-${index}`}>
                                <td>{contact_us?.user_role || "-"}</td>
                                <td>{contact_us?.user?.name || "-"}</td>
                                <td>{contact_us?.email || "-"}</td>
                                <td>{contact_us?.subject || "-"}</td>
                                <td>
                                  {format(
                                    new Date(contact_us?.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                                <td>
                                  <div className="dropdown dropdown-action">
                                    {/* <Link
                                      className="dropdown-item"
                                      to="/admin/contact-us/view"
                                      state={{
                                        contact_us,
                                      }}
                                    > */}
                                      <i className="far fa-eye me-2" />
                                      View
                                    {/* </Link> */}
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {contactUs.length === 0 && (
                              <tr>
                                <td colSpan={6}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}

                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={6}>
                              Loading...
                            </td>
                          </tr>
                        )}

                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListContactUs;
