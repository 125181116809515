import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FilterComponentBooking } from "../Common";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import { ErrorToast, BookingRefundPopup, SuccessToast } from "../../../Utils/SweetAlert";
import {
  GetCpayReceiptservice,
  getCustomerBookingGenerateReport,
  GetCustomerBookingPaginate,
  GetWidgetCountBookingService,
  refundBookingAmountAPI
} from "../../../Services/Api/booking";
import format from "date-fns/format";
import { StartCase } from "react-lodash";
import { saveAs } from "file-saver";
import NeedInvoiceIcon from "../Common/NeedInvoiceIcon";
import { onImageError, showCustName } from "../../../Utils/CommanFunctions";

const CustomerBookingList = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customerBookingList, setCustomerBookingList] = useState([]);
  const [currency, setCurrency] = useState();
  const [widgetData, setWidgetData] = useState();
  const navigate = useNavigate();
  const [resetDate, setResetDate] = useState(0);

  const handleReset = async () => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
  };
  const handleGenerateReport = async ({ fDate, fStatus, fCustomer }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_customer: fCustomer || "",
    };
    await getCustomerBookingGenerateReport(postData)
      .then((res) => {
        handleReset();
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        handleReset();
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const getWidgetData = async () => {
    GetWidgetCountBookingService()
      .then((res) => {
        setWidgetData(res?.data?.data?.booking);
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };

  const getCustomerBookingList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetCustomerBookingPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerBookingList(res?.data?.data?.bookings);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          // ErrorToast("No Results.");
          setCustomerBookingList([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setCustomerBookingList([]);
        setTotalRecords(0);
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getCustomerBookingList(limit, pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getCustomerBookingList(parseInt(event.target.value, 10), 0);
  };

  useEffect(() => {
    getWidgetData();
    const item = JSON.parse(localStorage.getItem("clientUserData"));
    setCurrency(item?.primary_currency);
  }, []);

  useEffect(() => {
    getCustomerBookingList(limit, page);
  }, [limit, page]);

  const refundBookingAmount = (booking_id, booking_amount) => {
    BookingRefundPopup({ denyButtonText: "Cancel", label: 'Refund Booking Amount', booking_amount }).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          booking_id: booking_id,
          amount: result.value.amount,
          notes: result.value.notes,
        }
        await refundBookingAmountAPI(postObj)
          .then((res) => {
            SuccessToast(res?.data?.message || "Booking refund successfully.");
            getCustomerBookingList(limit, page);
            getWidgetData();
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const handleCpayReceipt = async (id) => {
    await GetCpayReceiptservice(id)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>CPay | Admin Booking List</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Bookings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Bookings</li>
                </ul>
              </div>
            </div>
          </div>
          {/* Common component import here */}
          <FilterComponentBooking
            handleGenerateReport={handleGenerateReport}
            resetDate={resetDate}
          />
          <div className="card invoices-tabs-card">
            <div className="card-body card-body pt-0 pb-0">
              <div className="invoices-main-tabs">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-8">
                    <div className="invoices-tabs">
                      <ul>
                        <li>
                          <Link
                            to="/admin/customer-booking-list"
                            className="active"
                          >
                            All Bookings
                          </Link>
                        </li>
                        <li>
                          <Link to="/admin/booking-paid">Paid</Link>
                        </li>
                        <li>
                          <Link to="/admin/booking-failed">Failed</Link>
                        </li>
                        <li>
                          <Link to="/admin/booking-cancelled">Cancelled</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon1.svg"
                        alt="icon"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">AED </span>
                        {widgetData?.all_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    All Bookings <span>{widgetData?.all_bookings_count} </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon2.svg"
                        alt="icon"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">AED </span>
                        {widgetData?.paid_bookings_amount}{" "}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Paid Bookings{" "}
                    <span>{widgetData?.paid_bookings_count} </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon3.svg"
                        alt="icon"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">AED </span>
                        {widgetData?.failed_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Failed Bookings{" "}
                    <span>{widgetData?.failed_bookings_count} </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon4.svg"
                        alt="icon"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">AED </span>
                        {widgetData?.cancelled_bookings_amount}{" "}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Cancelled Bookings{" "}
                    <span>{widgetData?.cancelled_bookings_count} </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Booking ID</th>
                          <th>PO No.</th>
                          <th>Status</th>
                          <th>Refund</th>
                          <th className="text-end">Action</th>
                          <th>Category</th>
                          <th>Pax Details</th>
                          <th>Booking Details</th>
                          <th>Created On</th>
                          <th>Customer</th>
                          <th>Merchant</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerBookingList.map((booking, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <label className="custom_check">
                                      <input type="checkbox" name="Booking" />
                                      <span className="checkmark" />
                                  </label> */}
                                  <span
                                    className="invoice-link"
                                    onClick={() =>
                                      navigate("/admin/view-booking", {
                                        state: {
                                          id: booking?.booking_reference,
                                        },
                                      })
                                    }
                                  >
                                    {booking?.booking_reference}
                                  </span>
                                </td>
                                <td>
                                  <span className="invoice-link" onClick={() =>
                                      navigate("/admin/view-booking", {
                                        state: { id: booking?.booking_reference },
                                        })}>
                                    {booking?.order?.order_reference}
                                  </span>
                                  {!booking?.merchant_invoice && <NeedInvoiceIcon />}
                                </td>
                                <td>
                                  {booking?.update_status === 2 ? <span className="badge bg-success-light">
                                      Refunded
                                    </span> : (
                                      <>
                                        {booking?.status === 0 && (
                                          <span className="badge bg-warning-light">
                                            Pending
                                          </span>
                                        )}
                                        {booking?.status === 1 && (
                                          <span className="badge bg-success-light">
                                            Success
                                          </span>
                                        )}
                                        {booking?.status === 2 && (
                                          <span className="badge bg-danger-light">
                                            Failed
                                          </span>
                                        )}
                                        {booking?.status === 3 && (
                                          <span className="badge bg-danger-light">
                                            Cancelled
                                          </span>
                                        )}
                                      </>
                                    ) }
                                </td>
                                <td>
                                  {booking?.update_status === 0 && (
                                    <span className="inovices-dash-count inovices-amount currency-type-text">
                                      <button
                                        type="button"
                                        className="btn btn-block btn-outline-success btn-reposrt mx-0 px-2"
                                        onClick={() => refundBookingAmount(booking._id, booking?.customer_payment_amount)}
                                      >Refund</button>
                                    </span>
                                  )}
                                </td>
                                <td className="text-end">
                                  <div className="dropdown dropdown-action">
                                    <Link
                                      to="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      {/* <Link className="dropdown-item" to="#"><i className="far fa-edit me-2" />Edit</Link> */}
                                      <Link
                                        className="dropdown-item"
                                        to="/admin/view-booking"
                                        state={{
                                          id: booking?.booking_reference,
                                        }}
                                      >
                                        <i className="far fa-eye me-2" />
                                        View
                                      </Link>
                                      {booking?.merchant_invoice ? (
                                        <a
                                          className="dropdown-item"
                                          href={booking?.merchant_invoice}
                                          target="_blank" rel="noreferrer"
                                        >
                                          <i className="far fa-eye me-2" />
                                          Receipt by Merchant
                                        </a>
                                      ) : (
                                        <span className="dropdown-item text-wrap cursor-pointer">
                                          <i className="far fa-eye me-2" />
                                          Pending receipt by Merchant
                                        </span>
                                      )}
                                      <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() =>
                                          handleCpayReceipt(booking?._id)
                                        }
                                      >
                                        <i className="far fa-eye me-2" />
                                        Receipt by CPAY
                                      </span>
                                      {/* <Link className="dropdown-item" to="#"><i className="far fa-trash-alt me-2" />Delete</Link> */}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <StartCase string={booking?.product} />
                                </td>
                                <td>
                                  {booking?.product === "flight" && (
                                    <>
                                      {booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                            <span>
                                              ({traveller?.passenger_type})
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "hotel" && (
                                    <>
                                      {booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "visa" && (
                                    <div>
                                      {booking?.metadata?.visa?.first_name}{" "}
                                      {booking?.metadata?.visa?.last_name}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {booking?.product === "flight" && (
                                    <>
                                      <div>
                                        <b>Flight Type: </b>
                                        {booking?.metadata?.flights?.type.replace(/_/g, " ").toUpperCase()+' FLIGHT'}{" "}
                                      </div>
                                      {booking?.metadata?.flights?.flight_itinerary.map(
                                        (flight, index) => (
                                          <div>
                                            <b>Flight Name:</b> {flight?.flight_name}{" "}
                                            <br />
                                            <b>Flight Number:</b> {flight?.flight_number}{" "}
                                            <br />
                                            <b>Departure City:</b> {flight?.departure_city}{" "}
                                            <br />
                                            <b>Departure DateTime:</b> {flight?.departure_datetime}{" "}
                                            <br />
                                            <b>Arrival City:</b> {flight?.arrival_city}{" "}
                                            <br />
                                            <b>Arrival DateTime:</b> {flight?.arrival_datetime}{" "}
                                            <br />
                                            <b>Duration:</b> {flight?.duration}{" "}
                                            <br />
                                            <b>Stop:</b> {flight?.stop}{" "}
                                            <br />
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "hotel" && (
                                    <div>
                                      <b>Hotel Name:</b> {booking?.metadata?.hotel?.name} {" "}
                                      <br />
                                      <b>Hotel Address:</b> {booking?.metadata?.hotel?.address} {" "}
                                      <br />
                                      <b>Hotel Phone:</b> {booking?.metadata?.hotel?.contact_number} {" "}
                                      <br />
                                    </div>
                                  )}
                                  {booking?.product === "visa" && (
                                    <div>
                                      <b>Visa Type:</b> {booking?.metadata?.visa?.visa_type} {" "}
                                      <br />
                                      <b>Source Type:</b> {booking?.metadata?.visa?.source_type} {" "}
                                      <br />
                                      <b>Present Nationality:</b> {booking?.metadata?.visa?.present_nationality} {" "}
                                      <br />
                                      <b>Passport Number:</b> {booking?.metadata?.visa?.passport_number} {" "}
                                      <br />
                                      <b>First Name:</b> {booking?.metadata?.visa?.first_name} {" "}
                                      <br />
                                      <b>Last Name:</b> {booking?.metadata?.visa?.last_name} {" "}
                                      <br />
                                      <b>Gender:</b> {booking?.metadata?.visa?.gender} {" "}
                                      <br />
                                      <b>Marital Status:</b> {booking?.metadata?.visa?.marital_status} {" "}
                                      <br />
                                      <b>Birth Date:</b> {booking?.metadata?.visa?.birth_date} {" "}
                                      <br />
                                      <b>Birth Country:</b> {booking?.metadata?.visa?.birth_country} {" "}
                                      <br />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <span>
                                    {" "}
                                    {format(
                                      new Date(booking?.createdAt),
                                      "dd MMMM, yyyy hh:mm:ss a"
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    <img
                                      className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                      src={booking?.customer?.profile_picture || ""}
                                      alt="User"
                                      onError={onImageError}
                                    />{" "}
                                    {showCustName(booking?.customer?.name, booking?.customer?.company_name)}
                                  </h2>
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    <img
                                      className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                      src={booking?.merchant?.profile_picture || ""}
                                      alt="Merchant"
                                      onError={onImageError}
                                    />
                                    {booking?.merchant?.name}
                                  </h2>
                                </td>
                                <td className="text-primary">
                                  <span className="currency-type-text">
                                    AED{" "}
                                  </span>
                                  {booking?.customer_payment_amount}
                                </td>
                              </tr>
                            ))}
                            {customerBookingList.length === 0 && (
                              <tr>
                                <td align="left" colSpan={7}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                            {isLoading && (
                              <tr>
                                <td align="center" colSpan={7}>
                                  Loading...
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={7}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerBookingList;