import { useState, useEffect } from "react";

export const DailyPenalty = ({startDate, amount}) => {
    var endDate = new Date();
    var difference = endDate.getTime() - startDate.getTime();
    var TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    return TotalDays > 0 ? (TotalDays * amount) : 0;
};

export const WeeklyPenalty = ({startDate, amount}) => {
    let [totalWeeks, setTotalWeeks] = useState(0);

    useEffect(() => {
        var endDate = new Date();
        for (var i = startDate; i <= endDate; i.setDate(i.getDate()+1)) {
            // Sunday - Saturday : 0 - 6
            if (i.getDay() === 6) {
                setTotalWeeks(prevTotalWeeks => prevTotalWeeks + 1);
            }
        }
    }, []);
    
    return totalWeeks * amount;
};

export const FortNightPenalty = ({startDate, amount}) => {
    var endDate = new Date();
    var difference = endDate.getTime() - startDate.getTime();
    var TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (TotalDays > 0) {
        var fortNightly = Math.trunc(TotalDays / 15)
        return fortNightly * amount;
    } else {
        return 0;
    }
};