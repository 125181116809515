import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
// import { GetCRPenaltySetting } from "../../../Services/Api/master";
import { AddServiceChargeService } from "../../../Services/Api/customer";
import { Helmet } from "react-helmet-async";
import ReactSelect from "react-select";
import { GetMerchantPaginate } from "../../../Services/Api/merchant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";
import {
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));
const AddServiceCharge = () => {
  const stateData = useLocation();
  const history = useNavigate();
  const { customerid, currency } = stateData?.state || {};
  const serviceCharge = [
    { label: "Percentage", value: "percentage" },
    { label: "Amount", value: "amount" },
  ];
  const [merchantListPagination, setMerchantListPagination] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [user, setUser] = useState([]);
  const [status, setStatus] = useState([]);
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    merchantListPagination.length > 0 &&
    selected.length === merchantListPagination.length;

  const getMerchantList = async () => {
    // setIsLoading(true);
    await GetMerchantPaginate({ limit: 10, page: 0, paginate: false })
      .then((res) => {
        // setIsLoading(false);
        if (res?.data?.data?.merchants.length > 0) {
          let merchatArr = res?.data?.data?.merchants;
          merchatArr = merchatArr.map((value) => {
            return {
              label: value.name,
              value: value.id,
            };
          });
          setMerchantListPagination(merchatArr);
        } else {
          // ErrorToast("No Results.");
          setMerchantListPagination([]);
        }
      })
      .catch((err) => {
        // setIsLoading(false);
        setMerchantListPagination([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const initData = {
    merchants: "",
    service_charge: "",
    type: "",
    start_date: "",
  };
  //   let dfltFormData = { cr_due_days: "", cr_penalty_start_days: "", service_charge: "", cr_penalty_period: "" };
  //   const [initData, setInitData] = useState(dfltFormData);
  //   const getCRSettingData = async () => {
  //     await GetCRPenaltySetting().then((res) => {
  //       const dfltDataCRP = res?.data?.data?.creditReqPenalty || null;
  //       setInitData({ cr_due_days: dfltDataCRP.cr_due_days, cr_penalty_start_days: dfltDataCRP.cr_penalty_start_days, service_charge: dfltDataCRP.service_charge, cr_penalty_period: dfltDataCRP.cr_penalty_period });
  //     }).catch((err) => {
  //       // if (typeof err.response.data.message !== 'undefined') {
  //       //   ErrorToast(err.response.data.message || 'Server Error!!');
  //       // } else {
  //       //   ErrorToast(err?.message || 'Server Error!!');
  //       // }
  //     })
  //   };

  //   const handleChangeUser = (event) => {
  //     setSelectedOption(event.target.value)
  //     const {
  //         target: { value },
  //     } = event;
  //     setUser(
  //         typeof value === 'string' ? value.split(',') : value,
  //     );
  //     setSelectedOption(
  //         typeof value === 'string' ? value.split(',') : value,
  //     );
  // };
  const handleChangeUser = (event) => {
    if (
      event.target.value.includes("all") &&
      user.length !== merchantListPagination.length
    ) {
      setUser(merchantListPagination.map((name) => name.value));
    } else if (event.target.value.includes("all")) {
      setUser([]);
    } else {
      setUser(event.target.value);
    }
  };
  const user1 = user.map((u) => u).join(",");

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === "string" ? value.split(",") : value);
  };
  const status1 = status.map((u) => u).join(",");

  useEffect(() => {
    // getCRSettingData();
    getMerchantList();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Add Service Charge</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add Service charge</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/customer">Customers</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/admin/service-charge"
                      state={{ customerid: customerid, currency }}
                    >
                      Service Charge
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Add Service charge</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/service-charge"
                  state={{ customerid: customerid, currency }}
                  className="btn btn-primary "
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Add Service charge</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initData}
                    validationSchema={Yup.object().shape({
                      merchants: Yup.array().required("Please select merchant"),
                      type: Yup.string()
                        .nullable()
                        .required("Please select service charge type"),
                      service_charge: Yup.number()
                        .positive("Must be greater then 0")
                        .nullable(true)
                        .moreThan(
                          -1,
                          "Service charge should not be less than zero"
                        )
                        .required("Service charge is required")
                        .typeError("Please enter number only"),
                      start_date: Yup.date().required("Please select date"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      const data = {
                        customer_id: customerid,
                        // merchants: values.merchants,
                        merchants: user,
                        type: values.type,
                        service_charge: values.service_charge,
                        start_date: format(
                          new Date(values.start_date),
                          "yyyy-MM-dd"
                        ),
                      };
                      await AddServiceChargeService(data)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "Service charge add successfully."
                          );
                          setSubmitting(false);
                          history("/admin/service-charge", {
                            state: { customerid: customerid, currency },
                          });
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group forstyle">
                                <label htmlFor="merchants" className="pb-1">
                                  Choose Merchant{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                {/* <Select
                                  options={merchantListPagination}
                                  onChange={(e) => {
                                    setFieldValue("merchants", e.value);
                                    setFieldValue("id", e.value);
                                  }}
                                  name="merchants"
                                  placeholder="Select merchant"
                                /> */}
                                <FormControl sx={{ width: "100%" }}>
                                  <Select
                                    labelId="merchants"
                                    id="merchants"
                                    placeholder="Select merchant"
                                    multiple
                                    value={user}
                                    name="merchants"
                                    options={selectedOption}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "merchants",
                                        e.target.value
                                      );
                                      handleChangeUser(e);
                                    }}
                                    renderValue={(selected) =>
                                      merchantListPagination
                                        .filter((customer) =>
                                          selected.includes(customer.value)
                                        )
                                        .map((record) => record.label)
                                        .join(", ")
                                    }
                                    MenuProps={MenuProps}
                                  >
                                    <MenuItem
                                      value="all"
                                      classes={{
                                        root: isAllSelected
                                          ? classes.selectedAll
                                          : "",
                                      }}
                                    >
                                      <ListItemIcon>
                                        <Checkbox
                                          classes={{
                                            indeterminate:
                                              classes.indeterminateColor,
                                          }}
                                          checked={isAllSelected}
                                          indeterminate={
                                            user.length > 0 &&
                                            user.length <
                                              merchantListPagination.length
                                          }
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        classes={{
                                          primary: classes.selectAllText,
                                        }}
                                        primary="Select All"
                                      />
                                    </MenuItem>
                                    {merchantListPagination.map(
                                      (name, index) => (
                                        <MenuItem
                                          key={index}
                                          value={name.value}
                                        >
                                          <Checkbox
                                            checked={
                                              user.indexOf(name.value) > -1
                                            }
                                          />
                                          <ListItemText primary={name.label} />
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                                <ErrorMessage
                                  name="merchants"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-dark">
                                  Start Date{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  className={`form-control  ${
                                    errors.start_date && touched.start_date
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="start_date"
                                  autoComplete="off"
                                  onChange={(date) =>
                                    setFieldValue("start_date", date)
                                  }
                                  selected={values.start_date}
                                  placeholderText="dd-mm-yyyy"
                                  dateFormat="dd-MM-yyyy"
                                  minDate={new Date()}
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="">
                                  Service Charge Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <ReactSelect
                                  className=" h-reactselect"
                                  value={serviceCharge.filter(
                                    (option) => option.value === values.type
                                  )}
                                  options={serviceCharge}
                                  onChange={(e) => {
                                    setFieldValue("type", e.value);
                                  }}
                                  name="type"
                                  placeholder="Select service charge type"
                                />
                                {/* <FormControl sx={{ width: '100%' }}>
                                      <Select
                                         labelId="type"
                                         id="type"
                                         value={status}
                                          onChange={(e)=>{
                                             setFieldValue("type",e.target.value)
                                            handleChangeStatus(e)
                                          }}
                                          renderValue={(selected) =>
                                            serviceCharge.filter(status => selected.includes(status.value))
                                                          .map(record => record.label)
                                                          .join(", ")
                                                        }
                                        MenuProps={MenuProps} 
                                      >
                                          {serviceCharge.map((name, index) => (
                                              <MenuItem key={index} value={name.value}>
                                                  <ListItemText primary={name.label} />
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </FormControl> */}
                                <ErrorMessage
                                  name="type"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Service Charge</label>
                                <Field
                                  type="number"
                                  placeholder="Enter service charge"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.service_charge &&
                                    touched.service_charge
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="service_charge"
                                  value={values.service_charge}
                                />

                                <ErrorMessage
                                  name="service_charge"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                          </div>
                          {/* 12-07-2022 */}
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Service Charge&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div class="container-fluid absolute">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddServiceCharge;
