import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import Select from "react-select";
import { GetMerchantPaginate } from "../../../Services/Api/merchant";
import { PostCreditRequest } from "../../../Services/Api/master";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

const MerchantCreditRequest = () => {
  const history = useNavigate();
  const [merchantCurrencyName, setMerchantCurrencyName] = useState("");
  const [merchantListPagination, setMerchantListPagination] = useState([]);

  const getMerchantList = async () => {
    await GetMerchantPaginate({ limit: 10, page: 0, paginate: false })
      .then((res) => {
        if (res?.data?.data?.merchants.length > 0) {
          let merchatArr = res?.data?.data?.merchants;
          merchatArr = merchatArr.map((value) => {
            return {
              label: value.name,
              value: value._id,
              currencyValue: value.primary_currency,
            };
          });
          setMerchantListPagination(merchatArr);
        } else {
          // ErrorToast("No Results.");
          setMerchantListPagination([]);
        }
      })
      .catch((err) => {
        setMerchantListPagination([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const signInSchema = Yup.object().shape({
    user_id: Yup.string().nullable().required("Please select merchant"),
    amount: Yup.number()
      .nullable()
      .positive("Must be greater then 0")
      .required("Amount is required")
      .typeError("Enter number only"),
    reason: Yup.string().required("Reason is required"),
    aed_conversion_rate: Yup.number()
      .nullable()
      .positive("Must be greater then 0")
      .required("AED conversion rate is required")
      .typeError("Enter number only"),
  });

  const initialValues = {
    user_id: "",
    amount: "",
    reason: "",
    aed_conversion_rate: "",
  };

  useEffect(() => {
    getMerchantList();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Merchant Credit Request</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Add Request</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Add merchant credit request
                    </li>
                  </ul>
                </div>
                <div className="col-auto">
                  <Link
                    to="/admin/merchant-credit-request"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-angle-double-left" /> Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      validateOnChange
                      onSubmit={async (values, { setSubmitting }) => {
                        const postObj = {
                          user_id: values.user_id,
                          reason: values.reason,
                          currency: merchantCurrencyName,
                          amount: values.amount,
                          aed_conversion_rate: values.aed_conversion_rate,
                        };
                        await PostCreditRequest(postObj)
                          .then((res) => {
                            SuccessToast(
                              res?.data?.message || "Customer Created."
                            );

                            setSubmitting(false);
                            setTimeout(() => {
                              history("/admin/merchant-credit-request");
                            }, 1000);
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                            setSubmitting(false);
                          });
                      }}
                    >
                      {(formik) => {
                        const {
                          touched,
                          errors,
                          setFieldValue,
                          values,
                          handleChange,
                          isSubmitting,
                        } = formik;
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group destination-dropdown">
                                  <label htmlFor="user_id">
                                    Choose Merchant{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={merchantListPagination}
                                    onChange={(e) => {
                                      setFieldValue("user_id", e.value);
                                      setFieldValue(
                                        "Primary_currency",
                                        e.currencyValue
                                      );
                                      setMerchantCurrencyName(e.currencyValue);
                                    }}
                                    name="user_id"
                                    placeholder="Select merchant"
                                  />
                                </div>
                                <ErrorMessage
                                  name="user_id"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    {" "}
                                    Amount{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="inputGroup-sizing-default"
                                      >
                                        {merchantCurrencyName || "-"}
                                      </span>
                                    </div>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.amount && touched.amount
                                          ? "input-error"
                                          : null
                                      }`}
                                      name="amount"
                                      placeholder="Enter amount"
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="amount"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    {" "}
                                    AED Conversion Rate{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      {/* <span className="input-group-text" id="inputGroup-sizing-default">{merchantCurrencyName || "-"}</span> */}
                                    </div>
                                    <Field
                                      type="text"
                                      placeholder="Enter AED conversion rate"
                                      className={`form-control ${
                                        errors.aed_conversion_rate &&
                                        touched.aed_conversion_rate
                                          ? "input-error"
                                          : null
                                      }`}
                                      name="aed_conversion_rate"
                                    />{" "}
                                  </div>
                                  <ErrorMessage
                                    name="aed_conversion_rate"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Reason{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    as="textarea"
                                    rows={1}
                                    cols={1}
                                    type="text"
                                    placeholder="Enter reason"
                                    className={`form-control ${
                                      errors.reason && touched.reason
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="reason"
                                    onChange={handleChange}
                                    value={values.reason}
                                  />
                                </div>
                                <ErrorMessage
                                  name="reason"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="text-end mt-4">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Add Credit Request&nbsp;&nbsp;
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div class="content container-fluid absolute px-2">
              <footer className="text-center text-lg-start">
                <div className="text-center pb-4">
                  Powered by Alsaffron System.
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantCreditRequest;
