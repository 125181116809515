import Api from "../../Utils/Axios";

export const ApiKeyService = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/merchant_api_keys?offset=${page}&limit=${limit}`))
    } catch (error) {
      reject(error)
    }
  })
}

export const StatusApi = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/merchant_api_keys/change_status", data));
    } catch (error) {
      reject(error);
    }
  })
}