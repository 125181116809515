import Api from "../../Utils/Axios";

export const GetCountry = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get('/master/country-state-city/country'));
    } catch (error) {
      reject(error);
    }
  })
}
export const getAllAgreement = async (id = '') => {
  return new Promise(async (resolve, reject) => {
    try {
      let userId = '';
      if (id !== '') {
        userId = `?user_id=${id}`
      }
      resolve(await Api.get(`/master/agreement${userId}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const DeleteFile = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/master/file/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};
/* 12-07-2022 */
export const GetCRPenaltySetting = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get('/admin/credit-request-penalty-setting'));
    } catch (error) {
      reject(error);
    }
  })
}
export const UpdateCRPenaltySetting = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/admin/credit-request-penalty-setting/update-setting', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const getCurrencyList = async () => {
  return [
    { label: "EUR Euro", value: "EUR" },
    { label: "INR Indian Rupee", value: "INR" },
    { label: "USD US Dollar", value: "USD" },
    { label: "AED United Arab Emirates Dirham", value: "AED" },
  ];
}
export const PostCreditRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("master/credit_request/admin/create", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetMerchantCreditRequestPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/credit_request/admin/list?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const CloseMerchantCreditRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/master/credit_request/merchant/close_credit_request`, data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetDepositRequestDetails = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/admin/deposit_request_details?transaction_id=${id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const ApproveRejectCustomerWalletRequest = async (transaction_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/wallet/admin/approve_reject_request', transaction_id));
    } catch (error) {
      reject(error);
    }
  })
}

export const DeleteCustomerWalletRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/master/wallet/admin/delete_customer_request`, data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetPayCreditRequestDetails = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/credit_request/admin/details/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const DeleteCustomerCreditPaymentRequestDetail = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/credit_request/admin/delete_customer_payment_request", data));
    } catch (error) {
      reject(error);
    }
  })
}

export const ApproveRejectCustomerCreditPaymentRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/credit_request/admin/handle_customer_payment_request', data));
    } catch (error) {
      reject(error);
    }
  })
}