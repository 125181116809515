import Api from "../../Utils/Axios";

export const GetContactUsPaginate = async ({limit,page}) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/general/get-contact-us?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}
