import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import {
  EditWalletAmountMerchant, GetMerchantPaginate, GetBankAccountListBySelectedMerchantId,
  getDepositRequestDetailsById
} from "../../../Services/Api/merchant";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import { Helmet } from 'react-helmet-async';
import * as moment from "moment";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { useDebounce } from 'use-debounce';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const motiveOptions = [
  { label: "CASH DEPOSIT", value: "CASH_DEPOSIT" },
  { label: "CHEQUE DEPOSIT", value: "CHEQUE_DEPOSIT" },
  { label: "BANK TRANSFER", value: "BANK_TRANSFER" }
];

const EditWalletAmount = () => {
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [imageKey, setImageKey] = useState(0);
  const [debounceKey] = useDebounce(imageKey, 1000);
  const history = useNavigate();
  const stateData = useLocation();
  const { tid } = stateData?.state || {};
  const [items, setItems] = useState("");
  const [photoID, setPhotoID] = useState("");
  const [name, setName] = useState("");
  const [merchantListPagination, setMerchantListPagination] = useState([]);
  const [bankListPagination, setBankListPagination] = useState([]);
  const [logo, setLogo] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [titleName, setTitleName] = useState("");
  const [beneficiary, setBeneficiary] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [iban, setIban] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [isBankShow, setIsBankShow] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [bankCurrency, setBankCurrency] = useState("");
  const [currency, setCurrency] = useState("");
  const [tranId, setTranId] = useState("");
  const [walletAmountDetails, setWalletAmountDetails] = useState({});

  const getMerchantList = async () => {
    await GetMerchantPaginate({ limit: 10, page: 0, paginate: false })
      .then((res) => {
        if (res?.data?.data?.merchants.length > 0) {
          let merchatArr = res?.data?.data?.merchants;
          merchatArr = merchatArr.map((value) => {
            return {
              label: value.name,
              value: value.id,
              currencyValue: value.primary_currency,
            };
          });
          setMerchantListPagination(merchatArr);

        } else {
          // ErrorToast("No Results.");
          setMerchantListPagination([]);
        }
      })
      .catch((err) => {
        setMerchantListPagination([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const getBankAccountList = async (id) => {
    if (id) {
      await GetBankAccountListBySelectedMerchantId(id)
        .then((res) => {
          if (res?.data?.data?.accounts.length > 0) {
            let bankArr = res?.data?.data?.accounts;
            bankArr = bankArr.map((value) => {
              return {
                label: value.title,
                value: value.id,
                title: value.title,
                currencyValue: value.currency,
                logo: value.logo,
                beneficiary: value.beneficiary,
                accountNumber: value.account_number,
                iban: value.iban,
                swiftCode: value.swift_code,
              };
            });
            setBankListPagination(bankArr);
          } else {
            setWalletAmountDetails({ ...walletAmountDetails, destination_bank: null });
            ErrorToast("No Results.");
            setBankListPagination([]);
            setIsBankShow(false);
          }
        })
        .catch((err) => {
          setWalletAmountDetails({ ...walletAmountDetails, destination_bank: null })
          setBankListPagination([]);
          setIsBankShow(false);
          // if (typeof err.response.data.message !== "undefined") {
          //   ErrorToast(err.response.data.message || "Server Error!!");
          // } else {
          //   ErrorToast(err?.message || "Server Error!!");
          // }
        });
    }
  };

  const disable = () => {
    if (bankCurrency == "AED") {
      setDisableField(true)
    } else {
      setDisableField(false)
    }
  }

  const signInSchema = Yup.object().shape({
    user_id: Yup.object().nullable().required("Please select merchant"),
    // deposited_currency: Yup.string().nullable().required("Choose currency"),
    deposited_amount: Yup.number().nullable().positive("Must be greater then 0").required("Deposited amount is required").typeError("Enter number only"),
    date_of_payment: Yup.date().required("Please select date"),
    destination_bank: Yup.object().nullable().required("Please select a bank"),
    motive: Yup.string().nullable().required("Please select type of payment"),
    // transaction_currency: Yup.string().nullable().required("Please select a transaction currency type"),
    transaction_amount: Yup.number().nullable().positive().required("Credited amount is required"),
    bank_reference: Yup.string().required("Bank reference is required"),
    user_reference: Yup.string().required("User reference is required"),
    comments: Yup.string().nullable().max(200, "Enter 200 character only"),
    receipt_file: Yup.mixed(),
    aed_conversion_rate: Yup.number().nullable().positive("Must be greater then 0").required("AED conversion rate is required").typeError("Enter number only"),
  });

  const getDepositRequestDetails = async () => {
    await getDepositRequestDetailsById(tid).then((res) => {
      const resEditWalletData = res?.data?.data?.transaction || {};
      setWalletAmountDetails(resEditWalletData);
      setTranId(res?.data?.data?.transaction?.id);
      let bankData = res?.data?.data?.transaction?.destination_bank;
      if (typeof bankData !== 'undefined' && bankData !== '') {
        setTitle(bankData.id);
        setId(resEditWalletData.user_id);
        setTitleName(bankData.title);
        setIban(bankData.iban);
        setLogo(bankData.logo);
        setAccountNumber(bankData.account_number);
        setBeneficiary(bankData.beneficiary);
        setCurrency(bankData.currency);
        setBankCurrency(bankData.currency);
        setSwiftCode(bankData.swift_code);
        setIsBankShow(true);
      }
    });
  };

  useEffect(() => {
    let items = JSON.parse(localStorage.getItem('userData'));
    if (items) {
      setItems(items);
      setId(items.id);
      setPhotoID(items.id);
      setName(items.name);
      getMerchantList();
      getBankAccountList(id);
      getDepositRequestDetails();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Edit Wallet Amount</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Edit Wallet Amount</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Wallet Amount</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/merchant-wallet-request" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <Formik
                    initialValues={{
                      user_id: merchantListPagination.find(option => walletAmountDetails.user_id === option.value) || '',
                      deposited_amount: walletAmountDetails?.deposited_amount || '',
                      on_behalf_of: walletAmountDetails?.on_behalf_of || '',
                      date_of_payment: walletAmountDetails?.date_of_payment || '',
                      destination_bank: (walletAmountDetails?.destination_bank) ? { ...walletAmountDetails?.destination_bank, label: walletAmountDetails?.destination_bank?.title, value: walletAmountDetails?.destination_bank?._id } : "",
                      transaction_amount: walletAmountDetails?.transaction_amount || '',
                      motive: walletAmountDetails?.motive || '',
                      bank_reference: walletAmountDetails?.bank_reference || '',
                      user_reference: walletAmountDetails?.user_reference || '',
                      comments: walletAmountDetails?.comments || '',
                      receipt_file: "",
                      aed_conversion_rate: walletAmountDetails?.aed_conversion_rate || '',
                    }}
                    validateOnChange
                    enableReinitialize
                    validationSchema={signInSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      const postObj = {
                        transaction_id: tranId || "",
                        user_id: id || "",
                        motive: values.motive || "",
                        date_of_payment: moment(values.date_of_payment).format("YYYY-MM-DD"),
                        destination_bank: title,
                        on_behalf_of: "CPAY (AL SAFFRON TRAVEL AND TOURISM LLC)",
                        deposited_currency: bankCurrency || "",
                        deposited_amount: values.deposited_amount || "",
                        transaction_currency: currency || "",
                        transaction_amount: values.transaction_amount || "",
                        bank_reference: values.bank_reference || "",
                        user_reference: values.user_reference || "",
                        comments: values.comments || "",
                        receipt_file: values.receipt_file || "",
                        aed_conversion_rate: values.aed_conversion_rate || "",
                      }
                      await EditWalletAmountMerchant(postObj).then((res) => {
                        SuccessToast(res?.data?.message || 'Wallet Updated.');
                        setSubmitting(false);
                        setTimeout(() => {
                          history('/admin/merchant-wallet-request');
                        }, 1000);
                      }).catch((err) => {
                        if (typeof err.response.data.message !== 'undefined') {
                          ErrorToast(err.response.data.message || 'Server Error!!');
                        } else {
                          ErrorToast(err?.message || 'Server Error!!');
                        }
                        setSubmitting(false);
                      })
                      setTimeout(() => {
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {(formik) => {
                      const { touched, errors, setFieldValue, handleChange, isSubmitting, values } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group destination-dropdown">
                                    <label htmlFor="user_id">Choose Merchant <span className="text-danger">*</span></label>
                                    <Select
                                      options={merchantListPagination}
                                      onChange={(e) => {
                                        setFieldValue("user_id", e);
                                        setFieldValue("Currency", e.currencyValue);
                                        setFieldValue("id", e.value);
                                        setFieldValue("Transaction_id", e.value);
                                        setCurrency(e.currencyValue);
                                        setId(e.value);
                                        getBankAccountList(e.value);
                                        setFieldValue("destination_bank", '');
                                      }}
                                      name="user_id"
                                      value={values.user_id}
                                    />
                                    <ErrorMessage name="user_id" component="span" className="error" style={{ color: "red" }} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="label-dark">Date of Payment</label>
                                    <DatePicker
                                      autoComplete='off'
                                      className={`form-control ${errors.date_of_payment && touched.date_of_payment ? "input-error" : null}`}
                                      name="date_of_payment"
                                      onChange={date => setFieldValue('date_of_payment', date)}
                                      // selected ={values.date_of_payment}
                                      maxDate={new Date()}
                                      value={moment(values.date_of_payment).format("YYYY-MM-DD")}
                                    />
                                    <ErrorMessage
                                      name="date_of_payment"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>On Behalf of</label>
                                    <Field
                                      type="text"
                                      onChange={handleChange}
                                      className={`form-control ${errors.on_behalf_of && touched.on_behalf_of
                                        ? "input-error"
                                        : null
                                        }`}
                                      name="on_behalf_of"
                                      value={"CPAY (AL SAFFRON TRAVEL AND TOURISM LLC)"}
                                      disabled
                                    />
                                    <ErrorMessage
                                      name="on_behalf_of"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group destination-dropdown">
                                    <label htmlFor="bank">Destination/Bank <span className="text-danger">*</span></label>
                                    <Select
                                      options={bankListPagination}
                                      onChange={(e) => {
                                        setFieldValue("currency", e.currencyValue + "logo", e.logo + "beneficiary", e.beneficiary + "Account_Number", e.accountNumber + "Iban", e.iban + "Swift_Code", e.swiftCode + "title", e.id + "titleName", e.title);
                                        setFieldValue("destination_bank", e)
                                        setTitle(e.value);
                                        setTitleName(e.title || null);
                                        setLogo(e.logo);
                                        setBeneficiary(e.beneficiary);
                                        setAccountNumber(e.accountNumber);
                                        setIban(e.iban);
                                        setSwiftCode(e.swiftCode);
                                        setBankCurrency(e.currencyValue);
                                        if (!isBankShow) {
                                          setIsBankShow(true);
                                        }
                                        disable();
                                      }}
                                      name="destination_bank"
                                      placeholder="Select bank"
                                      value={values.destination_bank}
                                    />
                                    <ErrorMessage
                                      name="destination_bank"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Amount Deposited <span className="text-danger">*</span></label>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon3">{bankCurrency || '-'}</span>
                                      </div>
                                      <Field
                                        type="text"
                                        aria-describedby="basic-addon3"
                                        className={`form-control ${errors.deposited_amount && touched.deposited_amount
                                          ? "input-error"
                                          : null
                                          }`}
                                        name="deposited_amount"
                                        onChange={handleChange}
                                        placeholder="Enter deposited amount"
                                        value={values.deposited_amount}
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="deposited_amount"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                    <label>Actual currency and amount been deposited</label>
                                  </div>
                                </div>
                                <div className="form-group col-md-6">
                                  <div className="form-group">
                                    <label>Amount to be credited <span className="text-danger">*</span></label>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon3">{currency || "-"}</span>
                                      </div>
                                      <Field
                                        type="text"
                                        placeholder="Enter credited amount"
                                        className={`form-control ${errors.transaction_amount && touched.transaction_amount
                                          ? "input-error"
                                          : null
                                          }`}
                                        name="transaction_amount"
                                        value={values.transaction_amount}
                                        disabled={disableField}
                                      // value={bankCurrency != "AED" ? values.transaction_amount : values.deposited_amount}
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="transaction_amount"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                    <span>Conversion Rate: {values.transaction_amount != "" && values.deposited_amount != "" ? <> <span className="currency-type-text">{currency || ""}</span> {(values.transaction_amount / values.deposited_amount).toFixed(4)} </> : ""}</span>
                                    <label>Amount and currency you claim to credit into your account</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Bank Reference <span className="text-danger">*</span></label>
                                    <Field
                                      type="text"
                                      className={`form-control ${errors.bank_reference && touched.bank_reference ? "input-error" : null
                                        }`}
                                      name="bank_reference"
                                      value={values.bank_reference}
                                      placeholder="Enter bank reference"
                                    />
                                    <ErrorMessage
                                      name="bank_reference"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Your Reference <span className="text-danger">*</span></label>
                                    <Field
                                      type="text"
                                      className={`form-control ${errors.user_reference && touched.user_reference ? "input-error" : null
                                        }`}
                                      name="user_reference"
                                      value={values.user_reference}
                                      placeholder="Enter user reference"
                                    />
                                    <ErrorMessage
                                      name="user_reference"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group destination-dropdown">
                                    <label htmlFor="bank">Type of Payment <span className="text-danger">*</span></label>
                                    <Select
                                      options={motiveOptions}
                                      onChange={(e) => {
                                        setFieldValue("motive", e.value);
                                      }}
                                      name="motive"
                                      value={
                                        motiveOptions.filter(option =>
                                          option.value === values.motive)
                                      }
                                      placeholder="Select type of payment"
                                    />
                                    <ErrorMessage
                                      name="motive"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label> AED Conversion Rate <span className="text-danger">*</span></label>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        {/* <span className="input-group-text" id="inputGroup-sizing-default">{merchantCurrencyName || "-"}</span> */}
                                      </div>
                                      <Field
                                        type="text"
                                        placeholder="Enter AED conversion rate"
                                        className={`form-control ${errors.aed_conversion_rate && touched.aed_conversion_rate
                                          ? "input-error"
                                          : null
                                          }`}
                                        name="aed_conversion_rate"
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="aed_conversion_rate"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Comments </label>
                                  <Field
                                    as="textarea"
                                    rows={3}
                                    cols={5}
                                    type="text"
                                    placeholder="Enter comment"
                                    className={`form-control ${errors.comments && touched.comments
                                      ? "input-error"
                                      : null
                                      }`}
                                    name="comments"
                                    value={values.comments}
                                  />
                                  <ErrorMessage
                                    name="comments"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              {/* <div className="row">
                                <div className="form-group">
                                  <label>Receipt</label>
                                  <input
                                    type="file"
                                    className={`form-control ${errors.receipt_file && touched.receipt_file ? "input-error" : null
                                      }`}
                                    name="receipt_file"
                                    // value={values.receipt_file}
                                    onChange={async (e) => {
                                      let imageData = e.target.files[0];
                                      const imagePostData = {
                                        user_id: photoID,
                                        file_name: imageData.name,
                                        file_module: "WALLET_RECEIPT",
                                        mime_type: imageData.type,
                                        document_sides: "NONE",
                                        expiry_date: "",
                                      };
                                      await fileUploadToBucket({
                                        data: imagePostData,
                                        file: imageData,
                                      })
                                        .then(async (res) => {
                                          setFieldValue('receipt_file', res?.data?.data?.s3_key)
                                        })
                                        .catch((err) => {
                                          // if (
                                          //   typeof err.response.data.message !== "undefined"
                                          // ) {
                                          //   ErrorToast(
                                          //     err.response.data.message || "Server Error!!"
                                          //   );
                                          // } else {
                                          //   ErrorToast(err?.message || "Server Error!!");
                                          // }
                                        });
                                    }}
                                  />
                                  <ErrorMessage
                                    name="receipt_file"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div> */}

                              <div className='form-group dropzone-image-area'>
                                <label className='image-label'>Receipt</label>
                                <DragAndDropFile
                                  randerKey={debounceKey}
                                  maxFileSize={2000000} //2mb
                                  label="Drag and drop a image or click"
                                  fileTypes={['image/*', '.pdf', '.docx', '.doc', '.pptx']}
                                  className={`form-control dropzoneBox ${errors.receipt_file && touched.receipt_file
                                    ? "input-error"
                                    : null
                                    }`}
                                  handleChange={async (file) => {
                                    setFieldValue("receipt_file", file[0]);
                                    let imageData = file[0];
                                    const imagePostData = {
                                      user_id: id,
                                      file_name: imageData?.name,
                                      file_module: "Wallet_RECEIPT",
                                      mime_type: imageData?.type,
                                      document_sides: "NONE",
                                    };
                                    await fileUploadToBucket({
                                      data: imagePostData,
                                      file: imageData,
                                    })
                                      .then(async (res) => {
                                        setFieldValue('receipt_file', res?.data?.data?.s3_key)
                                      })
                                      .catch((err) => {
                                        // if (
                                        //   typeof err.response.data.message !== "undefined"
                                        // ) {
                                        //   ErrorToast(
                                        //     err.response.data.message || "Server Error!!"
                                        //   );
                                        // } else {
                                        //   ErrorToast(err?.message || "Server Error!!");
                                        // }
                                      });
                                  }}
                                  acceptedFiles={fileTypes}
                                />
                                <ErrorMessage
                                  name="profile_picture"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>{/* col-md-8 */}
                            <div className={`col-md-4${((!isBankShow) ? ` d-none` : ``)}`}>
                              <div><img src={logo} alt="bank logo" className="imgcenter" /> </div>
                              <table className="table table-stripped table-responsive d-block">
                                <tbody>
                                  <tr>
                                    <td style={{ width: "10%" }}>Bank</td>
                                    <th className="custom-table">{titleName}</th>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "10%" }}>Holder / Beneficiary</td>
                                    <th className="custom-table">{beneficiary}</th>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "10%" }}>Account Number</td>
                                    <th className="custom-table">{accountNumber}</th>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "10%" }}>IBAN</td>
                                    <th className="custom-table">{iban}</th>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "10%" }}>Swift Code</td>
                                    <th className="custom-table">{swiftCode}</th>
                                  </tr>
                                </tbody>
                              </table>
                              <div style={{ textAlign: "center", margin: "20px 0px" }}>
                                <img src={walletAmountDetails.receipt_file} alt="receip_file" style={{ width: "100%" }} />
                              </div>
                            </div>

                          </div>
                          <div className="text-center  mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Submit
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div class="content container-fluid absolute px-2">
              <footer className="text-center text-lg-start">
                <div className="text-center pb-4">
                  Powered by Alsaffron System.
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWalletAmount;
