import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { getAllNotification, getUnreadNotificationCount } from '../../../Services/Api/notification';
import { Helmet } from 'react-helmet-async';
import { onImageError } from '../../../Utils/CommanFunctions';

const Notifications = () => {
  const navigate = useNavigate();
  const stateData = useLocation();
  const { id } = stateData?.state || '';
  const [notificationList, setNotificationList] = useState([])

  const getNotificationList = () => {
    getAllNotification(id).then((res) => {
      setNotificationList(res.data.data.notifications);
      getNotificationCount()
    }).catch(() => {
      setNotificationList([]);
    })
  };
  const getNotificationCount = () => {
    getUnreadNotificationCount().then((res) => {
    }).catch(() => {

    })
  }
  const customerDocs = (id) => {
    navigate("/admin/customer-legaldocument", {
      state: { id: id },
    })
  }
  const merchantDocs = (id) => {
    navigate("/admin/merchant-legaldocument", {
      state: { id: id },
    })
  }
  useEffect(() => {
    getNotificationList();
  }, []);

  const getNotificationListFromCurrent = (id) => {
    getAllNotification(id).then((res) => {
      getNotificationCount()
    }).catch(() => {

    })
  };

  return (
    <>
      <Helmet>
        <title>CPay | Admin Notification</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Notifications</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Notifications</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="comp-sec-wrapper">
            <section className="comp-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="card bg-white">
                    <div className="card-body">
                      <div className="notifications">
                        <ul className="notification-list">
                          {notificationList.length > 0 && notificationList.map((notification) => (
                            <li key={notification._id} className="notification-message">
                              <span
                                onClick={() => {
                                  getNotificationListFromCurrent(notification._id)
                                }}
                              >
                                {notification?.user?.role === "merchant" && (
                                  <>
                                    {notification?.type.slice(0, 1) === "D" && (
                                      <div className="media d-flex" style={{ cursor: "pointer" }} onClick={() => { merchantDocs(notification.user._id) }}>
                                        <span className="avatar avatar-sm">
                                          <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || "/inner-assets/img/profiles/default_image.jpg"} alt="Profile pic" />
                                        </span>
                                        <div className="media-body px-3">
                                          <p className="noti-details"><span className="noti-title">{notification?.user.name}</span></p>
                                          <p className="noti-details">{notification?.message}</p>
                                          <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type === "WALLET_DEPOSIT_REQUEST_APPROVED") && (
                                      <div className="media d-flex" style={{ cursor: "pointer" }} onClick={() => { navigate("/admin/merchant-wallet-request") }}>
                                        <span className="avatar avatar-sm">
                                          <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                        </span>
                                        <div className="media-body px-3">
                                          <p className="noti-details"><span className="noti-title">{notification?.user.name}</span></p>
                                          <p className="noti-details">{notification?.message}</p>
                                          <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type === "WALLET_CREDIT_REQUEST_APPROVED") && (
                                      <div className="media d-flex" style={{ cursor: "pointer" }} onClick={() => { navigate("/admin/merchant-credit-request") }}>
                                        <span className="avatar avatar-sm">
                                          <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                        </span>
                                        <div className="media-body px-3">
                                          <p className="noti-details"><span className="noti-title">{notification?.user.name}</span></p>
                                          <p className="noti-details">{notification?.message}</p>
                                          <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type !== "WALLET_CREDIT_REQUEST_APPROVED" && notification?.type !== "WALLET_DEPOSIT_REQUEST_APPROVED" && notification?.type.slice(0, 1) !== "D") && (
                                      <div className="media d-flex" style={{ cursor: "pointer" }} >
                                        <span className="avatar avatar-sm">
                                          <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                        </span>
                                        <div className="media-body px-3">
                                          <p className="noti-details"><span className="noti-title">{notification?.user.name}</span></p>
                                          <p className="noti-details">{notification?.message}</p>
                                          <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                                {notification?.user?.role === "customer" && (
                                  <>
                                    {notification?.type.slice(0, 1) === "D" && (
                                      <div className="media d-flex" style={{ cursor: "pointer" }} onClick={() => { customerDocs(notification.user._id) }}>
                                        <span className="avatar avatar-sm">
                                          <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                        </span>
                                        <div className="media-body px-3">
                                          <p className="noti-details"><span className="noti-title">{notification?.user.name}</span></p>
                                          <p className="noti-details">{notification?.message}</p>
                                          <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type.slice(0, 1) !== "D" && notification?.type !== "WALLET_CREDIT_REQUEST") && (
                                      <div className="media d-flex" style={{ cursor: "pointer" }}
                                        onClick={() => { navigate("/admin/wallet-request") }}>
                                        <span className="avatar avatar-sm">
                                          <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                        </span>
                                        <div className="media-body px-3">
                                          <p className="noti-details"><span className="noti-title">{notification?.user.name}</span></p>
                                          <p className="noti-details">{notification?.message}</p>
                                          <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type === "WALLET_CREDIT_REQUEST") && (
                                      <div className="media d-flex" style={{ cursor: "pointer" }}
                                        onClick={() => { navigate("/admin/customer-credit-request") }}>
                                        <span className="avatar avatar-sm">
                                          <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                        </span>
                                        <div className="media-body px-3">
                                          <p className="noti-details"><span className="noti-title">{notification?.user.name}</span></p>
                                          <p className="noti-details">{notification?.message}</p>
                                          <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                                {notification?.user === null && (
                                  <>
                                    {(notification?.type === "MERCHANT_REGISTRATION_REQUEST") && (
                                      <div className="media d-flex" style={{ cursor: "pointer" }}
                                        onClick={() => { navigate("/admin/merchant-registration-request") }}>
                                        <span className="avatar avatar-sm">
                                          <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                        </span>
                                        <div className="media-body px-3">
                                          <p className="noti-details"><span className="noti-title">{notification?.user?.name || "New Merchant"}</span></p>
                                          <p className="noti-details">{notification?.message}</p>
                                          <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notifications