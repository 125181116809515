import Api from "../../Utils/Axios";

export const GetMerchantPaginate = async ({ limit, page, user_id = '', status = '', filter_date = '', search = '' }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/merchant?offset=${page}&limit=${limit}&user_id=${user_id}&status=${status}&filter_date=${filter_date}&search=${encodeURIComponent(search)}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetBankAccountListPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/bank_account?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetBankAccountListBySelectedMerchantId = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/bank_account?user_id=${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteMerchant = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/admin/merchant/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};
export const StatusMerchant = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/merchant/active_inactive", data));
    } catch (error) {
      reject(error);
    }
  });
};
export const PostMerchant = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/merchant", data));
    } catch (error) {
      reject(error);
    }
  });
};
export const PutMerchant = async ({ id, data }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put(`/admin/merchant/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};
export const GetMerchantById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/merchant/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};
export const BlockMerchant = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/merchant/block_unblock", data));
    } catch (error) {
      reject(error);
    }
  });
};
/* 13-07-2022 */
export const AddWalletAmountMerchant = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/wallet/admin/add_amount", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditWalletAmountMerchant = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/wallet/admin/update_deposit_request", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const getDepositRequestDetailsById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/admin/deposit_request_details?transaction_id=${id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetStatementPaginate = async ({ limit, page, user_id }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/merchant/statements?offset=${page}&limit=${limit}&user_id=${user_id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetStatementPaginateFilter = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("master/wallet/merchant/statements",data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetTransactionExportFile = async (type, user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/export_statements?type=${type}&user_id=${user_id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetTransactionExportFileFilter = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("master/wallet/export_statements",data));
    } catch (error) {
      reject(error);
    }
  })
}

export const ClientGetWalletBalanceService = async (user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet?user_id=${user_id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const ServiceChargeUpdate = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/admin/merchant/update_service_charge', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetMerchantUserPaginate = async ({ limit, page, paginate = true }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/merchant?offset=${page}&limit=${limit}&paginate=${paginate}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetBank = async ({ limit, page, paginate = true }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/merchant?offset=${page}&limit=${limit}&paginate=${paginate}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteBank = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/master/banks/delete/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const getMerchantGenerateReport = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/admin/merchant/generate_report', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetExpiredDocumentUserPaginate = async ({ limit, page, paginate = true }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/merchant/expired_docs?offset=${page}&limit=${limit}&paginate=${paginate}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetMerchantRegistrationRequest = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/merchant/registration_request?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ApproveMerchantRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/merchant/approve", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const RejectMerchantRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/merchant/reject", data));
    } catch (error) {
      reject(error);
    }
  });
};