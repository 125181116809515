import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import format from "date-fns/format";
import {
  GetDepositRequestDetails,
  ApproveRejectCustomerWalletRequest,
  DeleteCustomerWalletRequest,
} from "../../../Services/Api/master";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GppBadIcon from "@mui/icons-material/GppBad";
import DeleteIcon from "@mui/icons-material/Delete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";

const WalletDetails = () => {
  const { id } = useParams();
  const tid = id;
  // const stateData = useLocation();
  const history = useNavigate();
  // const { tid } = stateData?.state || {};
  const [walletRequest, setWalletRequest] = useState({});

  const getWalletRequestData = async () => {
    await GetDepositRequestDetails(tid)
      .then((res) => {
        const resWalletRequestData = res?.data?.data.transaction || {};
        setWalletRequest(resWalletRequestData);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  useEffect(() => {
    getWalletRequestData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Wallet Request Details</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title"> {(walletRequest?.user?.name) ? `Wallet Request Details For `+walletRequest?.user?.name : `Wallet Request Details`} </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Wallet Details</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/Admin/wallet-request"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="custom-wallet-details">
                    <Formik
                      initialValues={{
                        transaction_amount:
                          walletRequest?.transaction_amount || 0,
                        comments: walletRequest?.status_comments || "",
                      }}
                      validateOnChange
                      enableReinitialize
                      validationSchema={Yup.object().shape({
                        transaction_amount: Yup.number()
                          .nullable()
                          .positive("Amount must grater than 0")
                          .required("Transaction amount is required."),
                        comments: Yup.string().required("Comment is required."),
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        var process_type = values.process_type || null;
                        if (process_type == 3) {
                          var postObj = {
                            transaction_id: tid,
                            comments: values.comments,
                          };
                          await DeleteCustomerWalletRequest(postObj)
                            .then((res) => {
                              SuccessToast(
                                res?.data?.message || "Status Updated"
                              );
                              setSubmitting(false);
                              history("/Admin/wallet-request");
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                            });
                        } else if (process_type == 1 || process_type == 2) {
                          var postObj = {
                            transaction_id: tid,
                            amount: values.transaction_amount,
                            status: process_type,
                            comments: values.comments,
                          };
                          await ApproveRejectCustomerWalletRequest(postObj)
                            .then((res) => {
                              SuccessToast(
                                res?.data?.message || "Status Updated"
                              );
                              setSubmitting(false);
                              history("/Admin/wallet-request");
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                            });
                        } else {
                          ErrorToast("Please select process type.");
                        }
                      }}
                    >
                      {({
                        errors,
                        touched,
                        handleChange,
                        values,
                        isSubmitting,
                        setFieldValue,
                      }) => {
                        return (
                          <Form>
                            <div className="form-main-sec">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Reference Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.reference_number
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Date
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={format(
                                                new Date(
                                                  walletRequest?.date_of_payment ||
                                                    null
                                                ),
                                                "dd MMMM, yyyy,hh:mm"
                                              )}
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Currency
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.transaction_currency
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-3">
                                          <label className="form-label">
                                            Amount to be credited{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            placeholder="Enter credited amount"
                                            className={`form-control ${
                                              errors.transaction_amount &&
                                              touched.transaction_amount
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="transaction_amount"
                                            value={values.transaction_amount}
                                          />
                                          <ErrorMessage
                                            name="transaction_amount"
                                            component="span"
                                            className="error"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Your Reference
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.user_reference
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-12">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Destination / Bank
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.destination_bank
                                                  ?.title
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Currency
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.deposited_currency
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-6">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Amount Deposited
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.deposited_amount
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-12">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Debtor Reference
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.bank_reference
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                      <div className="col-lg-12">
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.transaction_type
                                              }
                                            />
                                          </div>
                                        </fieldset>
                                        <fieldset disabled>
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Comments
                                            </label>
                                            <input
                                              as="textarea"
                                              className="form-control"
                                              placeholder={
                                                walletRequest?.comments
                                              }
                                              style={{ minHeight: "100px" }}
                                            />
                                          </div>
                                        </fieldset>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="content-details-img">
                                    {walletRequest?.receipt_file && (
                                      <img
                                        src={walletRequest?.receipt_file}
                                        alt="right"
                                        className="content-details-img-size"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="comments-sec">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="comments-card text-center">
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <div className="input-radio">
                                      <Field
                                        className="form-check-input mt-0"
                                        type="radio"
                                        name="process_type"
                                        value="3"
                                      />{" "}
                                      Delete
                                    </div>
                                    <p>Remove this record.</p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="comments-card text-center">
                                    <Tooltip
                                      title="Reject"
                                      className="btn btn-sm btn-white text-danger me-2"
                                    >
                                      <IconButton>
                                        <GppBadIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <div className="input-radio">
                                      <i className="fa-solid fa-delete-left"></i>
                                      <Field
                                        className="form-check-input mt-0"
                                        type="radio"
                                        name="process_type"
                                        value="2"
                                      />{" "}
                                      Send Back
                                    </div>
                                    <p>Send back to client to revise.</p>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="comments-card text-center">
                                    <i className="fa-solid fa-trash-can-xmark" />
                                    <Tooltip
                                      title="Approve"
                                      className="btn btn-sm btn-white text-success me-2"
                                    >
                                      <IconButton>
                                        <AssignmentTurnedInIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <div className="input-radio">
                                      <Field
                                        className="form-check-input mt-0"
                                        type="radio"
                                        name="process_type"
                                        value="1"
                                      />{" "}
                                      Apply
                                    </div>
                                    <p>
                                      Accept and apply as a new transaction.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="text-area">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Comments Back
                                  </label>
                                  <Field
                                    component="textarea"
                                    name="comments"
                                    className="form-control"
                                    rows={3}
                                  />
                                  <ErrorMessage
                                    name="comments"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="submit-btn text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Submit
                                  {isSubmitting && (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  )}
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletDetails;
