import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Helmet } from "react-helmet-async";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import format from "date-fns/format";
import {
  DeleteMerchant,
  GetMerchantPaginate,
  BlockMerchant,
  ServiceChargeUpdate,
  getMerchantGenerateReport,
} from "../../../Services/Api/merchant";
import { FilterComponentMerchant, SearchBox } from "../Common";
import { saveAs } from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import { onImageError } from "../../../Utils/CommanFunctions";

const Merchants = () => {
  const history = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [merchantListPagination, setMerchantListPagination] = useState([]);
  const [resetDate, setResetDate] = useState(0);
  const [showScAlert, setShowScAlert] = useState(false);
  const [initData, setInitData] = useState({
    merchant_id: "",
    serviceCharge: 0,
    serviceChargeType: "",
    startDate: "",
  });

  const handleReset = async () => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    getMerchantList(limit, 0);
  };

  const handleGenerateReport = async ({ fDate, fStatus, fUser }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_user: fUser || "",
    };
    await getMerchantGenerateReport(postData)
      .then((res) => {
        handleReset();
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        handleReset();
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const handleSubmitFilter = (filterData) => {
    setPage(0);
    getMerchantList(limit, 0, filterData.fUser, filterData.fStatus, filterData.fDate);
  }

  const getMerchantList = async (limitO = "", pageO = "", user_id = "", status = "", filter_date = "", search = "") => {
    setIsLoading(true);
    await GetMerchantPaginate({ limit: limitO, page: pageO, user_id, status, filter_date, search })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.merchants.length > 0) {
          setMerchantListPagination(res?.data?.data?.merchants);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          // ErrorToast("No Results.");
          setMerchantListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setMerchantListPagination([]);
        setTotalRecords(0);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const showServiceChargeAlert = (merchant) => {
    setInitData({
      merchant_id: merchant?._id,
      serviceCharge: merchant?.service_charge?.service_charge
        ? merchant?.service_charge?.service_charge
        : 0,
      serviceChargeType: merchant?.service_charge?.type
        ? merchant?.service_charge?.type
        : "",
      startDate: moment(merchant?.service_charge?.start_date).toDate(),
    });
    setShowScAlert(true);
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getMerchantList(limit, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getMerchantList(parseInt(event.target.value, 10), 0);
  };

  const deleteMerchant = (deleteMerchantId) => {
    Confirmation("Want to delete merchant?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteMerchant(deleteMerchantId)
          .then((res) => {
            SuccessToast(res?.data?.message || "Merchant Deleted.");
            getMerchantList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  const changeBlock = (merchant, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          merchant_id: merchant._id,
          is_blocked: `${merchant.is_blocked ^ 1}`,
        };
        await BlockMerchant(postObj)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            getMerchantList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  useEffect(() => {
    const stopTypingFn = setTimeout(async () => {
      if (search !== "") {
        await getMerchantList(limit, page, "", "", "", search);
      }
    }, 300);

    return () => clearTimeout(stopTypingFn);
  }, [search]);

  useEffect(() => {
    getMerchantList(limit, page);
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Merchants</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Merchants</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Merchants</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/Admin/merchant-expired-document"
                  className="btn btn-primary me-3 mb-2"
                >
                  Expired Document Users
                </Link>
                <Link to="/Admin/add-merchant" className="btn btn-primary mb-2">
                  <i className="fas fa-plus" /> Add Merchant
                </Link>
                {/* <Link
                  className="btn btn-primary filter-btn"
                  to="/"
                  id="filter_search"
                >
                  <i className="fas fa-filter" />
                </Link> */}
              </div>
            </div>
          </div>
          <FilterComponentMerchant
            resetDate={resetDate}
            handleReset={handleReset}
            handleGenerateReport={handleGenerateReport}
            handleSubmitFilter={handleSubmitFilter}
          />
          <SearchBox search={search} setSearch={setSearch} />
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  {showScAlert && (
                    <SweetAlert
                      title={"Service Charge"}
                      onConfirm={(response) => {}}
                      type={"controlled"}
                      showConfirm={false}
                      showCancel={false}
                    >
                      {(renderProps) => (
                        <Formik
                          enableReinitialize
                          initialValues={initData}
                          validationSchema={Yup.object().shape({
                            serviceChargeType: Yup.string().required(
                              "Please select service charge type"
                            ),
                            serviceCharge: Yup.number()
                              .required("Please enter service charge")
                              .min(0)
                              .label("Service Charge"),
                            startDate: Yup.string().required(
                              "Please select start date"
                            ),
                          })}
                          validateOnChange
                          onSubmit={async (values, { setSubmitting }) => {
                            const payload = {
                              merchant_id: values.merchant_id,
                              service_charge: values.serviceCharge,
                              type: values.serviceChargeType,
                              start_date: format(
                                new Date(values.startDate),
                                "yyyy-MM-dd"
                              ),
                            };
                            setSubmitting(true);
                            await ServiceChargeUpdate(payload)
                              .then((res) => {
                                setSubmitting(false);
                                setShowScAlert(false);
                                SuccessToast(
                                  res?.data?.message || "Service charge updated"
                                );
                                getMerchantList(limit, page);
                              })
                              .catch((err) => {
                                setSubmitting(false);
                                if (
                                  typeof err.response.data.message !==
                                  "undefined"
                                ) {
                                  ErrorToast(
                                    err.response.data.message ||
                                      "Server Error!!"
                                  );
                                } else {
                                  ErrorToast(err?.message || "Server Error!!");
                                }
                              });
                          }}
                        >
                          {({
                            errors,
                            touched,
                            handleChange,
                            values,
                            isSubmitting,
                            setFieldValue,
                          }) => {
                            return (
                              <Form>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group text-start">
                                      <label>
                                        Service Charge Type{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        as="select"
                                        onChange={handleChange}
                                        className={`form-control ${
                                          errors.serviceChargeType &&
                                          touched.serviceChargeType
                                            ? "input-error"
                                            : null
                                        }`}
                                        name="serviceChargeType"
                                        value={values.serviceChargeType}
                                      >
                                        <option value="">
                                          Please select...
                                        </option>
                                        <option value="percentage">
                                          Percentage
                                        </option>
                                        <option value="amount">Amount</option>
                                      </Field>
                                      <ErrorMessage
                                        name="serviceChargeType"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group text-start">
                                      <label>
                                        Service Charge{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="number"
                                        className={`form-control ${
                                          errors.serviceCharge &&
                                          touched.serviceCharge
                                            ? "input-error"
                                            : null
                                        }`}
                                        name="serviceCharge"
                                        value={values.serviceCharge}
                                        placeholder="Enter service charge"
                                      />
                                      <ErrorMessage
                                        name="serviceCharge"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group text-start">
                                      <label>
                                        Start Date{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <DatePicker
                                        autoComplete="off"
                                        className={`form-control ${
                                          errors.startDate && touched.startDate
                                            ? "input-error"
                                            : null
                                        }`}
                                        name="startDate"
                                        onChange={(date) =>
                                          setFieldValue(
                                            "startDate",
                                            date == null ? "" : date
                                          )
                                        }
                                        selected={values.startDate}
                                        placeholderText="dd-mm-yyyy"
                                        dateFormat="dd-MM-yyyy"
                                      />
                                      <ErrorMessage
                                        name="startDate"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <Field
                                    type="hidden"
                                    name="merchant_id"
                                    value={values.merchant_id}
                                  />
                                </div>

                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={isSubmitting}
                                  >
                                    Save
                                    {isSubmitting && (
                                      <i className="fas fa-spinner fa-spin"></i>
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger ms-2"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                      setShowScAlert(false);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      )}
                    </SweetAlert>
                  )}

                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Status</th>
                          <th className="text-center">Actions</th>
                          <th>Merchant</th>
                          <th>Email</th>
                          <th>Website</th>
                          <th>Primary Currency</th>
                          <th>Total Balance</th>
                          <th>Pending Due</th>
                          <th>Service Charge</th>
                          <th>Registered On</th>
                          <th>Block</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {merchantListPagination.map((merchant, index) => (
                              <tr key={index}>
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  {merchant.status === 0 && (
                                    <span className="badge badge-pill bg-danger-light">
                                      Inactive
                                    </span>
                                  )}
                                  {merchant.status === 1 && (
                                    <span className="badge badge-pill bg-success-light">
                                      Active
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      history("/admin/edit-merchant", {
                                        state: { id: merchant._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() => deleteMerchant(merchant._id)}
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title={merchant?.docs.length > 0 ? "Document Pending For Verification": "View Document"}
                                    className={`btn btn-sm btn-white text-warning me-2 ${merchant?.docs.length > 0 ? `ripple` : ``}`}
                                    onClick={() =>
                                      history("/admin/merchant-legaldocument", {
                                        state: { id: merchant._id, merchant_name: merchant.name },
                                      })
                                    }
                                    // style={{border:merchant?.docs.length > 0 ? "2px solid red" : "0"}}
                                  >
                                    <IconButton>
                                      <InsertDriveFileIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Agreement"
                                    className="btn btn-sm btn-white text-warning me-2"
                                    onClick={() =>
                                      history("/admin/merchant-agreement", {
                                        state: { id: merchant._id, merchant_name: merchant.name },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <div className="handshake-img">
                                        <img
                                          src="../inner-assets/img/handshake-solid .svg"
                                          alt="solid-handshake"
                                        />
                                      </div>
                                    </IconButton>
                                  </Tooltip>

                                  {/* <Tooltip title="Edit Service Charge" className="btn btn-sm btn-white text-success me-2" onClick={() => deleteMerchant(merchant._id)}>
                                    <IconButton>
                                      <RequestQuoteIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    {/* <Link to="/admin/customer-profile" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="../inner-assets/img/profiles/avatar-02.jpg" alt="User" /></Link>
                                      <Link to="/admin/customer-profile">{customer.name} <span>{customer.phone}</span></Link> */}
                                    <Link
                                      to="/admin/merchants"
                                      className="avatar avatar-sm me-2"
                                    >
                                      <img
                                        className="avatar-img rounded-circle"
                                        src={
                                          merchant.profile_picture ||
                                          "../inner-assets/img/profiles/default_image.jpg"
                                        }
                                        alt="User"
                                        onError={onImageError}
                                      />
                                    </Link>
                                    <Link to="/admin/merchants">
                                      {merchant.name}{" "}
                                      <span>{merchant.phone}</span>
                                    </Link>
                                  </h2>
                                </td>
                                <td>{merchant.email}</td>
                                <td>{merchant?.website ? <a href={merchant?.website} target='_blank' >{merchant?.website}</a> :"-" }</td>
                                <td>{merchant?.primary_currency || "-"}</td>
                                {/* <td><a href="statement"><span className="currency-type-text">{merchant?.wallet?.currency}</span> {merchant?.wallet?.total_amount || "0"}</a></td> */}

                                <td
                                  className="invoice-link"
                                  onClick={() =>
                                    history("/admin/merchant-statement", {
                                      state: {
                                        id: merchant?.wallet?.user_id,
                                        user_name: merchant?.name
                                      },
                                    })
                                  }
                                >
                                  <span className="currency-type-text">
                                    {merchant?.wallet?.currency}
                                  </span>{" "}
                                  {merchant?.wallet?.total_amount || "0"}
                                </td>
                                <td
                                  onClick={() =>
                                    history("/admin/merchant-statement", {
                                      state: {
                                        id: merchant?.wallet?.user_id,
                                        user_name: merchant?.name
                                      },
                                    })
                                  }
                                  className="text-danger cursor-pointer"
                                >
                                  <span className="currency-type-text">
                                    {merchant?.wallet?.currency}
                                  </span>{" "}
                                  {merchant?.pending_due?.totalAmount || "0"}
                                </td>

                                {merchant?.service_charge == null ? (
                                  <td
                                    className="invoice-link"
                                    onClick={() =>
                                      showServiceChargeAlert(merchant)
                                    }
                                  >
                                    N/A
                                  </td>
                                ) : merchant?.service_charge?.type ==
                                  "percentage" ? (
                                  <td
                                    className="invoice-link"
                                    onClick={() =>
                                      showServiceChargeAlert(merchant)
                                    }
                                  >
                                    {merchant?.service_charge?.service_charge}%
                                  </td>
                                ) : (
                                  <td
                                    className="invoice-link"
                                    onClick={() =>
                                      showServiceChargeAlert(merchant)
                                    }
                                  >
                                    <span className="currency-type-text">
                                      {merchant?.primary_currency}
                                    </span>{" "}
                                    {merchant?.service_charge?.service_charge}
                                  </td>
                                )}
                                <td>
                                  {format(
                                    new Date(merchant.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                                <td>
                                  {merchant.is_blocked === 1 && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeBlock(
                                          merchant,
                                          "Want to active merchant?"
                                        );
                                      }}
                                    >
                                      Suspend
                                    </button>
                                  )}
                                  {merchant.is_blocked === 0 && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-secondary"
                                      onClick={() => {
                                        changeBlock(
                                          merchant,
                                          "Want to suspend merchant?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {merchantListPagination.length === 0 && (
                              <tr>
                                <td align="left" colSpan={11}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={11}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={11}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="content container-fluid absolute">
                <footer className="text-center text-lg-start">
                  <div className="text-center pb-2">
                    Powered by Alsaffron System.
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Merchants;
