import Api from "../../Utils/Axios";

export const GetInvestorPaginate = async ({ limit, page }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/admin/investors?offset=${page}&limit=${limit}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const AddFundService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post("/admin/investors/add_fund",data))
      } catch (error) {
        reject(error);
      }
    })
  }

  export const ReturnFundService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post("/admin/investors/return_fund",data))
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetInvestorStatementPaginate = async ({ limit, page, id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/admin/investors/statements?offset=${page}&limit=${limit}&investor_id=${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const addInvestor = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post("/admin/investors",data))
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetFundStatementPaginate = async ({limit, page}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/admin/investors/fund_statements?offset=${page}&limit=${limit}`))
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetFundWidgetService = async () =>{
      return new Promise(async (resolve,reject) => {
        try{
            resolve(await Api.get('/admin/investors/fund'))
        }catch(error){
            reject(error)
        }
      })
  }