import Api from "../../Utils/Axios";

export const getCustomerTransactionReportWidget = async () =>{
    return new Promise(async (resolve, reject) =>{
        try{
            resolve(await Api.get("/admin/reports/customer_transaction_widget"));
        }catch(error){
            reject(error);
        }
    })
}

export const GetCustomerTransactionList = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/admin/reports/customer_transactions",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetGenerateCustomerReport = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/admin/reports/generate_customer_transaction",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetMerchantTransactionList = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/admin/reports/merchant_transactions",data));
        }catch(error){
            reject(error);
        }
    })
}

export const getMerchantTransactionReportWidget = async () =>{
    return new Promise(async (resolve, reject) =>{
        try{
            resolve(await Api.get("/admin/reports/merchant_transaction_widget"));
        }catch(error){
            reject(error);
        }
    })
}

export const GetGenerateMerchantReport = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/admin/reports/generate_merchant_transaction",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetCustomerDueCredit = async ({ limit, page }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/admin/reports/customer_amount_due?offset=${page}&limit=${limit}`));
      } catch (error) {
        reject(error);
      }
    })
}
 
export const GetGenerateCustomerAmountDueReport = async () => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/admin/reports/generate_customer_amount_due"));
        }catch(error){
            reject(error);
        }
    })
}

export const GetViewEarning = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post("/admin/reports/earning",data));
      } catch (error) {
        reject(error);
      }
    })
}

export const getEarningWidget = async (date) =>{
    return new Promise(async (resolve, reject) =>{
        try{
            resolve(await Api.post("/admin/reports/earning_widget",date));
        }catch(error){
            reject(error);
        }
    })
}

export const GetGenerateViewEarningReport = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/admin/reports/generate_earning",data));
        }catch(error){
            reject(error);
        }
    })
}
