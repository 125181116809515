import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  GetCustomerDueCredit,
  GetGenerateCustomerAmountDueReport,
} from "../../../Services/Api/reports";
// import format from "date-fns/format";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { ErrorToast } from "../../../Utils/SweetAlert";
import { showCustName } from "../../../Utils/CommanFunctions";

const CustomerAmountDueReport = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [amountDue, setAmountDue] = useState([]);

  const getDueCreditList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetCustomerDueCredit({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setAmountDue(res?.data?.data?.due_credits);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setAmountDue([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setAmountDue([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGenerateReport = async () => {
    await GetGenerateCustomerAmountDueReport()
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  useEffect(() => {
    getDueCreditList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Customer Amount Due Report</title>
      </Helmet>
      <div>
        <div className="main-wrapper">
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Customer Amount Due Report</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Customer Amount Due Report
                      </li>
                    </ul>
                  </div>
                  <div className="col-auto">
                    <span
                      onClick={handleGenerateReport}
                      className="btn btn-primary me-1"
                    >
                      Generate Report
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-center table-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>Reference Number</th>
                              <th>Customer</th>
                              <th>Amount</th>
                              <th>Remaining Amount</th>
                              <th>Amount Used</th>
                              <th>Amount Paid</th>
                              <th>Amount Due</th>
                              <th>Due Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading && (
                              <>
                                {amountDue.map((dueCredit, index) => (
                                  <tr key={index}>
                                    <td>
                                      {dueCredit?.reference_number}
                                      <br />
                                    </td>
                                    <td>{showCustName(dueCredit?.user?.name, dueCredit?.user?.company_name)}</td>
                                    <td>
                                      <span className="currency-type-text">
                                        {dueCredit?.currency}{" "}
                                      </span>
                                      {dueCredit?.amount}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {dueCredit?.currency}{" "}
                                      </span>
                                      {dueCredit?.remaining_amount}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {dueCredit?.currency}{" "}
                                      </span>
                                      {(
                                        dueCredit?.amount -
                                        dueCredit?.remaining_amount
                                      ).toFixed(2)}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {dueCredit?.currency}{" "}
                                      </span>
                                      {dueCredit?.amount_paid}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {dueCredit?.currency}{" "}
                                      </span>
                                      {(
                                        dueCredit?.amount -
                                        dueCredit?.remaining_amount -
                                        dueCredit?.amount_paid
                                      ).toFixed(2)}
                                    </td>
                                    <td>
                                      {/* {format(new Date(dueCredit?.due_date),"dd MMMM, yyyy")} */}
                                      {moment(dueCredit?.due_date).format("ll")}
                                    </td>
                                  </tr>
                                ))}
                                {amountDue?.length === 0 && (
                                  <tr>
                                    <td colSpan={8}>No Records Found.</td>
                                  </tr>
                                )}
                              </>
                            )}
                            <Pagination
                              totalItem={totalRecords}
                              itemsPerPage={limit}
                              page={page}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleLimitChange}
                              colSpan={8}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerAmountDueReport;
