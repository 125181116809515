import React from "react";

const SwitchToggle = ({ value, setFieldValue }) => {
  return (
    <div className="form-group toggle-switch-wrapper">
      <label className="row toggle-switch" htmlFor="switchOn">
        <div className="toggle-switch-content">
          <span className="d-block text-dark" style={{ fontSize: "larger" }}>
            Status
          </span>
        </div>
        <div className="">
          <input
            type="checkbox"
            name="status"
            value="1"
            checked={value.status}
            className="toggle-switch-input"
            id="switchOn"
            onChange={(e) => {
              if (e.target.checked === true) {
                setFieldValue("status", 1);
              } else {
                setFieldValue("status", 0);
              }
            }}
          />
          <span className="toggle-switch-label">
            <span className="toggle-switch-indicator"></span>
          </span>
        </div>
      </label>
    </div>
  );
};

export default SwitchToggle;
